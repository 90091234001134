<template>
  <div class="loading-bg">
    <div>
      <img src="@/assets/images/loader.png">
      <p class="loading-text">Выполняется расчетное определение величин пожарного риска</p>
    </div>
  </div>
</template>  

<style scoped lang="scss" >
  @import "@/assets/styles/helpers/variables.scss";
  @import "@/assets/styles/helpers/mixin.scss";
  
  .loading-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background:rgba(255,255,255,.9) ;
    text-align: center;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading-text{
    font-size: 200%;
    margin-top: 50px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
  }
</style>