export const calculateCloudExplosionFire = {
  methods: {
    calculateCloudExplosionFire(graphType, activeAccident, saveMode) {
      const ATMOSPHERE_PRESSURE = 101325; // атмоссферное давление, Па
      const EXPLOSION_PARTICIPATION_RATE = 0.1; // коэффициент участия горючего во взрыве
      const CONST_43 = 43; // константа, равная 43
      const CONST_26 = 26; // константа, равная 26
      const SOUND_SPEED = 340; // скорость звука в воздухе, м/c
      const DEGREE_OF_EXPANSION = 7; // степень расширения продуктов сгорания газа или пара

      let explosionInOpenSpace = this.currentObject.explosionInOpenSpace; // характеристика взрыва топливовоздушной смеси в открытом пространстве

      let carbonNumber = this.currentObject.substance.carbonNumber; // число атомов углерода в молекуле горючего
      let hydrogenNumber  = this.currentObject.substance.hydrogenNumber; // число атомов водорода в молекуле горючего
      let oxygenNumber  = this.currentObject.substance.oxygenNumber; // число атомов кислорода в молекуле горючего
      let halogenNumber = this.currentObject.substance.halogenNumber; // число атомов галоидов в молекуле горючего

      let specificHeatOfCombustion  = this.currentObject.substance.specificHeatOfCombustion; // удельная теплота сгорания горючего газа или пара, Дж/кг
      let bottomConcentrationLimit  = this.currentObject.substance.bottomConcentrationLimit; // нижний концентрационный предел распространения пламени, %(об.)
      let fuelClass  = this.currentObject.substance.fuelClass; // класс горючего вещества по степени чувствительности к возбуждению взрывных процессов

      let spaceClass  = this.$store.state.Project.currentProjectGeneralData.class; // класс окружающего пространства по степени загроможденности
      let massSubstanceInCloud  = this.currentObject.failureParameterObj[activeAccident].failureParameter.massSubstanceInCloud; // масса газа или пара, поступившего в открытое пространство, кг

      let probitFunctionTable = [-5.0,-4.5,-4.0,-3.5,-3.0,-2.5,-2.0,-1.5,-1.0,-0.5,0.0,0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,11.0,11.5,12.0,12.5,13.0,13.5,14.0,14.5,15.0];
      let probabilityOfDamageTable = [0,0,0,0,0,3.191e-14,1.280e-12,4.016e-11,9.866e-10,1.899e-8,2.867e-7,3.398e-6,3.167e-5,2.326e-4,1.350e-3,6.210e-3,0.023,0.067,0.159,0.309,0.500,0.691,0.841,0.933,0.977,0.994,0.999,1,1,1,1,1,1,1,1,1,1,1,1,1,1,]

      /*  */

      let distanceFromEquipment = []; // расстояние от оборудования, м
      for (let i = 0; i < 10000; i++) {
        distanceFromEquipment[i] = i;
      }

      let stoichiometricCoefficient; // стехиометрический коэффициент кислорода в реакции сгорания
      let stoichiometricConcentration; // стехиометрическая концентрация горючих газов или паров, %(об.)
      let cloudMassInIgnitionZone; // масса горючего газа или пара, содержащегося в облаке, с концентрацией между нижним и верхним концентрационными пределами распространения пламени, кг
      let fuelConcentration; // концентрация горючего вещества в смеси, %(об.)
      let energyEfficiency; // эфективный энергозапас горючей смеси, Дж
      let combustionClass; // класс режима сгорания облака
      let flameFrontVelocity; // скорость фронта пламени, необходимая для расчета 2-6 классов режима сгорания облака
      let dimensionlessDistance = []; // безразмерное расстояние
      let dimensionlessPressure = []; // безразмерное давление
      let overpressure = []; // размерная величина избыточного давления, Па
      let dimensionlessPulse = []; // безразмерный импульс фазы сжатия
      let pulse = []; // размерный импульс фазы сжатия, Па*с;
      let funcV = []; // функция V;
      let probitFunction = []; // пробит функция
      let indexProbability = []; //индекс массива, рядом с которым находится значение условной вероятности поражения  
      let probabilityOfDamage = []; //условная вероятность поражения человека волной давления

      stoichiometricCoefficient = carbonNumber + (hydrogenNumber - halogenNumber) / 4 - oxygenNumber / 2;
      stoichiometricConcentration = 100 / (1 + 4.84 * stoichiometricCoefficient);
      cloudMassInIgnitionZone = EXPLOSION_PARTICIPATION_RATE * massSubstanceInCloud;
      fuelConcentration = bottomConcentrationLimit;
      if (explosionInOpenSpace === "surfaceExplosionYes") {
        if (fuelConcentration <= stoichiometricConcentration) {
          energyEfficiency = 2 * cloudMassInIgnitionZone * specificHeatOfCombustion;
        } else {
          energyEfficiency = 2 * cloudMassInIgnitionZone * specificHeatOfCombustion * stoichiometricConcentration / fuelConcentration;
        }
      } else if (explosionInOpenSpace === "surfaceExplosionNo") {
        if (fuelConcentration <= stoichiometricConcentration) {
          energyEfficiency = cloudMassInIgnitionZone * specificHeatOfCombustion;
        } else {
          energyEfficiency = cloudMassInIgnitionZone * specificHeatOfCombustion * stoichiometricConcentration / fuelConcentration;
        }
      }
      if (fuelClass === "class_1" && spaceClass === "class_I") {combustionClass = 1}
      if (fuelClass === "class_1" && spaceClass === "class_II") {combustionClass = 1}
      if (fuelClass === "class_1" && spaceClass === "class_III") {combustionClass = 2}
      if (fuelClass === "class_1" && spaceClass === "class_IV") {combustionClass = 3}
      if (fuelClass === "class_2" && spaceClass === "class_I") {combustionClass = 1}
      if (fuelClass === "class_2" && spaceClass === "class_II") {combustionClass = 2}
      if (fuelClass === "class_2" && spaceClass === "class_III") {combustionClass = 3}
      if (fuelClass === "class_2" && spaceClass === "class_IV") {combustionClass = 4}
      if (fuelClass === "class_3" && spaceClass === "class_I") {combustionClass = 2}
      if (fuelClass === "class_3" && spaceClass === "class_II") {combustionClass = 3}
      if (fuelClass === "class_3" && spaceClass === "class_III") {combustionClass = 4}
      if (fuelClass === "class_3" && spaceClass === "class_IV") {combustionClass = 5}
      if (fuelClass === "class_4" && spaceClass === "class_I") {combustionClass = 3}
      if (fuelClass === "class_4" && spaceClass === "class_II") {combustionClass = 4}
      if (fuelClass === "class_4" && spaceClass === "class_III") {combustionClass = 5}
      if (fuelClass === "class_4" && spaceClass === "class_IV") {combustionClass = 6}

      if (combustionClass === 6) {
        flameFrontVelocity = CONST_26 * Math.pow(massSubstanceInCloud, 1 / 6);
      }
      if (combustionClass === 5) {
        flameFrontVelocity = CONST_43 * Math.pow(massSubstanceInCloud, 1 / 6);
      }
      if (combustionClass === 4) {
        flameFrontVelocity = CONST_43 * Math.pow(massSubstanceInCloud, 1 / 6);
        if (flameFrontVelocity < 200) {
          flameFrontVelocity = 200;
        }
      }
      if (combustionClass === 3) {
        flameFrontVelocity = CONST_43 * Math.pow(massSubstanceInCloud, 1 / 6);
        if (flameFrontVelocity < 300) {
          flameFrontVelocity = 300;
        }
      }
      if (combustionClass === 2) {
        flameFrontVelocity = CONST_43 * Math.pow(massSubstanceInCloud, 1 / 6);
        if (flameFrontVelocity < 500) {
          flameFrontVelocity = 500;
        }
      }
      if (combustionClass === 1) {
        flameFrontVelocity = 0;
      }
      for (let i = 0; i < distanceFromEquipment.length; i++) {
        dimensionlessDistance[i] = distanceFromEquipment[i] / Math.pow((energyEfficiency / ATMOSPHERE_PRESSURE), 1 / 3);
        if (combustionClass === 1) {
          if (dimensionlessDistance[i] >= 0.2) {
            dimensionlessPressure[i] = Math.exp(-1.124 - 1.66 * Math.log(dimensionlessDistance[i]) + 0.260 * Math.pow(Math.log(dimensionlessDistance[i]), 2));
          } else {
            dimensionlessPressure[i] = 18;
          }
        } else if (combustionClass === 2 || combustionClass === 3 || combustionClass === 4 || combustionClass === 5 || combustionClass === 6) {
          if (dimensionlessDistance[i] > 0.34) {
            dimensionlessPressure[i] = (Math.pow(flameFrontVelocity, 2) / Math.pow(SOUND_SPEED, 2)) * ((DEGREE_OF_EXPANSION - 1) / DEGREE_OF_EXPANSION) * (0.83 / dimensionlessDistance[i] - 0.14 / Math.pow(dimensionlessDistance[i], 2));
          } else {
            dimensionlessPressure[i] = (Math.pow(flameFrontVelocity, 2) / Math.pow(SOUND_SPEED, 2)) * ((DEGREE_OF_EXPANSION - 1) / DEGREE_OF_EXPANSION) * (0.83 / 0.34 - 0.14 / Math.pow(0.34, 2));
          }
        }
        overpressure[i] = dimensionlessPressure[i] * ATMOSPHERE_PRESSURE;
        if (combustionClass === 1) {
          if (dimensionlessDistance[i] >= 0.2) {
            dimensionlessPulse[i] = Math.exp(-3.4217 - 0.898 * Math.log(dimensionlessDistance[i]) - 0.0096 * Math.pow(Math.log(dimensionlessDistance[i]), 2));
          } else {
            dimensionlessPulse[i] = Math.exp(-3.4217 - 0.898 * Math.log(0.14) - 0.0096 * Math.pow(Math.log(0.14), 2));
          }
        } else if (combustionClass === 2 || combustionClass === 3 || combustionClass === 4 || combustionClass === 5 || combustionClass === 6) {
          if (dimensionlessDistance[i] > 0.34) {
            dimensionlessPulse[i] = (flameFrontVelocity / SOUND_SPEED) * ((DEGREE_OF_EXPANSION - 1) / DEGREE_OF_EXPANSION) * (1 - 0.4 * (flameFrontVelocity / SOUND_SPEED) * ((DEGREE_OF_EXPANSION - 1) / DEGREE_OF_EXPANSION)) * (0.06 / dimensionlessDistance[i] + 0.01 / Math.pow(dimensionlessDistance[i], 2) - 0.0025 / Math.pow(dimensionlessDistance[i], 3));
          } else {
            dimensionlessPulse[i] = (flameFrontVelocity / SOUND_SPEED) * ((DEGREE_OF_EXPANSION - 1) / DEGREE_OF_EXPANSION) * (1 - 0.4 * (flameFrontVelocity / SOUND_SPEED) * ((DEGREE_OF_EXPANSION - 1) / DEGREE_OF_EXPANSION)) * (0.06 / 0.34 + 0.01 / Math.pow(0.34, 2) - 0.0025 / Math.pow(0.34, 3));
          }
        }
        pulse[i] = dimensionlessPulse[i] * Math.pow(ATMOSPHERE_PRESSURE, 2 / 3) * Math.pow(energyEfficiency, 1 / 3) / SOUND_SPEED;
        funcV[i] = Math.pow((17500 / overpressure[i]), 8.4) + Math.pow((290 / pulse[i]), 9.3);
        probitFunction[i] = 5 - 0.26 * Math.log(funcV[i]);
        indexProbability[i] = Math.trunc((probitFunction[i] + 5) / 0.5);
        if (probitFunction[i] < -5) {
          probabilityOfDamage[i] = 0;
        } else if (indexProbability[i] <= 39) {
          probabilityOfDamage[i] = probabilityOfDamageTable[indexProbability[i]] + (probitFunction[i] - probitFunctionTable[indexProbability[i]]) * (probabilityOfDamageTable[indexProbability[i] + 1] - probabilityOfDamageTable[indexProbability[i]]) / 0.5;
        } else if(indexProbability[i] > 39) {
          probabilityOfDamage[i] = 1;
        }
      }

      if(saveMode){
        return {
          overpressure: overpressure,
          probabilityOfDamage: probabilityOfDamage,
          combustionClass: combustionClass,
        }
      }
      
      let overpressureGraph = [];
      let probabilityOfDamageGraph = [];
      
      if (combustionClass === 1) {
        let distanceFromEquipmentMax // максимальное расстояние от оборудования для построения графиков, м
        distanceFromEquipmentMax = 6.5 * Math.pow((energyEfficiency / ATMOSPHERE_PRESSURE), 1 / 3);
        for (let i = 0; i < 10000; i++) {
          if (i < distanceFromEquipmentMax) {
            overpressureGraph[i] = overpressure[i] / 1000;
            probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
          } else {
            overpressure[i] = 0;
            probabilityOfDamage[i] = 0;
            overpressureGraph[i] = overpressure[i] / 1000;
            probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
          }
        }
      } else if (combustionClass === 2 || combustionClass === 3 || combustionClass === 4 || combustionClass === 5 || combustionClass === 6) {
        for (let i = 0; i < 10000; i++) {
          overpressureGraph[i] = overpressure[i] / 1000;
          probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
        }
      }
    
      while (overpressureGraph[overpressureGraph.length - 1] < 10) {
        overpressureGraph.pop();
      }
    
      while (probabilityOfDamageGraph[probabilityOfDamageGraph.length - 1] < 1e-3) {
        probabilityOfDamageGraph.pop();
      }
      
      let distance1; //расстояние от оборудования, где избыточное давление равно 100 кПа
      let distance2; //расстояние от оборудования, где избыточное давление равно 53 кПа
      let distance3; //расстояние от оборудования, где избыточное давление равно 28 кПа
      let distance4; //расстояние от оборудования, где избыточное давление равно 12 кПа

      if(!saveMode) {
        for (let i = 0; i < overpressureGraph.length; i++) {
          if (overpressureGraph[i] >= 100) {distance1 = i;}
          if (overpressureGraph[i] >= 53) {distance2 = i;}
          if (overpressureGraph[i] >= 28) {distance3 = i;}
          if (overpressureGraph[i] >= 12) {distance4 = i;}
        }

        this.$store.commit('Project/setData', { label: 'graphDestructionTableData', data: [distance1,distance2,distance3,distance4] })
      }

      

      let abscissaRad = [];
      for (let i = 0; i < overpressureGraph.length; i++) {
        if(overpressureGraph[i] > 0) {
          abscissaRad[i] = i;
        }
        
      }
    
      let abscissaProb = [];
      for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
        if(probabilityOfDamageGraph[i] > 0.01) {
          abscissaProb[i] = i;
        }
      }

      let data,options,probabilityData,probabilityOptions
      if(graphType === 'radiationIntencity') {
        this.showDetailsTable = true
        this.tableType = 'destruction'
        data  = {
          labels: abscissaRad,
          datasets: [
            {
              label: 'Избыточное давление взрыва облака',
              data: overpressureGraph,
              backgroundColor: [],
              borderColor: [],
              pointRadius: [],
            }
          ],
        }
  
        options =  {
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Расстояние от оборудования, м'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Избыточное давление взрыва, кПа'
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
                color: '#3278e1',
                font: {
                  size: 16,
                }
              }
            },
            datalabels: {
              display: function(context) {
                return (context.dataIndex === distance1 || context.dataIndex === distance2 || context.dataIndex === distance3 || context.dataIndex === distance4)
              },
              formatter: function(value, context) {
                if (context.dataIndex === distance1) {
                  return 1;
                } else if (context.dataIndex === distance2) {
                  return 2;
                } else if (context.dataIndex === distance3) {
                  return 3;
                } else if (context.dataIndex === distance4) {
                  return 4;
                }
              },
              color: 'white',
              borderRadius: 10,
              backgroundColor: '#ff4d00',
              align: '-45',
              padding: {
                top: 5,
                right: 5,
                bottom: 5,
                left: 5
              },
              offset: 10,
            }
          }
        }
  
        for (let i = 0; i < data.labels.length; i++) {
          if (data.labels[i] === distance1 || data.labels[i] === distance2 || data.labels[i] === distance3 || data.labels[i] === distance4) {
            data.datasets[0].pointRadius[i] = 5;
            data.datasets[0].backgroundColor[i] = '#ff4d00';
            data.datasets[0].borderColor[i] = '#ff4d00';
          } else {
            data.datasets[0].borderColor[i] = '#3278e1';
            data.datasets[0].pointRadius[i] = 0;
          }
        }
      } else {
        this.showDetailsTable = false
        probabilityData  = {
          labels: abscissaProb,
          datasets: [{
            label: 'Условная вероятность поражения человека в здании',
            data: probabilityOfDamageGraph,
            borderColor: '#3278e1',
            pointRadius: 0,
          }]
        }

        probabilityOptions = {
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Расстояние от оборудования, м'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Условная вероятность поражения, %'
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
                color: '#3278e1',
                font: {
                  size: 16,
                }
              }
            },
            datalabels: {
              display: false
            }
          }
        }
        
      }

      if(!saveMode){
        this.detailChartInfo = null
        if(graphType === 'radiationIntencity') {
          this.$store.commit('Project/setData', { label: 'chartData', data: data })
          this.$store.commit('Project/setData', { label: 'chartOptions', data: options })
        } else {
          this.$store.commit('Project/setData', { label: 'probabilityChartData', data: probabilityData })
          this.$store.commit('Project/setData', { label: 'probabilityChartOptions', data: probabilityOptions })
        }
      } else {
        return probabilityData.datasets[0].data
      }
    }
  },
};
