export const calculateHorisontalJetFire = {
  methods: {
    calculateHorisontalJetFire(activeAccident, saveMode) {
      const RADIATION_INTENSITY_FIRE_JET = 10; // интенсивность теплового излучения горизонтального факела в интервале расстояний от длины факела до 1,5 длины факела, кВт/м2
      const SECTOR_COEFFICIENT = 0.083; // коэффициент, учитывающий, что поражение человека в горизонтальном факеле происходит в 30-градусном секторе с радиусом, равным длине факела
      const HUMAN_TIME = 5; // характерное время, за которое человек обнаруживает пожар и принимает решение о своих дальнейших действиях, с
      const HUMAN_VELOCITY = 5; // средняя скорость человека к безопасной зоне, м/c

      let classOfSubstanceJetFire = this.currentObject.substance.classOfSubstanceJetFire; // класс вещества в оборудовании (для расчета факела): сжатый газ (compressedGas), паровая фаза сжиженного газа (vaporPhaseLiquefiedGas), жидкая фаза сжиженного газа или жидкость (liquidPhaseLiquefiedGasOrLiquid)
      let massSubstanceRate = this.currentObject.failureParameterObj[activeAccident].failureParameter.massSubstanceRate; // расход вещества через отверстие, кг/c

      let probitFunctionTable = [-5.0,-4.5,-4.0,-3.5,-3.0,-2.5,-2.0,-1.5,-1.0,-0.5,0.0,0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,11.0,11.5,12.0,12.5,13.0,13.5,14.0,14.5,15.0];
      let probabilityOfDamageTable = [0,0,0,0,0,3.191e-14,1.280e-12,4.016e-11,9.866e-10,1.899e-8,2.867e-7,3.398e-6,3.167e-5,2.326e-4,1.350e-3,6.210e-3,0.023,0.067,0.159,0.309,0.500,0.691,0.841,0.933,0.977,0.994,0.999,1,1,1,1,1,1,1,1,1,1,1,1,1,1,]

      let distanceFromEquipment = []; // расстояние от оборудования, м
      for (let i = 0; i < 10000; i++) {
        distanceFromEquipment[i] = i;
      }

      let empiricalCoefficient; // значение эмпирического коэффициента
      let jetFireLength; // длина факела при струйном горении, м
      //let jetFireWidth; // ширина факела при струйном горении, м
      let effectiveExposureTime = []; // эффективное время экспозиции, с
      let probitFunction = []; // пробит функция
      let indexProbability = []; //индекс массива, рядом с которым находится значение условной вероятности поражения  
      let probabilityOfDamage = []; // условная вероятность поражения человека тепловым излучением горизонтального факела 

      if (classOfSubstanceJetFire === "compressedGas") {empiricalCoefficient = 12.5}
      if (classOfSubstanceJetFire === "vaporPhaseLiquefiedGas") {empiricalCoefficient = 13.5}
      if (classOfSubstanceJetFire === "liquidPhaseLiquefiedGasOrLiquid") {empiricalCoefficient = 15}
      jetFireLength = empiricalCoefficient * Math.pow(massSubstanceRate, 0.4);
/*       jetFireWidth = 0.15 * jetFireLength; */
      for (let i = 0; i < distanceFromEquipment.length; i++) {
        if (distanceFromEquipment[i] <= jetFireLength) {
          probabilityOfDamage[i] = SECTOR_COEFFICIENT;
        } else if (distanceFromEquipment[i] > jetFireLength && distanceFromEquipment[i] <= 1.5 * jetFireLength) {
          effectiveExposureTime[i] = HUMAN_TIME + (1.5 * jetFireLength - distanceFromEquipment[i]) / HUMAN_VELOCITY;
          probitFunction[i] = -12.8 + 2.56 * Math.log(effectiveExposureTime[i] * Math.pow(RADIATION_INTENSITY_FIRE_JET, 4 / 3));
          indexProbability[i] = Math.trunc((probitFunction[i] + 5) / 0.5);
          if (probitFunction[i] < -5) {
            probabilityOfDamage[i] = 0;
          } else if (indexProbability[i] <= 39) {
            probabilityOfDamage[i] = SECTOR_COEFFICIENT * (probabilityOfDamageTable[indexProbability[i]] + (probitFunction[i] - probitFunctionTable[indexProbability[i]]) * (probabilityOfDamageTable[indexProbability[i] + 1] - probabilityOfDamageTable[indexProbability[i]]) / 0.5);
          } else if(indexProbability[i] > 39) {
            probabilityOfDamage[i] = SECTOR_COEFFICIENT;
          }
        } else {
          probabilityOfDamage[i] = 0;
        }
      }

      if(saveMode){
        return {
          probabilityOfDamage: probabilityOfDamage,
          jetFireLength: jetFireLength
        }
      }

      this.$store.commit('Project/setData', { label: 'detailChartInfo', data: `Длина факела при струйном горении, м: ${jetFireLength.toFixed(1)}` })
      
      let probabilityOfDamageGraph = [];
		
      for (let i = 0; i < 10000; i++) {
        probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
      }
    
      while (probabilityOfDamageGraph[probabilityOfDamageGraph.length - 1] === 0) {
        probabilityOfDamageGraph.pop();
      }

      let abscissaProb = [];
      for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
        abscissaProb[i] = i;
      }    

      let data,options

      this.showDetailsTable = false
      
      data  = {
        labels: abscissaProb,
        datasets: [{
          label: 'Условная вероятность поражения человека на открытом пространстве',
          data: probabilityOfDamageGraph,
          borderColor: '#3278e1',
          pointRadius: 0,
        }]
      }

      options = {
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: 'Расстояние от оборудования, м'
            }
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Условная вероятность поражения, %'
            }
          },
        },
        plugins: {
          legend: {
            labels: {
              boxWidth: 0,
              color: '#3278e1',
              font: {
                size: 16,
              }
            }
          },
          datalabels: {
            display: false
          }
        }
      }

      if(!saveMode){
        this.$store.commit('Project/setData', { label: 'chartData', data: data })
        this.$store.commit('Project/setData', { label: 'chartOptions', data: options })
      } else {
        return data.datasets[0].data
      }
    }
  },
};
