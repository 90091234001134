export const probabilityOfDamageChart = {
  methods: {
    probabilityOfDamageChart() {

      let сhartData = null

      let potRiskGraph = []

      for (let i = 0; i < 10000; i++) {
        potRiskGraph[i] = 0;
      }

      this.currentObject.failureParameterObj.map((element, indx) => {
        element.trees.map((treeElement) => {
          treeElement.fires.map((element, fireindx) => {
            switch (element.value) {
              case 0:
                break;
              case 1:
                сhartData = this.calculatePoolFire('probabilityOfDamage', indx, true)
                break;
              case 2:
                сhartData = this.calculateBallFire('probabilityOfDamage', indx, true)
                break;
              case 3:
                сhartData = this.calculateFlashFire(indx , true)
                break;
              case 4:
                сhartData = this.calculateHorisontalJetFire(indx , true)
                break;
              case 5:
                сhartData = this.calculateVerticalJetFire('probabilityOfDamage', indx , true)
                break;
              case 6:
                сhartData = this.calculateCloudExplosionFire('probabilityOfDamage', indx , true)
                break;
              case 7:
                сhartData = this.calculateTankExplosionFire('probabilityOfDamage', indx , true)
                break;
            }

            for (let i = 0; i < 10000; i++) {
              potRiskGraph[i] +=  treeElement.frequencyScenario[fireindx] * сhartData.probabilityOfDamage[i];
            }
          })
        })
      })

      while (potRiskGraph[potRiskGraph.length - 1] < 1e-10) {
        potRiskGraph.pop();
      }

      let abscissaPotRisk = [];
      for (let i = 0; i < potRiskGraph.length; i++) {
          abscissaPotRisk[i] = i;
      }

      let distance1PotRisk; //расстояние от оборудования, где величина потенциального риска равна 1e-4
      let distance2PotRisk; //расстояние от оборудования, где величина потенциального риска равна 1e-6
      let distance3PotRisk; //расстояние от оборудования, где величина потенциального риска равна 1e-8
      
      for (let i = 0; i < potRiskGraph.length; i++) {
        if (potRiskGraph[i] >= 1e-4) {distance1PotRisk = i;}
        if (potRiskGraph[i] >= 1e-6) {distance2PotRisk = i;}
        if (potRiskGraph[i] >= 1e-8) {distance3PotRisk = i;}
      }
     
      this.chartData  = {
        labels: abscissaPotRisk,
        datasets: [{
          label: 'Интегральный потенциальный риск',
          data: potRiskGraph,
          backgroundColor: [],
          borderColor: [],
          pointRadius: [],
        }]
      }
     
      this.chartOptions = {
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: 'Расстояние от оборудования, м'
            }
          },
          y: {
            display: true,
            title: {
              display: true,
              text: this.currentObject.objDetailData.baseData.pipeType ? 'Потенциальный риск, год -1 * м -1' : 'Потенциальный риск, год -1'
            },
            type: 'logarithmic',
            ticks: {
              callback: function(value) {
                return value.toExponential(0);
              }
            }		
          },
        },
        plugins: {
          legend: {
            labels: {
              boxWidth: 0,
              color: '#3278e1',
              font: {
                size: 16,
              }
            }
          },
          datalabels: {
              display: function(context) {
                    return (context.dataIndex === distance1PotRisk || context.dataIndex === distance2PotRisk || context.dataIndex === distance3PotRisk)
                  },
              formatter: function(value, context) {
                if (context.dataIndex === distance1PotRisk) {
                  return 1e-4.toExponential(0);
                } else if (context.dataIndex === distance2PotRisk) {
                  return 1e-6.toExponential(0);
                } else if (context.dataIndex === distance3PotRisk) {
                  return 1e-8.toExponential(0);
                }
              },
              color: 'white',
              borderRadius: 10,
              backgroundColor: '#ff4d00',
              align: '-45',
              padding: {
                top: 5,
                right: 5,
                bottom: 5,
                left: 5
              },
              offset: 10,
          }
        }
      }
      for (let i = 0; i < this.chartData.labels.length; i++) {
        if (this.chartData.labels[i] === distance1PotRisk || this.chartData.labels[i] === distance2PotRisk || this.chartData.labels[i] === distance3PotRisk) {
          this.chartData.datasets[0].pointRadius[i] = 5;
          this.chartData.datasets[0].backgroundColor[i] = '#ff4d00';
          this.chartData.datasets[0].borderColor[i] = '#ff4d00';
        } else {
          this.chartData.datasets[0].borderColor[i] = '#3278e1';
          this.chartData.datasets[0].pointRadius[i] = 0;
        }
      }
    }
  }
}
