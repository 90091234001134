export const calculatePoolFire = {
  methods: {
    calculatePoolFire(graphType, activeAccident, saveMode) {
      const AIR_DENSITY = 1.29 // плотность воздуха при нормальных условиях, кг/м3
      const GRAVITY = 9.807 // ускорение свободного падения, м/с2
      const MOLAR_VOLUME = 22.414 // молярный объем пара при нормальных условиях, м3/кмоль
      const HUMAN_TIME = 5 // характерное время, за которое человек обнаруживает пожар и принимает решение о своих дальнейших действиях, с
      const HUMAN_VELOCITY = 5 // средняя скорость человека к безопасной зоне, м/c

      let nameOfSubstancePoolFire = this.currentObject.substance.nameOfSubstancePoolFire // наименование вещества в оборудовании (для расчета пожара пролива): СПГ(LNG), СУГ(LPG), бензин(gasoline), дизельное топливо(diesel), нефть иили нефтепродукт(oilOrOilProduct), однокомпонентная жидкость(oneComponentLiquid)

      // средние скорости ветра по направлениям в июле в регионе расположения предприятия, м/c
      let northWindVelocity = this.region.northWindVelocity // северный
      let northEastWindVelocity = this.region.northEastWindVelocity // северо-восточный
      let eastWindVelocity = this.region.eastWindVelocity // восточный
      let southEastWindVelocity = this.region.southEastWindVelocity // юго-восточный
      let southWindVelocity = this.region.southWindVelocity // южный
      let southWestWindVelocity = this.region.southWestWindVelocity // юго-западный
      let westWindVelocity = this.region.westWindVelocity // западный
      let northWestWindVelocity = this.region.northWestWindVelocity // северо-западный
          
      let molarMass = this.currentObject.substance.molarMass // молярная масса вещества, кг/кмоль
      let massBurningRate = this.currentObject.substance.massBurningRate // удельная массовая скорость выгорания, кг/(м2*с)
      let boilingTemperature = this.currentObject.substance.boilingTemperature // температура кипения жидкости (сжиженного газа) при атмосферном давлении, K

      let actualSpillArea = this.currentObject.failureParameterObj[activeAccident].failureParameter.actualSpillArea; // фактическая площадь пролива, м2

      let probitFunctionTable = [-5.0,-4.5,-4.0,-3.5,-3.0,-2.5,-2.0,-1.5,-1.0,-0.5,0.0,0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,11.0,11.5,12.0,12.5,13.0,13.5,14.0,14.5,15.0];
      let probabilityOfDamageTable = [0,0,0,0,0,3.191e-14,1.280e-12,4.016e-11,9.866e-10,1.899e-8,2.867e-7,3.398e-6,3.167e-5,2.326e-4,1.350e-3,6.210e-3,0.023,0.067,0.159,0.309,0.500,0.691,0.841,0.933,0.977,0.994,0.999,1,1,1,1,1,1,1,1,1,1,1,1,1,1,]

      /*  */

      let distanceFromEquipment = []; // расстояние от оборудования, м
      for (let i = 0; i < 10000; i++) {
        distanceFromEquipment[i] = i;
      }

      let maxOfAverageWindVelocity; // максимальная из средних скоростей ветра по направлениям в июле в регионе расположения предприятия, м/c
/*       let windVelocity = []; // скорость ветра в зависимости от направения, м/c */
      let characteristicSpillDiameter; // характерный диаметр фактической площади пролива, м2
      let vaporDensityAtBoilingPoint; //плотность паров топлива при температуре кипения, кг/м3 
      let radiationDensityPoolFire; // среднеповерхностная плотность теплового излучения пламени, кВт/м2
      let parameterUz; // параметр u со звездочкой
      let flameLength; // длина пламени, м
      let flameAngle; // угол отклонения пламени под действием ветра, рад.
      let pa; // параметр a
      let pb; // параметр b
      let pA; // параметр A
      let pB; // параметр B
      let pC; // параметр C
      let pD; // параметр D
      let pE; // параметр E
      let pF; // параметр F
      let pFv; // фактор облученности для вертикальной площадки
      let pFh; // фактор облученности для горизонтальной площадки
      let radiatinCoefficient; // угловой коэффициент облученности
      let atmosphericTransmittance; // коэффициент пропускания атмосферы
      let radiationIntencity; // интенсивность теплового излучения пожара пролива, кВт/м2 
      let safetyDistance; // безопасное расстояние, на котором интенсивность теплового излучения равна 4 кВт/м2
      let effectiveExposureTime = []; // эффективное время экспозиции, с
      let radiationDose = []; // доза облучения
      let probitFunction = []; // пробит функция
      let indexProbability = []; //индекс массива, рядом с которым находится значение условной вероятности поражения  
      let probabilityOfDamage = []; //условная вероятность поражения человека тепловым излучением пожара пролива

      characteristicSpillDiameter = Math.sqrt(4 * actualSpillArea / Math.PI);
      maxOfAverageWindVelocity = Math.max(northWindVelocity, northEastWindVelocity, eastWindVelocity, southEastWindVelocity, southWindVelocity, southWestWindVelocity, westWindVelocity, northWestWindVelocity);
      vaporDensityAtBoilingPoint = molarMass / (MOLAR_VOLUME * (boilingTemperature / 273.15));
      parameterUz = maxOfAverageWindVelocity / Math.pow(massBurningRate * GRAVITY * characteristicSpillDiameter / vaporDensityAtBoilingPoint, 1 / 3);
      
      if (parameterUz >= 1) {
        flameLength = 55 * characteristicSpillDiameter * Math.pow(massBurningRate / (AIR_DENSITY * Math.pow(GRAVITY * characteristicSpillDiameter, 1 / 2)), 0.67) * Math.pow(parameterUz, 0.21);
      } else {
        flameLength = 42 * characteristicSpillDiameter * Math.pow(massBurningRate / (AIR_DENSITY * Math.pow(GRAVITY * characteristicSpillDiameter, 1 / 2)), 0.61);
      }
      if (parameterUz < 1) {
        flameAngle = Math.acos(1);
      } else {
        flameAngle = Math.acos(Math.pow(parameterUz, -0.5));
      }

      switch (nameOfSubstancePoolFire) {
        case "LNG":
          if (characteristicSpillDiameter < 10) {
            radiationDensityPoolFire = 220;
          } else if (characteristicSpillDiameter > 50) {
            radiationDensityPoolFire = 120;
          } else {
            radiationDensityPoolFire = 0.05 * Math.pow(characteristicSpillDiameter, 2) - 5.5 * characteristicSpillDiameter + 270;
          }
          break;
        case "LPG":
          if (characteristicSpillDiameter < 10) {
            radiationDensityPoolFire = 80;
          } else if (characteristicSpillDiameter > 50) {
            radiationDensityPoolFire = 40;
          } else {
            radiationDensityPoolFire = 0.0243 * Math.pow(characteristicSpillDiameter, 2) - 2.4571 * characteristicSpillDiameter + 102.2;
          }
          break;
        case "gasoline":
          if (characteristicSpillDiameter < 10) {
            radiationDensityPoolFire = 60;
          } else if (characteristicSpillDiameter > 50) {
            radiationDensityPoolFire = 25;
          } else {
            radiationDensityPoolFire = 0.0179 * Math.pow(characteristicSpillDiameter, 2) - 1.9614 * characteristicSpillDiameter + 78.2;
          }
          break;
        case "diesel":
          if (characteristicSpillDiameter < 10) {
            radiationDensityPoolFire = 40;
          } else if (characteristicSpillDiameter > 50) {
            radiationDensityPoolFire = 18;
          } else {
            radiationDensityPoolFire = 0.0093 * Math.pow(characteristicSpillDiameter, 2) - 1.1071 * characteristicSpillDiameter + 50.2;
          }
          break;
        case "oilOrOilProduct":
          radiationDensityPoolFire = 140 * Math.exp(-0.12 * characteristicSpillDiameter) + 20 * (1 - Math.exp(-0.12 * characteristicSpillDiameter));
          break;
        case "oneComponentLiquid":
          radiationDensityPoolFire = 0.4 * this.substance.massBurningRate * this.substance.specificHeatOfCombustion / (1 + 4 * flameLength / characteristicSpillDiameter);
          break;
      }
      pa = 2 * flameLength / characteristicSpillDiameter;
      safetyDistance = Math.trunc(characteristicSpillDiameter / 2) + 1;
      do {
        pb = 2 * safetyDistance / characteristicSpillDiameter;
        pA = Math.sqrt(Math.pow(pa, 2) + Math.pow(pb + 1, 2) - 2 * pa * (pb + 1) * Math.sin(flameAngle));
        pB = Math.sqrt(Math.pow(pa, 2) + Math.pow(pb - 1, 2) - 2 * pa * (pb - 1) * Math.sin(flameAngle));
        pC = Math.sqrt(1 + (Math.pow(pb, 2) - 1) * Math.pow(Math.cos(flameAngle), 2));
        pD = Math.sqrt((pb - 1) / (pb + 1));
        pE = pa * Math.cos(flameAngle) / (pb - pa * Math.sin(flameAngle));
        pF = Math.sqrt(Math.pow(pb, 2) - 1);
        pFv = (1 / Math.PI) * (-pE * Math.atan(pD) + pE * ((Math.pow(pa, 2) + Math.pow(pb + 1, 2) - 2 * pb * (1 + pa * Math.sin(flameAngle))) / (pA * pB)) * Math.atan(pA * pD / pB) + (Math.cos(flameAngle) / pC) * (Math.atan((pa * pb - Math.pow(pF, 2) * Math.sin(flameAngle)) / (pF * pC)) + Math.atan(Math.pow(pF, 2) * Math.sin(flameAngle) / (pF * pC)))); 
        pFh = (1 / Math.PI) * (Math.atan(1 / pD) + (Math.sin(flameAngle) / pC) * (Math.atan((pa * pb - Math.pow(pF, 2) * Math.sin(flameAngle)) / (pF * pC)) + Math.atan((Math.pow(pF, 2) * Math.sin(flameAngle)) / (pF * pC))) - ((Math.pow(pa, 2) + Math.pow(pb + 1, 2) - 2 * (pb + 1 + pa * pb * Math.sin(flameAngle))) / (pA * pB)) * Math.atan(pA * pD / pB));
        radiatinCoefficient = Math.sqrt(Math.pow(pFv, 2) + Math.pow(pFh, 2));
        atmosphericTransmittance = Math.exp(-7 * (safetyDistance - 0.5 * characteristicSpillDiameter) / 10000);
        radiationIntencity = radiationDensityPoolFire * radiatinCoefficient * atmosphericTransmittance;
        safetyDistance++;
      } while (radiationIntencity >= 4);
      
      safetyDistance = safetyDistance - 1;

      pb = [];
      pA = [];
      pB = [];
      pC = [];
      pD = [];
      pE = [];
      pF = [];
      pFv = [];
      pFh = [];
      radiatinCoefficient = [];
      atmosphericTransmittance = [];
      radiationIntencity = [];
      effectiveExposureTime = [];
      radiationDose = [];
      probitFunction = [];
      indexProbability = [];
      probabilityOfDamage = [];

      for (let i = 0; i < distanceFromEquipment.length; i++) {

        if (distanceFromEquipment[i] <= Math.trunc(characteristicSpillDiameter / 2 + 1)) {
          radiationIntencity[i] = radiationDensityPoolFire;
    
        } else {
          pb[i] = 2 * distanceFromEquipment[i] / characteristicSpillDiameter;
          pA[i] = Math.sqrt(Math.pow(pa, 2) + Math.pow(pb[i] + 1, 2) - 2 * pa * (pb[i] + 1) * Math.sin(flameAngle));
          pB[i] = Math.sqrt(Math.pow(pa, 2) + Math.pow(pb[i] - 1, 2) - 2 * pa * (pb[i] - 1) * Math.sin(flameAngle));
          pC[i] = Math.sqrt(1 + (Math.pow(pb[i], 2) - 1) * Math.pow(Math.cos(flameAngle), 2));
          pD[i] = Math.sqrt((pb[i] - 1) / (pb[i] + 1));
          pE[i] = pa * Math.cos(flameAngle) / (pb[i] - pa * Math.sin(flameAngle));
          pF[i] = Math.sqrt(Math.pow(pb[i], 2) - 1);
    
          pFv[i] = (1 / Math.PI) * (-pE[i] * Math.atan(pD[i]) + pE[i] * ((Math.pow(pa, 2) + Math.pow(pb[i] + 1, 2) - 2 * pb[i] * (1 + pa * Math.sin(flameAngle))) / (pA[i] * pB[i])) * Math.atan(pA[i] * pD[i] / pB[i]) + (Math.cos(flameAngle) / pC[i]) * (Math.atan((pa * pb[i] - Math.pow(pF[i], 2) * Math.sin(flameAngle)) / (pF[i] * pC[i])) + Math.atan(Math.pow(pF[i], 2) * Math.sin(flameAngle) / (pF[i] * pC[i])))); 
    
          pFh[i] = (1 / Math.PI) * (Math.atan(1 / pD[i]) + (Math.sin(flameAngle) / pC[i]) * (Math.atan((pa * pb[i] - Math.pow(pF[i], 2) * Math.sin(flameAngle)) / (pF[i] * pC[i])) + Math.atan((Math.pow(pF[i], 2) * Math.sin(flameAngle)) / (pF[i] * pC[i]))) - ((Math.pow(pa, 2) + Math.pow(pb[i] + 1, 2) - 2 * (pb[i] + 1 + pa * pb[i] * Math.sin(flameAngle))) / (pA[i] * pB[i])) * Math.atan(pA[i] * pD[i] / pB[i]));
    
          radiatinCoefficient[i] = Math.sqrt(Math.pow(pFv[i], 2) + Math.pow(pFh[i], 2));
    
          atmosphericTransmittance[i] = Math.exp(-7 * (distanceFromEquipment[i] - 0.5 * characteristicSpillDiameter) / 10000);
    
          radiationIntencity[i] = radiationDensityPoolFire * radiatinCoefficient[i] * atmosphericTransmittance[i];
        }
    
        if (distanceFromEquipment[i] > safetyDistance) {
          effectiveExposureTime[i] = 0;
          radiationDose[i] = 0;
          probitFunction[i] = 0;
          probabilityOfDamage[i] = 0
        } else if (distanceFromEquipment[i] <= characteristicSpillDiameter / 2) {
          effectiveExposureTime[i] = 0;
          radiationDose[i] = 0;
          probitFunction[i] = 0;
          probabilityOfDamage[i] = 1;
        } else {
          effectiveExposureTime[i] = HUMAN_TIME + (safetyDistance - distanceFromEquipment[i]) / HUMAN_VELOCITY;
          radiationDose[i] = effectiveExposureTime[i] * Math.pow(radiationIntencity[i], 4 / 3);
          probitFunction[i] = -12.8 + 2.56 * Math.log(radiationDose[i]);
          indexProbability[i] = Math.trunc((probitFunction[i] + 5) / 0.5);
          if (probitFunction[i] < -5) {
            probabilityOfDamage[i] = 0;
          } else if (indexProbability[i] <= 39) {
            probabilityOfDamage[i] = probabilityOfDamageTable[indexProbability[i]] + (probitFunction[i] - probitFunctionTable[indexProbability[i]]) * (probabilityOfDamageTable[indexProbability[i] + 1] - probabilityOfDamageTable[indexProbability[i]]) / 0.5;
          } else if (indexProbability[i] > 39) {
          probabilityOfDamage[i] = 1;
          }
        }
      }

      if(saveMode){
        return  {
          radiationIntencity: radiationIntencity,
          probabilityOfDamage: probabilityOfDamage,
        }
      }

      let radiationIntencityGraph = [];
      let probabilityOfDamageGraph = [];
      
      
      for (let i = 0; i < 10000; i++) {
        radiationIntencityGraph[i] = radiationIntencity[i];
        probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
      }

      while (radiationIntencityGraph[radiationIntencityGraph.length - 1] < 1) {
        radiationIntencityGraph.pop();
      }

      while (probabilityOfDamageGraph[probabilityOfDamageGraph.length - 1] === 0) {
        probabilityOfDamageGraph.pop();
      }
      let distance1; //расстояние от оборудования, где интенсивность излучения равна 10.5 кВт/м2
      let distance2; //расстояние от оборудования, где интенсивность излучения равна 7.0 кВт/м2
      let distance3; //расстояние от оборудования, где интенсивность излучения равна 4.2 кВт/м2
      let distance4; //расстояние от оборудования, где интенсивность излучения равна 1.4 кВт/м2

      if(!saveMode){
        for (let i = 0; i < radiationIntencityGraph.length; i++) {
          if (radiationIntencityGraph[i] >= 10.5) {distance1 = i;}
          if (radiationIntencityGraph[i] >= 7.0) {distance2 = i;}
          if (radiationIntencityGraph[i] >= 4.2) {distance3 = i;}
          if (radiationIntencityGraph[i] >= 1.4) {distance4 = i;}
        }
        this.$store.commit('Project/setData', { label: 'graphTableData', data: [distance1,distance2,distance3,distance4] })
      }
      
      let abscissaRad = [];
      for (let i = 0; i < radiationIntencityGraph.length; i++) {
        if(radiationIntencityGraph[i] > 0) {
          abscissaRad[i] = i;
        }
        
      }
    
      let abscissaProb = [];
      for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
        if(probabilityOfDamageGraph[i] > 0.01) {
          abscissaProb[i] = i;
        }
      }

      let data,options,probabilityData,probabilityOptions

      if(graphType === 'radiationIntencity') {
        this.showDetailsTable = true
        this.tableType = ''
        data  = {
          labels: abscissaRad,
          datasets: [
            {
              label: 'Интенсивность теплового излучения пожара пролива',
              data: radiationIntencityGraph,
              backgroundColor: [],
              borderColor: [],
              pointRadius: [],
            }
          ],
        }
  
        options =  {
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Расстояние от оборудования, м'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Интенсивность теплового излучения, кВт/м2'
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
                color: '#3278e1',
                font: {
                  size: 16,
                }
              }
            },
            datalabels: {
              display: function(context) {
                return (context.dataIndex === distance1 || context.dataIndex === distance2 || context.dataIndex === distance3 || context.dataIndex === distance4)
              },
              formatter: function(value, context) {
                if (context.dataIndex === distance1) {
                  return 1;
                } else if (context.dataIndex === distance2) {
                  return 2;
                } else if (context.dataIndex === distance3) {
                  return 3;
                } else if (context.dataIndex === distance4) {
                  return 4;
                }
              },
              color: 'white',
              borderRadius: 10,
              backgroundColor: '#ff4d00',
              align: '-45',
              padding: {
                top: 5,
                right: 5,
                bottom: 5,
                left: 5
              },
              offset: 10,
            }
          }
        }
  
        for (let i = 0; i < data.labels.length; i++) {
          if (data.labels[i] === distance1 || data.labels[i] === distance2 || data.labels[i] === distance3 || data.labels[i] === distance4) {
            data.datasets[0].pointRadius[i] = 5;
            data.datasets[0].backgroundColor[i] = '#ff4d00';
            data.datasets[0].borderColor[i] = '#ff4d00';
          } else {
            data.datasets[0].borderColor[i] = '#3278e1';
            data.datasets[0].pointRadius[i] = 0;
          }
        }
      } else {
        this.showDetailsTable = false
        probabilityData  = {
          labels: abscissaProb,
          datasets: [{
            label: 'Условная вероятность поражения человека на открытом пространстве',
            data: probabilityOfDamageGraph,
            borderColor: '#3278e1',
            pointRadius: 0,
          }]
        }

        probabilityOptions = {
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Расстояние от оборудования, м'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Условная вероятность поражения, %'
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
                color: '#3278e1',
                font: {
                  size: 16,
                }
              }
            },
            datalabels: {
              display: false
            }
          }
        }
      }
      
      if(!saveMode){
        this.detailChartInfo = null
        if(graphType === 'radiationIntencity') {
          this.$store.commit('Project/setData', { label: 'chartData', data: data })
          this.$store.commit('Project/setData', { label: 'chartOptions', data: options })
        } else {
          this.$store.commit('Project/setData', { label: 'probabilityChartData', data: probabilityData })
          this.$store.commit('Project/setData', { label: 'probabilityChartOptions', data: probabilityOptions })
        }
      } else {
        return probabilityData.datasets[0].data
      }
      
    }
  },
};
