<template>
  <div class="project__content-risk">
    <div class="project__content-risk-info">
      <div class="project__content-risk-item">
        <div class="project__content-risk-name">
          Индивидуальный риск <br />
          для работников, год <sup>-1</sup>
        </div>
        <div class="project__content-risk-value" :class="risks ? risks.colorIndRiskWorkers : ''">
          {{ risks && risks.indRiskWorkers ? risks.indRiskWorkers.toExponential(3) : 0 }}
        </div>
      </div>
      <div class="project__content-risk-item">
        <div class="project__content-risk-name">
          Индивидуальный риск <br />
          для населения, год <sup>-1</sup>
        </div>
        <div class="project__content-risk-value" :class="risks ? risks.colorIndRiskPersons : ''">
          {{ risks && risks.indRiskPersons ? risks.indRiskPersons.toExponential(3) : 0 }}
        </div>
      </div>
      <div class="project__content-risk-item">
        <div class="project__content-risk-name">
          Социальный риск для <br />
          населения, год <sup>-1</sup>
        </div>
        <div class="project__content-risk-value" :class="risks ? risks.colorSocRisk : ''">
          {{ risks && risks.socRisk ? risks.socRisk.toExponential(3) : 0 }}
        </div>
      </div>
    </div>
    <div class="project__content-risk-buttons">
      <el-tooltip effect="light" content="Для начала нужно заполнить все данные" placement="top-start" :disabled="!checkDisabelStatus()">
        <ButtonElement text="Рассчитать риски" color="white" @click="calculateRisk" :disabled="checkDisabelStatus()" />
      </el-tooltip>

      <el-tooltip effect="light" content="Для начала нужно заполнить все данные" placement="top-start" :disabled="!checkDisabelStatus()">
        <ButtonElement text="Скачать отчёт в Word" color="white" @click="docGenerator" :disabled="checkDisabelStatus('saveWord')" />
      </el-tooltip>
    </div>
    <div class="chart-example">
      <canvas id="myChart"></canvas>
    </div>
    <Loader v-show="showLoader" />
  </div>
</template>

<script>
import ButtonElement from './elements/ButtonElement'
import Loader from '@/components/Loader'
import { DocGenerator } from '@/mixin/doc_saver'
import { DateParser } from '@/mixin/date_parser'
const actions = new Worker('../worker.js', { type: 'module' });
export default {
  name: 'CalculateRisk',
  mixins: [DocGenerator, DateParser],
  components: {
    ButtonElement,
    Loader,
  },
  data() {
    return {
      showLoader: false,
      socRisk: 0,
      indRiskWorkers: 0,
      indRiskPersons: 0,

      colorSocRisk: '',
      colorIndRiskWorkers: '',
      colorIndRiskPersons: '',

      RADIATION_DENSITY_FIRE_BALL: 350,
      AIR_DENSITY: 1.29,
      GRAVITY: 9.807,
      MOLAR_VOLUME: 22.414,

      HUMAN_TIME: 5,
      HUMAN_VELOCITY: 5,

      EXPLOSION_PARTICIPATION_RATE: 0.1,
      SOUND_SPEED: 340,
      DEGREE_OF_EXPANSION: 7,

      CONST_43: 43,
      CONST_26: 26,

      ATMOSPHERE_PRESSURE: 101325,
      PRESSURE_WAVE_ENERGY_FRACTION: 0.5,

      SPECIFIC_HEAT_OF_LIQUID: 2000,
      RADIATION_INTENSITY_FIRE_JET: 10,

      SECTOR_COEFFICIENT: 0.083,
      RADIATION_DENSITY_FIRE_JET: 200,

      probabilityOfDamage: [],
      probabilityOfDamageTable: [0, 0, 0, 0, 0, 3.191e-14, 1.28e-12, 4.016e-11, 9.866e-10, 1.899e-8, 2.867e-7, 3.398e-6, 3.167e-5, 2.326e-4, 1.35e-3, 6.21e-3, 0.023, 0.067, 0.159, 0.309, 0.5, 0.691, 0.841, 0.933, 0.977, 0.994, 0.999, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      probitFunctionTable: [-5.0, -4.5, -4.0, -3.5, -3.0, -2.5, -2.0, -1.5, -1.0, -0.5, 0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0, 5.5, 6.0, 6.5, 7.0, 7.5, 8.0, 8.5, 9.0, 9.5, 10.0, 10.5, 11.0, 11.5, 12.0, 12.5, 13.0, 13.5, 14.0, 14.5, 15.0],

      xField: [],
      yField: [],
    }
  },

  computed: {
    presenceOfWall() {
      return this.$store.state.Project.presenceOfWall
    },
    wall() {
      return this.$store.state.Project.arrWall[0]
    },
    region() {
      return this.$store.state.Project.region
    },
    spaceClass() {
      return this.$store.state.Project.currentProjectGeneralData.class
    },
    currentProject() {
      return this.$store.state.Project
    },
    risks() {
      if (this.$store.state.Project.currentProject && this.$store.state.Project.currentProject.risks) {
        return this.$store.state.Project.currentProject.risks
      } else {
        return null
      }
    },
    user(){
      return this.$store.state.Personal.user
    },
  },

  methods: {
    checkDisabelStatus(button) {
      if(this.user && this.user.role === 'demo' && this.getDemoDays(this.user.role_expiration_date) === '0') {
        return true
      }

      if (
        this.currentProject &&
        !this.currentProject.arrEquipment.length &&
        !this.currentProject.arrPipeEquipment.length /*  ||
        !this.currentProject.arrBuilding.length ||
        !this.currentProject.arrWall.length ||
        !this.currentProject.arrWorker.length ||
        !this.currentProject.arrZone.length */
      ) {
        return true
      }

      if(button === 'saveWord' && !this.risks) {
        return true
      }

      return false
    },

    calculateRisk() {
      const message = {
        text: 'generateItems',
        arrBuilding: this.$store.state.Project.arrBuilding,
        arrZone: this.$store.state.Project.arrZone,
        arrWorkers: this.$store.state.Project.arrWorker,
        delta: this.$store.state.Project.currentProject.delta,
        pipes: this.$store.state.Project.arrPipeEquipment,
        arrEquipment: this.$store.state.Project.arrEquipment,
        canvas: {
          width: document.getElementById('canvas').width,
          height: document.getElementById('canvas').height,
        },
        wall: this.wall,
        deflectionAngle: this.$store.state.Project.currentProject.deflectionAngle,
        region: this.region,
        presenceOfWall: this.presenceOfWall,
        spaceClass: this.spaceClass,
      }
      actions.postMessage(JSON.stringify(message));
      this.showLoader = true
      actions.onmessage = e => {
        if (e.data.type === 'SET_ITEMS') {
          this.$store.commit('Project/setData', { label: 'potRisk', data: e.data.payload.potRisk })
          setTimeout(() => {
            this.$store.commit('Project/addDataToProject', { label: 'risks', data: e.data.payload.data })
          }, 500)
          this.showLoader = false
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';


.chart-example {
  width: 620px;
  height: 400px;
  position: fixed;
  left: -2000%;
}

#myChart {
  width: 100% !important;
  height: 100% !important;
}

.project__content-risk {
  width: 100%;
  border-radius: 1rem;
  background: $blue;
  padding: 2rem 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &-info {
    display: flex;
  }

  &-item {
    margin-right: 10rem;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &-name {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: $grey;
    margin-bottom: 1rem;
  }

  &-value {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: $basicWhite;
    font-weight: 700;

    &.red {
      color: red;
    }
    &.orange {
      color: orange;
    }
    &.green {
      color: #75f94c;
    }
  }

  &-buttons {
    display: flex;
  }
}
</style>
