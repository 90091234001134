export const calculateBallFire = {
  methods: {
    calculateBallFire(graphType, activeAccident, saveMode) {
      const RADIATION_DENSITY_FIRE_BALL = 350;

      let probitFunctionTable = [-5.0,-4.5,-4.0,-3.5,-3.0,-2.5,-2.0,-1.5,-1.0,-0.5,0.0,0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,11.0,11.5,12.0,12.5,13.0,13.5,14.0,14.5,15.0];
      let probabilityOfDamageTable = [0,0,0,0,0,3.191e-14,1.280e-12,4.016e-11,9.866e-10,1.899e-8,2.867e-7,3.398e-6,3.167e-5,2.326e-4,1.350e-3,6.210e-3,0.023,0.067,0.159,0.309,0.500,0.691,0.841,0.933,0.977,0.994,0.999,1,1,1,1,1,1,1,1,1,1,1,1,1,1,]

      let distanceFromEquipment = []; // расстояние от оборудования, м
      for (let i = 0; i < 10000; i++) {
        distanceFromEquipment[i] = i;
      }

      let massSubstanceInEquipment = this.currentObject.failureParameterObj[activeAccident].failureParameter.massSubstanceInEquipment

      let effectiveDiameterFireBall = 6.48 * Math.pow(massSubstanceInEquipment, 0.325); // эффективный диаметр огненного шара, м
      let effectiveExposureTime = 0.92 * Math.pow(massSubstanceInEquipment, 0.303); // эффективное время экспозиции, с
      let centerHeightFireBall = effectiveDiameterFireBall; // эффективный диаметр огенного шара, м
      let radiatinCoefficient = []; // угловой коэффициент облученности
      let atmosphericTransmittance = []; // коэффициент пропускания атмосферы
      let radiationIntencity = []; // интенсивность теплового излучения огненного шара, кВт/м2 
      let probitFunction = []; // пробит функция для поражения человека тепловым излучением огненного шара 
      let indexProbability = []; //индекс массива, рядом с которым находится значение условной вероятности поражения  
      let probabilityOfDamage = []; //условная вероятность поражения человека тепловым излучением огенного шара 
      for (let i = 0; i < distanceFromEquipment.length; i++) {
        radiatinCoefficient[i] = Math.pow(effectiveDiameterFireBall, 2) / (4 * (Math.pow(centerHeightFireBall, 2) + Math.pow(distanceFromEquipment[i], 2)));
        atmosphericTransmittance[i] = Math.exp(-7 * (Math.sqrt(Math.pow(distanceFromEquipment[i], 2) + Math.pow(centerHeightFireBall, 2)) - effectiveDiameterFireBall / 2) / 10000);
        radiationIntencity[i] = RADIATION_DENSITY_FIRE_BALL * radiatinCoefficient[i] * atmosphericTransmittance[i];
        probitFunction[i] = -12.8 +2.56 * Math.log(effectiveExposureTime * Math.pow(radiationIntencity[i], 4 / 3));
        indexProbability[i] = Math.trunc((probitFunction[i] + 5) / 0.5);
        if (probitFunction[i] < -5) {
          probabilityOfDamage[i] = 0;
        } else if (indexProbability[i] <= 39) {
          probabilityOfDamage[i] = probabilityOfDamageTable[indexProbability[i]] + (probitFunction[i] - probitFunctionTable[indexProbability[i]]) * (probabilityOfDamageTable[indexProbability[i] + 1] - probabilityOfDamageTable[indexProbability[i]]) / 0.5;
        } else if(indexProbability[i] > 39) {
          probabilityOfDamage[i] = 1;
        }
      }

      if(saveMode){
        return {
          radiationIntencity: radiationIntencity,
          probabilityOfDamage: probabilityOfDamage,
        }
      }
      
      let radiationIntencityGraph = [];
      let probabilityOfDamageGraph = [];
      
      for (let i = 0; i < 10000; i++) {
        radiationIntencityGraph[i] = radiationIntencity[i];
        probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
      }

      while (radiationIntencityGraph[radiationIntencityGraph.length - 1] < 1) {
        radiationIntencityGraph.pop();
      }

      while (probabilityOfDamageGraph[probabilityOfDamageGraph.length - 1] === 0) {
        probabilityOfDamageGraph.pop();
      }
      
      let distance1; //расстояние от оборудования, где интенсивность излучения равна 10.5 кВт/м2
      let distance2; //расстояние от оборудования, где интенсивность излучения равна 7.0 кВт/м2
      let distance3; //расстояние от оборудования, где интенсивность излучения равна 4.2 кВт/м2
      let distance4; //расстояние от оборудования, где интенсивность излучения равна 1.4 кВт/м2

      if(!saveMode){
        for (let i = 0; i < radiationIntencityGraph.length; i++) {
          if (radiationIntencityGraph[i] >= 10.5) {distance1 = i;}
          if (radiationIntencityGraph[i] >= 7.0) {distance2 = i;}
          if (radiationIntencityGraph[i] >= 4.2) {distance3 = i;}
          if (radiationIntencityGraph[i] >= 1.4) {distance4 = i;}
        }
        
        this.$store.commit('Project/setData', { label: 'graphTableData', data: [distance1,distance2,distance3,distance4] })
      }

      let abscissaRad = [];
      for (let i = 0; i < radiationIntencityGraph.length; i++) {
        if(radiationIntencityGraph[i] > 0) {
          abscissaRad[i] = i;
        }
        
      }
    
      let abscissaProb = [];
      for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
        if(probabilityOfDamageGraph[i] > 0.01) {
          abscissaProb[i] = i;
        }
      }

      let data,options,probabilityData,probabilityOptions

      if(graphType === 'radiationIntencity') {
        this.showDetailsTable = true
        this.tableType = ''
        data  = {
          labels: abscissaRad,
          datasets: [
            {
              label: 'Интенсивность теплового излучения огненного шара',
              data: radiationIntencityGraph,
              backgroundColor: [],
              borderColor: [],
              pointRadius: [],
            }
          ],
        }
  
        options =  {
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Расстояние от оборудования, м'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Интенсивность теплового излучения, кВт/м2'
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
                color: '#3278e1',
                font: {
                  size: 16,
                }
              }
            },
            datalabels: {
              display: function(context) {
                return (context.dataIndex === distance1 || context.dataIndex === distance2 || context.dataIndex === distance3 || context.dataIndex === distance4)
              },
              formatter: function(value, context) {
                if (context.dataIndex === distance1) {
                  return 1;
                } else if (context.dataIndex === distance2) {
                  return 2;
                } else if (context.dataIndex === distance3) {
                  return 3;
                } else if (context.dataIndex === distance4) {
                  return 4;
                }
              },
              color: 'white',
              borderRadius: 10,
              backgroundColor: '#ff4d00',
              align: '-45',
              padding: {
                top: 5,
                right: 5,
                bottom: 5,
                left: 5
              },
              offset: 10,
            }
          }
        }
  
        for (let i = 0; i < data.labels.length; i++) {
          if (data.labels[i] === distance1 || data.labels[i] === distance2 || data.labels[i] === distance3 || data.labels[i] === distance4) {
            data.datasets[0].pointRadius[i] = 5;
            data.datasets[0].backgroundColor[i] = '#ff4d00';
            data.datasets[0].borderColor[i] = '#ff4d00';
          } else {
            data.datasets[0].borderColor[i] = '#3278e1';
            data.datasets[0].pointRadius[i] = 0;
          }
        }
      } else {
        this.showDetailsTable = false
        probabilityData  = {
          labels: abscissaProb,
          datasets: [{
            label: 'Условная вероятность поражения человека на открытом пространстве',
            data: probabilityOfDamageGraph,
            borderColor: '#3278e1',
            pointRadius: 0,
          }]
        }

        probabilityOptions = {
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Расстояние от оборудования, м'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Условная вероятность поражения, %'
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
                color: '#3278e1',
                font: {
                  size: 16,
                }
              }
            },
            datalabels: {
              display: false
            }
          }
        }
        
      }
      
      if(!saveMode){
        this.detailChartInfo = null
        if(graphType === 'radiationIntencity') {
          this.$store.commit('Project/setData', { label: 'chartData', data: data })
          this.$store.commit('Project/setData', { label: 'chartOptions', data: options })
        } else {
          this.$store.commit('Project/setData', { label: 'probabilityChartData', data: probabilityData })
          this.$store.commit('Project/setData', { label: 'probabilityChartOptions', data: probabilityOptions })
        }
      } else {
        return probabilityData.datasets[0].data
      }
    }
  },
};
