export const calculateFlashFire = {
  methods: {
    calculateFlashFire(activeAccident, saveMode) {
      const MOLAR_VOLUME = 22.414;

      let absoluteMaxTemperature = this.region.absoluteMaxTemperature; // абсолютная максимальная температура воздуха в городе расположения предприятия, К
      let averageMaxTemperature = this.region.averageMaxTemperature; // cредняя максимальная температура воздуха наиболее теплого месяца в городе расположения предприятия, К 

      let equipmentLocation = this.currentObject.objDetailData.baseData.equipmentLocation; // расположение оборудования: наземное или подземное

      let molarMass = this.currentObject.substance.molarMass; // молярная масса вещества, кг/кмоль

      let bottomConcentrationLimit = this.currentObject.substance.bottomConcentrationLimit; // нижний концентрационный предел распространения пламени, %(об.)

      let massSubstanceInCloud = this.currentObject.failureParameterObj[activeAccident].failureParameter.massSubstanceInCloud // масса газа или пара, поступившего в открытое пространство, кг

      let distanceFromEquipment = []; // расстояние от оборудования, м
      for (let i = 0; i < 10000; i++) {
        distanceFromEquipment[i] = i;
      }

      let estimatedTemperatureOfLiquid; // расчетная температура жидкости, К
      let gasDensityEstimatedTemperature; // плотность газа или пара при расчетной температуре и атмосферном давлении кг/м3
      let zoneRadiusBottomConcentrationLimit; // радиус зоны, ограничивающей область концентраций, превышающих нижний концентрационный предел распространения пламени, м
      let radiusFlashFire; // радиус воздействия высокотемпературных продуктов сгорания газопаровоздушного облака, м
      let probabilityOfDamage = []; //условная вероятность поражения человека высокотемпературными продуктами сгорания

      if (equipmentLocation == "ground") {
        estimatedTemperatureOfLiquid = absoluteMaxTemperature;
      } else if (equipmentLocation == "underground") {
        estimatedTemperatureOfLiquid = averageMaxTemperature;
      }
      gasDensityEstimatedTemperature = molarMass / (MOLAR_VOLUME * (estimatedTemperatureOfLiquid / 273.15));
      zoneRadiusBottomConcentrationLimit = 7.8 * Math.pow(massSubstanceInCloud / (gasDensityEstimatedTemperature * bottomConcentrationLimit), 0.33);
      radiusFlashFire = 1.2 * zoneRadiusBottomConcentrationLimit;
      for (let i = 0; i < distanceFromEquipment.length; i++) {
        if (distanceFromEquipment[i] <= radiusFlashFire) {
          probabilityOfDamage[i] = 1;
        } else {
          probabilityOfDamage[i] = 0;
        }
      }

      if(saveMode){
        return {
          probabilityOfDamage: probabilityOfDamage,
          radiusFlashFire: radiusFlashFire
        }
      }

      this.$store.commit('Project/setData', { label: 'detailChartInfo', data: `Радиус воздействия высокотемпературных продуктов сгорания газопаровоздушного облака, м: ${radiusFlashFire.toFixed(1)}` })
      
      let probabilityOfDamageGraph = [];
		
      for (let i = 0; i < Math.ceil(2 * radiusFlashFire); i++) {
        probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
      }

      let abscissaProb = [];
      for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
        abscissaProb[i] = i;
      }

      let data,options

      this.showDetailsTable = false
      data  = {
        labels: abscissaProb,
        datasets: [{
          label: 'Условная вероятность поражения человека на открытом пространстве',
          data: probabilityOfDamageGraph,
          borderColor: '#3278e1',
          pointRadius: 0,
        }]
      }

      options = {
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: 'Расстояние от оборудования, м'
            }
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'Условная вероятность поражения, %'
            }
          },
        },
        plugins: {
          legend: {
            labels: {
              boxWidth: 0,
              color: '#3278e1',
              font: {
                size: 16,
              }
            }
          },
          datalabels: {
            display: false
          }
        }
      }

      if(!saveMode){
        this.$store.commit('Project/setData', { label: 'chartData', data: data })
        this.$store.commit('Project/setData', { label: 'chartOptions', data: options })
      }/*  else {
        return data.datasets[0].data
      } */
    }
  },
};
