export default {
  'liquid': 'Жидкость',
  'compressedGas': 'Сжатый газ',
  'liquefiedGasFluid': 'Жидкая фаза сжиженного газа',
  'liquefiedGasVapor': 'Паровая фаза сжиженного газа',
  'vaporPhaseLiquefiedGas': 'Паровая фаза сжиженного газа',
  'liquidPhaseLiquefiedGasOrLiquid': 'Жидкая фаза сжиженного газа или жидкость',
  'LNG': 'Сжиженный природный газ',
  'LPG': 'Сжиженный углеводородный газ',
  'gasoline': 'Бензин',
  'diesel': 'Дизельное топливо',
  'oilOrOilProduct': 'Нефть и другие нефтепродукты',
  'oneComponentLiquid': 'Однокомпонентная жидкость',
  'tank': 'Емкостное оборудование',
  'pump': 'Насос',
  'compressor': 'Компрессор',
  'ground': 'Наземное',
  'underground': 'Подземное',
  'concrete': 'Бетонное или асфальтовое покрытие',
  'plannedGround': 'Спланированная грунтовая поверхность',
  'unplannedGround': 'Неспланированная грунтовая поверхность',
  'newSurface': 'Другая поверхность',
  'true': 'Да',
  'false': 'Нет',
  'surfaceExplosionYes': 'Взрыв поверхностный - да',
  'surfaceExplosionNo': 'Взрыв поверхностный - нет',
  'pipelineConnectedTank': 'Трубопровод, присоединенный к емкости',
  'pipelineConnectedPump': 'Трубопровод, присоединенный к насосу',
  'pipelineConnectedCompressor': 'Трубопровод, присоединенный к компрессору',
  'class_1': 'Класс 1',
  'class_2': 'Класс 2',
  'class_3': 'Класс 3',
  'class_4': 'Класс 4',
  'class_I': 'Kласс I',
  'class_II': 'Kласс II',
  'class_III': 'Kласс III',
  'class_IV': 'Kласс IV',
}
