export const calculateTankExplosionFire = {
  methods: {
    calculateTankExplosionFire(graphType, activeAccident, saveMode) {
      const ATMOSPHERE_PRESSURE = 101325; // атмоссферное давление, Па
      const PRESSURE_WAVE_ENERGY_FRACTION = 0.5; // доля энергии волны давления
      const SPECIFIC_HEAT_OF_LIQUID = 2000; // удельная теплоемкость жидкости, Дж/(кг*К) (для расчета взрыва резервуара в очаге пожара)

      let constA = this.currentObject.substance.constA; // константа A уравнения Антуана;
      let constB = this.currentObject.substance.constB; // константа B уравнения Антуана;
      let constCa = this.currentObject.substance.constCa; // константа Ca уравнения Антуана;

      let boilingTemperature = this.currentObject.substance.boilingTemperature; // температура кипения жидкости (сжиженного газа) при атмосферном давлении, K
      let massSubstanceInEquipment = this.currentObject.failureParameterObj[activeAccident].failureParameter.massSubstanceInEquipment; // масса вещества, содержащаяся в емкости, кг
      let valvePressure = this.currentObject.objDetailData.baseData.valvePressure; // избыточное давление срабатывания предохранительного устройства, кПа

      let probitFunctionTable = [-5.0,-4.5,-4.0,-3.5,-3.0,-2.5,-2.0,-1.5,-1.0,-0.5,0.0,0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,11.0,11.5,12.0,12.5,13.0,13.5,14.0,14.5,15.0];
      let probabilityOfDamageTable = [0,0,0,0,0,3.191e-14,1.280e-12,4.016e-11,9.866e-10,1.899e-8,2.867e-7,3.398e-6,3.167e-5,2.326e-4,1.350e-3,6.210e-3,0.023,0.067,0.159,0.309,0.500,0.691,0.841,0.933,0.977,0.994,0.999,1,1,1,1,1,1,1,1,1,1,1,1,1,1,]

      let distanceFromEquipment = []; // расстояние от оборудования, м
      for (let i = 0; i < 10000; i++) {
        distanceFromEquipment[i] = i;
      }

      let tankTemperature; // температура в емкости при давлении срабатывания предохранительного устройства, К
      let effectiveExplosionEnergy; // эффективная энергия взрыва, Дж
      let givenMass; // приведенная масса, кг
      let overpressure = []; // избыточное давление взрыва, Па
      let pulse = []; // импульс фазы сжатия, Па*с;
      let funcV = []; // функция V;
      let probitFunction = []; // пробит функция
      let indexProbability = []; //индекс массива, рядом с которым находится значение условной вероятности поражения  
      let probabilityOfDamage = []; //условная вероятность поражения человека волной давления

      tankTemperature = constB / (constA - Math.log10(valvePressure + ATMOSPHERE_PRESSURE / 1000)) - constCa + 273.15;

      effectiveExplosionEnergy = PRESSURE_WAVE_ENERGY_FRACTION * SPECIFIC_HEAT_OF_LIQUID * massSubstanceInEquipment * (tankTemperature - boilingTemperature);

      givenMass = (effectiveExplosionEnergy / 4.52) * Math.pow(10, -6);
      for (let i = 0; i < distanceFromEquipment.length; i++) {
        
        if (distanceFromEquipment[i] <= 1) {
          overpressure[i] = ATMOSPHERE_PRESSURE * (0.8 * Math.pow(givenMass, 0.33) + 3 * Math.pow(givenMass, 0.66) + 5 * givenMass);
          pulse[i] = 123 * Math.pow(givenMass, 0.66);
        } else {
          overpressure[i] = ATMOSPHERE_PRESSURE * (0.8 * Math.pow(givenMass, 0.33) / distanceFromEquipment[i] + 3 * Math.pow(givenMass, 0.66) / Math.pow(distanceFromEquipment[i], 2) + 5 * givenMass / Math.pow(distanceFromEquipment[i], 3));
          pulse[i] = 123 * Math.pow(givenMass, 0.66) / distanceFromEquipment[i];
        }
        funcV[i] = Math.pow((17500 / overpressure[i]), 8.4) + Math.pow((290 / pulse[i]), 9.3);
        probitFunction[i] = 5 - 0.26 * Math.log(funcV[i]);
        indexProbability[i] = Math.trunc((probitFunction[i] + 5) / 0.5);
        if (probitFunction[i] < -5) {
          probabilityOfDamage[i] = 0;
        } else if (indexProbability[i] <= 39) {
          probabilityOfDamage[i] = probabilityOfDamageTable[indexProbability[i]] + (probitFunction[i] - probitFunctionTable[indexProbability[i]]) * (probabilityOfDamageTable[indexProbability[i] + 1] - probabilityOfDamageTable[indexProbability[i]]) / 0.5;
        } else if(indexProbability[i] > 39) {
          probabilityOfDamage[i] = 1;
        }
      }

      if(saveMode){
        return {
          overpressure: overpressure,
          probabilityOfDamage: probabilityOfDamage,
          tankTemperature: tankTemperature,
          boilingTemperature: boilingTemperature
        }
      }
      
      let overpressureGraph = [];
      let probabilityOfDamageGraph = [];
    
      for (let i = 0; i < 10000; i++) {
         overpressureGraph[i] = overpressure[i] / 1000;
         probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
       }
    
      while (overpressureGraph[overpressureGraph.length - 1] < 10) {
        overpressureGraph.pop();
      }
    
      while (probabilityOfDamageGraph[probabilityOfDamageGraph.length - 1] < 1e-3) {
        probabilityOfDamageGraph.pop();
      }
      
      let distance1; //расстояние от оборудования, где избыточное давление равно 100 кПа
      let distance2; //расстояние от оборудования, где избыточное давление равно 53 кПа
      let distance3; //расстояние от оборудования, где избыточное давление равно 28 кПа
      let distance4; //расстояние от оборудования, где избыточное давление равно 12 кПа

      if(!saveMode){
        for (let i = 0; i < overpressureGraph.length; i++) {
          if (overpressureGraph[i] >= 100) {distance1 = i;}
          if (overpressureGraph[i] >= 53) {distance2 = i;}
          if (overpressureGraph[i] >= 28) {distance3 = i;}
          if (overpressureGraph[i] >= 12) {distance4 = i;}
        }
  
        this.$store.commit('Project/setData', { label: 'graphDestructionTableData', data: [distance1,distance2,distance3,distance4] })
      }
      

      let abscissaPress = [];
      for (let i = 0; i < overpressureGraph.length; i++) {
        abscissaPress[i] = i;
      }
    
      let abscissaProb = [];
      for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
        abscissaProb[i] = i;
      }

      let data,options,probabilityData,probabilityOptions
      if(graphType === 'radiationIntencity') {
        this.showDetailsTable = true
        this.tableType = 'destruction'
        data  = {
          labels: abscissaPress,
          datasets: [
            {
              label: 'Избыточное давление взрыва резервуара',
              data: overpressureGraph,
              backgroundColor: [],
              borderColor: [],
              pointRadius: [],
            }
          ],
        }
  
        options =  {
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Расстояние от оборудования, м'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Избыточное давление взрыва, кПа'
              },
              max: 1000,
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
                color: '#3278e1',
                font: {
                  size: 16,
                }
              }
            },
            datalabels: {
              display: function(context) {
                return (context.dataIndex === distance1 || context.dataIndex === distance2 || context.dataIndex === distance3 || context.dataIndex === distance4)
              },
              formatter: function(value, context) {
                if (context.dataIndex === distance1) {
                  return 1;
                } else if (context.dataIndex === distance2) {
                  return 2;
                } else if (context.dataIndex === distance3) {
                  return 3;
                } else if (context.dataIndex === distance4) {
                  return 4;
                }
              },
              color: 'white',
              borderRadius: 10,
              backgroundColor: '#ff4d00',
              align: '-45',
              padding: {
                top: 5,
                right: 5,
                bottom: 5,
                left: 5
              },
              offset: 10,
            }
          }
        }
  
        for (let i = 0; i < data.labels.length; i++) {
          if (data.labels[i] === distance1 || data.labels[i] === distance2 || data.labels[i] === distance3 || data.labels[i] === distance4) {
            data.datasets[0].pointRadius[i] = 5;
            data.datasets[0].backgroundColor[i] = '#ff4d00';
            data.datasets[0].borderColor[i] = '#ff4d00';
          } else {
            data.datasets[0].borderColor[i] = '#3278e1';
            data.datasets[0].pointRadius[i] = 0;
          }
        }
      } else {
        this.showDetailsTable = false
        probabilityData  = {
          labels: abscissaPress,
          datasets: [{
            label: 'Условная вероятность поражения человека в здании',
            data: probabilityOfDamageGraph,
            borderColor: '#3278e1',
            pointRadius: 0,
          }]
        }

        probabilityOptions = {
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Расстояние от оборудования, м'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Условная вероятность поражения, %'
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
                color: '#3278e1',
                font: {
                  size: 16,
                }
              }
            },
            datalabels: {
              display: false
            }
          }
        }
        
      }

      if(!saveMode){
        this.detailChartInfo = null
        if(graphType === 'radiationIntencity') {
          this.$store.commit('Project/setData', { label: 'chartData', data: data })
          this.$store.commit('Project/setData', { label: 'chartOptions', data: options })
        } else {
          this.$store.commit('Project/setData', { label: 'probabilityChartData', data: probabilityData })
          this.$store.commit('Project/setData', { label: 'probabilityChartOptions', data: probabilityOptions })
        }
      } else {
        return probabilityData.datasets[0].data
      }
    }
  },
};
