import message from "@/messages/ru";
import Chart from 'chart.js/auto';
import { saveAs } from "file-saver";
import {
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  AlignmentType,
  Table,
  TableRow,
  TableCell,
  convertInchesToTwip,
  SectionType,
  ImageRun,
  HorizontalPositionRelativeFrom,
/*   HorizontalPositionAlign, */
  VerticalPositionRelativeFrom,
  VerticalPositionAlign,
  VerticalAlign,
  /*   WidthType, */
  /* TableOfContents, */
  /* TextRun, */
  /* PageBreak */
} from "docx";
import * as html2canvas from "html2canvas";

export const DocGenerator = {
  methods: {
    async docGenerator() {
      const project = this.$store.state.Project
      let buildingTable = []
      let workersTable = []
      let zonesTable = []
      let usedSubstancesNames = []
      let usedSubstances = []
      let usedSubstancesData = []
      let allEquipments = []

      let arrEquipment = []
      let arrEquipments = []
      let arrEquipmentsData = []

      let arrPipeEquipment = [] 
      let arrPipeEquipments = []
      let arrPipeEquipmentsData = []
      

      if(project.currentProject.arrEquipment && project.currentProject.arrEquipment.length) {
        allEquipments = allEquipments.concat(project.currentProject.arrEquipment)
        arrEquipment =  project.currentProject.arrEquipment
      }

      if(project.currentProject.arrPipeEquipment && project.currentProject.arrPipeEquipment.length) {
        allEquipments = allEquipments.concat(project.currentProject.arrPipeEquipment)
        arrPipeEquipment = project.currentProject.arrPipeEquipment
      }

      let objectFile = null
      await html2canvas(document.querySelector(".project__content-view-content"), { allowTaint: true, useCORS: true }).then(canvas => {
        objectFile = canvas.toDataURL("image/png");
      })

      let diagramFile = null
      await html2canvas(document.querySelector(".project__content-diagram"), { allowTaint: true, useCORS: true }).then(canvas => {
        diagramFile = canvas.toDataURL("image/png");
      })

      this.showedRisk = false

      let groupId = null

      const ctx = document.getElementById('myChart').getContext('2d')
      let chart = Chart.getChart("myChart") || null

      function failureName(failure) {
        if(failure.failure === 'hole') {
          return `Локальное повреждение ${failure.diameterHole} мм`
        } else if(failure.failure === 'tankFire') {
          return `Пожар резервуара ${failure.diameterTank} м`
        } else {
          return `Полное разрушение`
        }
      }

      function calculatePoolFire(graphType, activeAccident, saveMode, currentObject) {
        const AIR_DENSITY = 1.29 // плотность воздуха при нормальных условиях, кг/м3
        const GRAVITY = 9.807 // ускорение свободного падения, м/с2
        const MOLAR_VOLUME = 22.414 // молярный объем пара при нормальных условиях, м3/кмоль
        const HUMAN_TIME = 5 // характерное время, за которое человек обнаруживает пожар и принимает решение о своих дальнейших действиях, с
        const HUMAN_VELOCITY = 5 // средняя скорость человека к безопасной зоне, м/c

        let nameOfSubstancePoolFire = currentObject.substance.nameOfSubstancePoolFire // наименование вещества в оборудовании (для расчета пожара пролива): СПГ(LNG), СУГ(LPG), бензин(gasoline), дизельное топливо(diesel), нефть иили нефтепродукт(oilOrOilProduct), однокомпонентная жидкость(oneComponentLiquid)

        // средние скорости ветра по направлениям в июле в регионе расположения предприятия, м/c
        let northWindVelocity = project.region.northWindVelocity // северный
        let northEastWindVelocity = project.region.northEastWindVelocity // северо-восточный
        let eastWindVelocity = project.region.eastWindVelocity // восточный
        let southEastWindVelocity = project.region.southEastWindVelocity // юго-восточный
        let southWindVelocity = project.region.southWindVelocity // южный
        let southWestWindVelocity = project.region.southWestWindVelocity // юго-западный
        let westWindVelocity = project.region.westWindVelocity // западный
        let northWestWindVelocity = project.region.northWestWindVelocity // северо-западный

        let molarMass = currentObject.substance.molarMass // молярная масса вещества, кг/кмоль
        let massBurningRate = currentObject.substance.massBurningRate // удельная массовая скорость выгорания, кг/(м2*с)
        let boilingTemperature = currentObject.substance.boilingTemperature // температура кипения жидкости (сжиженного газа) при атмосферном давлении, K

        let actualSpillArea = currentObject.failureParameterObj[activeAccident].failureParameter.actualSpillArea; // фактическая площадь пролива, м2

        let probitFunctionTable = [-5.0,-4.5,-4.0,-3.5,-3.0,-2.5,-2.0,-1.5,-1.0,-0.5,0.0,0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,11.0,11.5,12.0,12.5,13.0,13.5,14.0,14.5,15.0];
        let probabilityOfDamageTable = [0,0,0,0,0,3.191e-14,1.280e-12,4.016e-11,9.866e-10,1.899e-8,2.867e-7,3.398e-6,3.167e-5,2.326e-4,1.350e-3,6.210e-3,0.023,0.067,0.159,0.309,0.500,0.691,0.841,0.933,0.977,0.994,0.999,1,1,1,1,1,1,1,1,1,1,1,1,1,1,]

        let distanceFromEquipment = []; // расстояние от оборудования, м
        for (let i = 0; i < 10000; i++) {
          distanceFromEquipment[i] = i;
        }

        let maxOfAverageWindVelocity; // максимальная из средних скоростей ветра по направлениям в июле в регионе расположения предприятия, м/c
        let characteristicSpillDiameter; // характерный диаметр фактической площади пролива, м2
        let vaporDensityAtBoilingPoint; //плотность паров топлива при температуре кипения, кг/м3
        let radiationDensityPoolFire; // среднеповерхностная плотность теплового излучения пламени, кВт/м2
        let parameterUz; // параметр u со звездочкой
        let flameLength; // длина пламени, м
        let flameAngle; // угол отклонения пламени под действием ветра, рад.
        let pa; // параметр a
        let pb; // параметр b
        let pA; // параметр A
        let pB; // параметр B
        let pC; // параметр C
        let pD; // параметр D
        let pE; // параметр E
        let pF; // параметр F
        let pFv; // фактор облученности для вертикальной площадки
        let pFh; // фактор облученности для горизонтальной площадки
        let radiatinCoefficient; // угловой коэффициент облученности
        let atmosphericTransmittance; // коэффициент пропускания атмосферы
        let radiationIntencity; // интенсивность теплового излучения пожара пролива, кВт/м2
        let safetyDistance; // безопасное расстояние, на котором интенсивность теплового излучения равна 4 кВт/м2
        let effectiveExposureTime = []; // эффективное время экспозиции, с
        let radiationDose = []; // доза облучения
        let probitFunction = []; // пробит функция
        let indexProbability = []; //индекс массива, рядом с которым находится значение условной вероятности поражения
        let probabilityOfDamage = []; //условная вероятность поражения человека тепловым излучением пожара пролива

        characteristicSpillDiameter = Math.sqrt(4 * actualSpillArea / Math.PI);
        maxOfAverageWindVelocity = Math.max(northWindVelocity, northEastWindVelocity, eastWindVelocity, southEastWindVelocity, southWindVelocity, southWestWindVelocity, westWindVelocity, northWestWindVelocity);
        vaporDensityAtBoilingPoint = molarMass / (MOLAR_VOLUME * (boilingTemperature / 273.15));
        parameterUz = maxOfAverageWindVelocity / Math.pow(massBurningRate * GRAVITY * characteristicSpillDiameter / vaporDensityAtBoilingPoint, 1 / 3);

        if (parameterUz >= 1) {
          flameLength = 55 * characteristicSpillDiameter * Math.pow(massBurningRate / (AIR_DENSITY * Math.pow(GRAVITY * characteristicSpillDiameter, 1 / 2)), 0.67) * Math.pow(parameterUz, 0.21);
        } else {
          flameLength = 42 * characteristicSpillDiameter * Math.pow(massBurningRate / (AIR_DENSITY * Math.pow(GRAVITY * characteristicSpillDiameter, 1 / 2)), 0.61);
        }
        if (parameterUz < 1) {
          flameAngle = Math.acos(1);
        } else {
          flameAngle = Math.acos(Math.pow(parameterUz, -0.5));
        }

        switch (nameOfSubstancePoolFire) {
          case "LNG":
            if (characteristicSpillDiameter < 10) {
              radiationDensityPoolFire = 220;
            } else if (characteristicSpillDiameter > 50) {
              radiationDensityPoolFire = 120;
            } else {
              radiationDensityPoolFire = 0.05 * Math.pow(characteristicSpillDiameter, 2) - 5.5 * characteristicSpillDiameter + 270;
            }
            break;
          case "LPG":
            if (characteristicSpillDiameter < 10) {
              radiationDensityPoolFire = 80;
            } else if (characteristicSpillDiameter > 50) {
              radiationDensityPoolFire = 40;
            } else {
              radiationDensityPoolFire = 0.0243 * Math.pow(characteristicSpillDiameter, 2) - 2.4571 * characteristicSpillDiameter + 102.2;
            }
            break;
          case "gasoline":
            if (characteristicSpillDiameter < 10) {
              radiationDensityPoolFire = 60;
            } else if (characteristicSpillDiameter > 50) {
              radiationDensityPoolFire = 25;
            } else {
              radiationDensityPoolFire = 0.0179 * Math.pow(characteristicSpillDiameter, 2) - 1.9614 * characteristicSpillDiameter + 78.2;
            }
            break;
          case "diesel":
            if (characteristicSpillDiameter < 10) {
              radiationDensityPoolFire = 40;
            } else if (characteristicSpillDiameter > 50) {
              radiationDensityPoolFire = 18;
            } else {
              radiationDensityPoolFire = 0.0093 * Math.pow(characteristicSpillDiameter, 2) - 1.1071 * characteristicSpillDiameter + 50.2;
            }
            break;
          case "oilOrOilProduct":
            radiationDensityPoolFire = 140 * Math.exp(-0.12 * characteristicSpillDiameter) + 20 * (1 - Math.exp(-0.12 * characteristicSpillDiameter));
            break;
          case "oneComponentLiquid":
            radiationDensityPoolFire = 0.4 * project.substance.massBurningRate * project.substance.specificHeatOfCombustion / (1 + 4 * flameLength / characteristicSpillDiameter);
            break;
        }
        pa = 2 * flameLength / characteristicSpillDiameter;
        safetyDistance = Math.trunc(characteristicSpillDiameter / 2) + 1;
        do {
          pb = 2 * safetyDistance / characteristicSpillDiameter;
          pA = Math.sqrt(Math.pow(pa, 2) + Math.pow(pb + 1, 2) - 2 * pa * (pb + 1) * Math.sin(flameAngle));
          pB = Math.sqrt(Math.pow(pa, 2) + Math.pow(pb - 1, 2) - 2 * pa * (pb - 1) * Math.sin(flameAngle));
          pC = Math.sqrt(1 + (Math.pow(pb, 2) - 1) * Math.pow(Math.cos(flameAngle), 2));
          pD = Math.sqrt((pb - 1) / (pb + 1));
          pE = pa * Math.cos(flameAngle) / (pb - pa * Math.sin(flameAngle));
          pF = Math.sqrt(Math.pow(pb, 2) - 1);
          pFv = (1 / Math.PI) * (-pE * Math.atan(pD) + pE * ((Math.pow(pa, 2) + Math.pow(pb + 1, 2) - 2 * pb * (1 + pa * Math.sin(flameAngle))) / (pA * pB)) * Math.atan(pA * pD / pB) + (Math.cos(flameAngle) / pC) * (Math.atan((pa * pb - Math.pow(pF, 2) * Math.sin(flameAngle)) / (pF * pC)) + Math.atan(Math.pow(pF, 2) * Math.sin(flameAngle) / (pF * pC))));
          pFh = (1 / Math.PI) * (Math.atan(1 / pD) + (Math.sin(flameAngle) / pC) * (Math.atan((pa * pb - Math.pow(pF, 2) * Math.sin(flameAngle)) / (pF * pC)) + Math.atan((Math.pow(pF, 2) * Math.sin(flameAngle)) / (pF * pC))) - ((Math.pow(pa, 2) + Math.pow(pb + 1, 2) - 2 * (pb + 1 + pa * pb * Math.sin(flameAngle))) / (pA * pB)) * Math.atan(pA * pD / pB));
          radiatinCoefficient = Math.sqrt(Math.pow(pFv, 2) + Math.pow(pFh, 2));
          atmosphericTransmittance = Math.exp(-7 * (safetyDistance - 0.5 * characteristicSpillDiameter) / 10000);
          radiationIntencity = radiationDensityPoolFire * radiatinCoefficient * atmosphericTransmittance;
          safetyDistance++;
        } while (radiationIntencity >= 4);

        safetyDistance = safetyDistance - 1;

        pb = [];
        pA = [];
        pB = [];
        pC = [];
        pD = [];
        pE = [];
        pF = [];
        pFv = [];
        pFh = [];
        radiatinCoefficient = [];
        atmosphericTransmittance = [];
        radiationIntencity = [];
        effectiveExposureTime = [];
        radiationDose = [];
        probitFunction = [];
        indexProbability = [];
        probabilityOfDamage = [];

        for (let i = 0; i < distanceFromEquipment.length; i++) {

          if (distanceFromEquipment[i] <= Math.trunc(characteristicSpillDiameter / 2 + 1)) {
            radiationIntencity[i] = radiationDensityPoolFire;

          } else {
            pb[i] = 2 * distanceFromEquipment[i] / characteristicSpillDiameter;
            pA[i] = Math.sqrt(Math.pow(pa, 2) + Math.pow(pb[i] + 1, 2) - 2 * pa * (pb[i] + 1) * Math.sin(flameAngle));
            pB[i] = Math.sqrt(Math.pow(pa, 2) + Math.pow(pb[i] - 1, 2) - 2 * pa * (pb[i] - 1) * Math.sin(flameAngle));
            pC[i] = Math.sqrt(1 + (Math.pow(pb[i], 2) - 1) * Math.pow(Math.cos(flameAngle), 2));
            pD[i] = Math.sqrt((pb[i] - 1) / (pb[i] + 1));
            pE[i] = pa * Math.cos(flameAngle) / (pb[i] - pa * Math.sin(flameAngle));
            pF[i] = Math.sqrt(Math.pow(pb[i], 2) - 1);

            pFv[i] = (1 / Math.PI) * (-pE[i] * Math.atan(pD[i]) + pE[i] * ((Math.pow(pa, 2) + Math.pow(pb[i] + 1, 2) - 2 * pb[i] * (1 + pa * Math.sin(flameAngle))) / (pA[i] * pB[i])) * Math.atan(pA[i] * pD[i] / pB[i]) + (Math.cos(flameAngle) / pC[i]) * (Math.atan((pa * pb[i] - Math.pow(pF[i], 2) * Math.sin(flameAngle)) / (pF[i] * pC[i])) + Math.atan(Math.pow(pF[i], 2) * Math.sin(flameAngle) / (pF[i] * pC[i]))));

            pFh[i] = (1 / Math.PI) * (Math.atan(1 / pD[i]) + (Math.sin(flameAngle) / pC[i]) * (Math.atan((pa * pb[i] - Math.pow(pF[i], 2) * Math.sin(flameAngle)) / (pF[i] * pC[i])) + Math.atan((Math.pow(pF[i], 2) * Math.sin(flameAngle)) / (pF[i] * pC[i]))) - ((Math.pow(pa, 2) + Math.pow(pb[i] + 1, 2) - 2 * (pb[i] + 1 + pa * pb[i] * Math.sin(flameAngle))) / (pA[i] * pB[i])) * Math.atan(pA[i] * pD[i] / pB[i]));

            radiatinCoefficient[i] = Math.sqrt(Math.pow(pFv[i], 2) + Math.pow(pFh[i], 2));

            atmosphericTransmittance[i] = Math.exp(-7 * (distanceFromEquipment[i] - 0.5 * characteristicSpillDiameter) / 10000);

            radiationIntencity[i] = radiationDensityPoolFire * radiatinCoefficient[i] * atmosphericTransmittance[i];
          }

          if (distanceFromEquipment[i] > safetyDistance) {
            effectiveExposureTime[i] = 0;
            radiationDose[i] = 0;
            probitFunction[i] = 0;
            probabilityOfDamage[i] = 0
          } else if (distanceFromEquipment[i] <= characteristicSpillDiameter / 2) {
            effectiveExposureTime[i] = 0;
            radiationDose[i] = 0;
            probitFunction[i] = 0;
            probabilityOfDamage[i] = 1;
          } else {
            effectiveExposureTime[i] = HUMAN_TIME + (safetyDistance - distanceFromEquipment[i]) / HUMAN_VELOCITY;
            radiationDose[i] = effectiveExposureTime[i] * Math.pow(radiationIntencity[i], 4 / 3);
            probitFunction[i] = -12.8 + 2.56 * Math.log(radiationDose[i]);
            indexProbability[i] = Math.trunc((probitFunction[i] + 5) / 0.5);
            if (probitFunction[i] < -5) {
              probabilityOfDamage[i] = 0;
            } else if (indexProbability[i] <= 39) {
              probabilityOfDamage[i] = probabilityOfDamageTable[indexProbability[i]] + (probitFunction[i] - probitFunctionTable[indexProbability[i]]) * (probabilityOfDamageTable[indexProbability[i] + 1] - probabilityOfDamageTable[indexProbability[i]]) / 0.5;
            } else if (indexProbability[i] > 39) {
            probabilityOfDamage[i] = 1;
            }
          }
        }

        if(saveMode){
          return  {
            radiationIntencity: radiationIntencity,
            probabilityOfDamage: probabilityOfDamage,
          }
        }  

        let radiationIntencityGraph = [];
        let probabilityOfDamageGraph = [];
        
        
        for (let i = 0; i < 10000; i++) {
          radiationIntencityGraph[i] = radiationIntencity[i];
          probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
        }

        while (radiationIntencityGraph[radiationIntencityGraph.length - 1] < 1) {
          radiationIntencityGraph.pop();
        }

        while (probabilityOfDamageGraph[probabilityOfDamageGraph.length - 1] === 0) {
          probabilityOfDamageGraph.pop();
        }
        let distance1; //расстояние от оборудования, где интенсивность излучения равна 10.5 кВт/м2
        let distance2; //расстояние от оборудования, где интенсивность излучения равна 7.0 кВт/м2
        let distance3; //расстояние от оборудования, где интенсивность излучения равна 4.2 кВт/м2
        let distance4; //расстояние от оборудования, где интенсивность излучения равна 1.4 кВт/м2

        if(!saveMode){
          for (let i = 0; i < radiationIntencityGraph.length; i++) {
            if (radiationIntencityGraph[i] >= 10.5) {distance1 = i;}
            if (radiationIntencityGraph[i] >= 7.0) {distance2 = i;}
            if (radiationIntencityGraph[i] >= 4.2) {distance3 = i;}
            if (radiationIntencityGraph[i] >= 1.4) {distance4 = i;}
          }
        }
        
        let abscissaRad = [];
        for (let i = 0; i < radiationIntencityGraph.length; i++) {
          if(radiationIntencityGraph[i] > 0) {
            abscissaRad[i] = i;
          }
          
        }
      
        let abscissaProb = [];
        for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
          if(probabilityOfDamageGraph[i] > 0.01) {
            abscissaProb[i] = i;
          }
        }

        let data,options,probabilityData,probabilityOptions

        if(graphType === 'radiationIntencity') {
          data  = {
            labels: abscissaRad,
            datasets: [
              {
                label: 'Интенсивность теплового излучения пожара пролива',
                data: radiationIntencityGraph,
                backgroundColor: [],
                borderColor: [],
                pointRadius: [],
              }
            ],
          }
    
          options =  {
            animation: false,
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Расстояние от оборудования, м'
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: 'Интенсивность теплового излучения, кВт/м2'
                }
              },
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 0,
                  color: '#3278e1',
                  font: {
                    size: 16,
                  }
                }
              },
              datalabels: {
                display: function(context) {
                  return (context.dataIndex === distance1 || context.dataIndex === distance2 || context.dataIndex === distance3 || context.dataIndex === distance4)
                },
                formatter: function(value, context) {
                  if (context.dataIndex === distance1) {
                    return 1;
                  } else if (context.dataIndex === distance2) {
                    return 2;
                  } else if (context.dataIndex === distance3) {
                    return 3;
                  } else if (context.dataIndex === distance4) {
                    return 4;
                  }
                },
                color: 'white',
                borderRadius: 10,
                backgroundColor: '#ff4d00',
                align: '-45',
                padding: {
                  top: 5,
                  right: 5,
                  bottom: 5,
                  left: 5
                },
                offset: 10,
              }
            }
          }
    
          for (let i = 0; i < data.labels.length; i++) {
            if (data.labels[i] === distance1 || data.labels[i] === distance2 || data.labels[i] === distance3 || data.labels[i] === distance4) {
              data.datasets[0].pointRadius[i] = 5;
              data.datasets[0].backgroundColor[i] = '#ff4d00';
              data.datasets[0].borderColor[i] = '#ff4d00';
            } else {
              data.datasets[0].borderColor[i] = '#3278e1';
              data.datasets[0].pointRadius[i] = 0;
            }
          }
        } else {
          probabilityData  = {
            labels: abscissaProb,
            datasets: [{
              label: 'Условная вероятность поражения человека на открытом пространстве',
              data: probabilityOfDamageGraph,
              borderColor: '#3278e1',
              pointRadius: 0,
            }]
          }

          probabilityOptions = {
            animation: false,
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Расстояние от оборудования, м'
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: 'Условная вероятность поражения, %'
                }
              },
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 0,
                  color: '#3278e1',
                  font: {
                    size: 16,
                  }
                }
              },
              datalabels: {
                display: false
              }
            }
          }
        }

        if(chart) {
          chart.destroy()
        }

        if(graphType === 'radiationIntencity') {
          chart = new Chart(ctx, { type: 'line', data, options })
          if(radiationIntencityGraph.length) {
            return chart.toBase64Image()  
          } else {
            return false
          }
        } else {
          chart = new Chart(ctx, { type: 'line', data: probabilityData, options: probabilityOptions})
          if(probabilityOfDamageGraph.length) {
            return chart.toBase64Image()  
          } else {
            return false
          }
        }
      }

      function calculateBallFire(graphType, activeAccident, saveMode, currentObject) {
        const RADIATION_DENSITY_FIRE_BALL = 350;

        let probitFunctionTable = [-5.0,-4.5,-4.0,-3.5,-3.0,-2.5,-2.0,-1.5,-1.0,-0.5,0.0,0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,11.0,11.5,12.0,12.5,13.0,13.5,14.0,14.5,15.0];
        let probabilityOfDamageTable = [0,0,0,0,0,3.191e-14,1.280e-12,4.016e-11,9.866e-10,1.899e-8,2.867e-7,3.398e-6,3.167e-5,2.326e-4,1.350e-3,6.210e-3,0.023,0.067,0.159,0.309,0.500,0.691,0.841,0.933,0.977,0.994,0.999,1,1,1,1,1,1,1,1,1,1,1,1,1,1,]

        let distanceFromEquipment = []; // расстояние от оборудования, м
        for (let i = 0; i < 10000; i++) {
          distanceFromEquipment[i] = i;
        }

        let massSubstanceInEquipment = currentObject.failureParameterObj[activeAccident].failureParameter.massSubstanceInEquipment

        let effectiveDiameterFireBall = 6.48 * Math.pow(massSubstanceInEquipment, 0.325); // эффективный диаметр огненного шара, м
        let effectiveExposureTime = 0.92 * Math.pow(massSubstanceInEquipment, 0.303); // эффективное время экспозиции, с
        let centerHeightFireBall = effectiveDiameterFireBall; // эффективный диаметр огенного шара, м
        let radiatinCoefficient = []; // угловой коэффициент облученности
        let atmosphericTransmittance = []; // коэффициент пропускания атмосферы
        let radiationIntencity = []; // интенсивность теплового излучения огненного шара, кВт/м2
        let probitFunction = []; // пробит функция для поражения человека тепловым излучением огненного шара
        let indexProbability = []; //индекс массива, рядом с которым находится значение условной вероятности поражения
        let probabilityOfDamage = []; //условная вероятность поражения человека тепловым излучением огенного шара
        for (let i = 0; i < distanceFromEquipment.length; i++) {
          radiatinCoefficient[i] = Math.pow(effectiveDiameterFireBall, 2) / (4 * (Math.pow(centerHeightFireBall, 2) + Math.pow(distanceFromEquipment[i], 2)));
          atmosphericTransmittance[i] = Math.exp(-7 * (Math.sqrt(Math.pow(distanceFromEquipment[i], 2) + Math.pow(centerHeightFireBall, 2)) - effectiveDiameterFireBall / 2) / 10000);
          radiationIntencity[i] = RADIATION_DENSITY_FIRE_BALL * radiatinCoefficient[i] * atmosphericTransmittance[i];
          probitFunction[i] = -12.8 +2.56 * Math.log(effectiveExposureTime * Math.pow(radiationIntencity[i], 4 / 3));
          indexProbability[i] = Math.trunc((probitFunction[i] + 5) / 0.5);
          if (probitFunction[i] < -5) {
            probabilityOfDamage[i] = 0;
          } else if (indexProbability[i] <= 39) {
            probabilityOfDamage[i] = probabilityOfDamageTable[indexProbability[i]] + (probitFunction[i] - probitFunctionTable[indexProbability[i]]) * (probabilityOfDamageTable[indexProbability[i] + 1] - probabilityOfDamageTable[indexProbability[i]]) / 0.5;
          } else if(indexProbability[i] > 39) {
            probabilityOfDamage[i] = 1;
          }
        }

        if(saveMode){
          return {
            radiationIntencity: radiationIntencity,
            probabilityOfDamage: probabilityOfDamage,
          }
        }

        let radiationIntencityGraph = [];
        let probabilityOfDamageGraph = [];
        
        for (let i = 0; i < 10000; i++) {
          radiationIntencityGraph[i] = radiationIntencity[i];
          probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
        }

        while (radiationIntencityGraph[radiationIntencityGraph.length - 1] < 1) {
          radiationIntencityGraph.pop();
        }

        while (probabilityOfDamageGraph[probabilityOfDamageGraph.length - 1] === 0) {
          probabilityOfDamageGraph.pop();
        }
        
        let distance1; //расстояние от оборудования, где интенсивность излучения равна 10.5 кВт/м2
        let distance2; //расстояние от оборудования, где интенсивность излучения равна 7.0 кВт/м2
        let distance3; //расстояние от оборудования, где интенсивность излучения равна 4.2 кВт/м2
        let distance4; //расстояние от оборудования, где интенсивность излучения равна 1.4 кВт/м2

        if(!saveMode){
          for (let i = 0; i < radiationIntencityGraph.length; i++) {
            if (radiationIntencityGraph[i] >= 10.5) {distance1 = i;}
            if (radiationIntencityGraph[i] >= 7.0) {distance2 = i;}
            if (radiationIntencityGraph[i] >= 4.2) {distance3 = i;}
            if (radiationIntencityGraph[i] >= 1.4) {distance4 = i;}
          }
        }

        let abscissaRad = [];
        for (let i = 0; i < radiationIntencityGraph.length; i++) {
          if(radiationIntencityGraph[i] > 0) {
            abscissaRad[i] = i;
          }
          
        }
      
        let abscissaProb = [];
        for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
          if(probabilityOfDamageGraph[i] > 0.01) {
            abscissaProb[i] = i;
          }
        }

        let data,options,probabilityData,probabilityOptions

        if(graphType === 'radiationIntencity') {
          data  = {
            labels: abscissaRad,
            datasets: [
              {
                label: 'Интенсивность теплового излучения огненного шара',
                data: radiationIntencityGraph,
                backgroundColor: [],
                borderColor: [],
                pointRadius: [],
              }
            ],
          }
    
          options =  {
            animation: false,
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Расстояние от оборудования, м'
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: 'Интенсивность теплового излучения, кВт/м2'
                }
              },
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 0,
                  color: '#3278e1',
                  font: {
                    size: 16,
                  }
                }
              },
              datalabels: {
                display: function(context) {
                  return (context.dataIndex === distance1 || context.dataIndex === distance2 || context.dataIndex === distance3 || context.dataIndex === distance4)
                },
                formatter: function(value, context) {
                  if (context.dataIndex === distance1) {
                    return 1;
                  } else if (context.dataIndex === distance2) {
                    return 2;
                  } else if (context.dataIndex === distance3) {
                    return 3;
                  } else if (context.dataIndex === distance4) {
                    return 4;
                  }
                },
                color: 'white',
                borderRadius: 10,
                backgroundColor: '#ff4d00',
                align: '-45',
                padding: {
                  top: 5,
                  right: 5,
                  bottom: 5,
                  left: 5
                },
                offset: 10,
              }
            }
          }
    
          for (let i = 0; i < data.labels.length; i++) {
            if (data.labels[i] === distance1 || data.labels[i] === distance2 || data.labels[i] === distance3 || data.labels[i] === distance4) {
              data.datasets[0].pointRadius[i] = 5;
              data.datasets[0].backgroundColor[i] = '#ff4d00';
              data.datasets[0].borderColor[i] = '#ff4d00';
            } else {
              data.datasets[0].borderColor[i] = '#3278e1';
              data.datasets[0].pointRadius[i] = 0;
            }
          }
        } else {
          probabilityData  = {
            labels: abscissaProb,
            datasets: [{
              label: 'Условная вероятность поражения человека на открытом пространстве',
              data: probabilityOfDamageGraph,
              borderColor: '#3278e1',
              pointRadius: 0,
            }]
          }

          probabilityOptions = {
            animation: false,
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Расстояние от оборудования, м'
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: 'Условная вероятность поражения, %'
                }
              },
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 0,
                  color: '#3278e1',
                  font: {
                    size: 16,
                  }
                }
              },
              datalabels: {
                display: false
              }
            }
          } 
        }

        if(chart) {
          chart.destroy()
        }

        if(graphType === 'radiationIntencity') {
          chart = new Chart(ctx, { type: 'line', data, options })
          if(radiationIntencityGraph.length) {
            return chart.toBase64Image()  
          } else {
            return false
          }
        } else {
          chart = new Chart(ctx, { type: 'line', data: probabilityData, options: probabilityOptions})
          if(probabilityOfDamageGraph.length) {
            return chart.toBase64Image()  
          } else {
            return false
          }
        }
      }

      function calculateFlashFire(activeAccident, saveMode, currentObject) {
        const MOLAR_VOLUME = 22.414;

        let absoluteMaxTemperature = project.region.absoluteMaxTemperature; // абсолютная максимальная температура воздуха в городе расположения предприятия, К
        let averageMaxTemperature = project.region.averageMaxTemperature; // cредняя максимальная температура воздуха наиболее теплого месяца в городе расположения предприятия, К

        let equipmentLocation = currentObject.objDetailData.baseData.equipmentLocation; // расположение оборудования: наземное или подземное

        let molarMass = currentObject.substance.molarMass; // молярная масса вещества, кг/кмоль

        let bottomConcentrationLimit = currentObject.substance.bottomConcentrationLimit; // нижний концентрационный предел распространения пламени, %(об.)

        let massSubstanceInCloud = currentObject.failureParameterObj[activeAccident].failureParameter.massSubstanceInCloud // масса газа или пара, поступившего в открытое пространство, кг

        let distanceFromEquipment = []; // расстояние от оборудования, м
        for (let i = 0; i < 10000; i++) {
          distanceFromEquipment[i] = i;
        }

        let estimatedTemperatureOfLiquid; // расчетная температура жидкости, К
        let gasDensityEstimatedTemperature; // плотность газа или пара при расчетной температуре и атмосферном давлении кг/м3
        let zoneRadiusBottomConcentrationLimit; // радиус зоны, ограничивающей область концентраций, превышающих нижний концентрационный предел распространения пламени, м
        let radiusFlashFire; // радиус воздействия высокотемпературных продуктов сгорания газопаровоздушного облака, м
        let probabilityOfDamage = []; //условная вероятность поражения человека высокотемпературными продуктами сгорания

        if (equipmentLocation == "ground") {
          estimatedTemperatureOfLiquid = absoluteMaxTemperature;
        } else if (equipmentLocation == "underground") {
          estimatedTemperatureOfLiquid = averageMaxTemperature;
        }
        gasDensityEstimatedTemperature = molarMass / (MOLAR_VOLUME * (estimatedTemperatureOfLiquid / 273.15));
        zoneRadiusBottomConcentrationLimit = 7.8 * Math.pow(massSubstanceInCloud / (gasDensityEstimatedTemperature * bottomConcentrationLimit), 0.33);
        radiusFlashFire = 1.2 * zoneRadiusBottomConcentrationLimit;
        for (let i = 0; i < distanceFromEquipment.length; i++) {
          if (distanceFromEquipment[i] <= radiusFlashFire) {
            probabilityOfDamage[i] = 1;
          } else {
            probabilityOfDamage[i] = 0;
          }
        }

        if(saveMode){
          return {
            probabilityOfDamage: probabilityOfDamage,
            radiusFlashFire: radiusFlashFire
          }
        }

        let probabilityOfDamageGraph = [];
		
        for (let i = 0; i < Math.ceil(2 * radiusFlashFire); i++) {
          probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
        }

        let abscissaProb = [];
        for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
          abscissaProb[i] = i;
        }

        let data,options

        data  = {
          labels: abscissaProb,
          datasets: [{
            label: 'Условная вероятность поражения человека на открытом пространстве',
            data: probabilityOfDamageGraph,
            borderColor: '#3278e1',
            pointRadius: 0,
          }]
        }

        options = {
          animation: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Расстояние от оборудования, м'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Условная вероятность поражения, %'
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
                color: '#3278e1',
                font: {
                  size: 16,
                }
              }
            },
            datalabels: {
              display: false
            }
          }
        }

        if(chart) {
          chart.destroy()
        }

        chart = new Chart(ctx, { type: 'line', data, options })
        if(probabilityOfDamageGraph.length) {
          return chart.toBase64Image()  
        } else {
          return false
        }
      }

      function calculateHorisontalJetFire(activeAccident, saveMode, currentObject) {
        const RADIATION_INTENSITY_FIRE_JET = 10; // интенсивность теплового излучения горизонтального факела в интервале расстояний от длины факела до 1,5 длины факела, кВт/м2
        const SECTOR_COEFFICIENT = 0.083; // коэффициент, учитывающий, что поражение человека в горизонтальном факеле происходит в 30-градусном секторе с радиусом, равным длине факела
        const HUMAN_TIME = 5; // характерное время, за которое человек обнаруживает пожар и принимает решение о своих дальнейших действиях, с
        const HUMAN_VELOCITY = 5; // средняя скорость человека к безопасной зоне, м/c

        let classOfSubstanceJetFire = currentObject.substance.classOfSubstanceJetFire; // класс вещества в оборудовании (для расчета факела): сжатый газ (compressedGas), паровая фаза сжиженного газа (vaporPhaseLiquefiedGas), жидкая фаза сжиженного газа или жидкость (liquidPhaseLiquefiedGasOrLiquid)
        let massSubstanceRate = currentObject.failureParameterObj[activeAccident].failureParameter.massSubstanceRate; // расход вещества через отверстие, кг/c

        let probitFunctionTable = [-5.0,-4.5,-4.0,-3.5,-3.0,-2.5,-2.0,-1.5,-1.0,-0.5,0.0,0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,11.0,11.5,12.0,12.5,13.0,13.5,14.0,14.5,15.0];
        let probabilityOfDamageTable = [0,0,0,0,0,3.191e-14,1.280e-12,4.016e-11,9.866e-10,1.899e-8,2.867e-7,3.398e-6,3.167e-5,2.326e-4,1.350e-3,6.210e-3,0.023,0.067,0.159,0.309,0.500,0.691,0.841,0.933,0.977,0.994,0.999,1,1,1,1,1,1,1,1,1,1,1,1,1,1,]

        let distanceFromEquipment = []; // расстояние от оборудования, м
        for (let i = 0; i < 10000; i++) {
          distanceFromEquipment[i] = i;
        }

        let empiricalCoefficient; // значение эмпирического коэффициента
        let jetFireLength; // длина факела при струйном горении, м
        //let jetFireWidth; // ширина факела при струйном горении, м
        let effectiveExposureTime = []; // эффективное время экспозиции, с
        let probitFunction = []; // пробит функция
        let indexProbability = []; //индекс массива, рядом с которым находится значение условной вероятности поражения
        let probabilityOfDamage = []; // условная вероятность поражения человека тепловым излучением горизонтального факела

        if (classOfSubstanceJetFire === "compressedGas") {empiricalCoefficient = 12.5}
        if (classOfSubstanceJetFire === "vaporPhaseLiquefiedGas") {empiricalCoefficient = 13.5}
        if (classOfSubstanceJetFire === "liquidPhaseLiquefiedGasOrLiquid") {empiricalCoefficient = 15}
        jetFireLength = empiricalCoefficient * Math.pow(massSubstanceRate, 0.4);
        for (let i = 0; i < distanceFromEquipment.length; i++) {
          if (distanceFromEquipment[i] <= jetFireLength) {
            probabilityOfDamage[i] = SECTOR_COEFFICIENT;
          } else if (distanceFromEquipment[i] > jetFireLength && distanceFromEquipment[i] <= 1.5 * jetFireLength) {
            effectiveExposureTime[i] = HUMAN_TIME + (1.5 * jetFireLength - distanceFromEquipment[i]) / HUMAN_VELOCITY;
            probitFunction[i] = -12.8 + 2.56 * Math.log(effectiveExposureTime[i] * Math.pow(RADIATION_INTENSITY_FIRE_JET, 4 / 3));
            indexProbability[i] = Math.trunc((probitFunction[i] + 5) / 0.5);
            if (probitFunction[i] < -5) {
              probabilityOfDamage[i] = 0;
            } else if (indexProbability[i] <= 39) {
              probabilityOfDamage[i] = SECTOR_COEFFICIENT * (probabilityOfDamageTable[indexProbability[i]] + (probitFunction[i] - probitFunctionTable[indexProbability[i]]) * (probabilityOfDamageTable[indexProbability[i] + 1] - probabilityOfDamageTable[indexProbability[i]]) / 0.5);
            } else if(indexProbability[i] > 39) {
              probabilityOfDamage[i] = SECTOR_COEFFICIENT;
            }
          } else {
            probabilityOfDamage[i] = 0;
          }
        }

        if(saveMode){
          return {
            probabilityOfDamage: probabilityOfDamage,
            jetFireLength: jetFireLength
          }
        }

        let probabilityOfDamageGraph = [];
		
        for (let i = 0; i < 10000; i++) {
          probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
        }
      
        while (probabilityOfDamageGraph[probabilityOfDamageGraph.length - 1] === 0) {
          probabilityOfDamageGraph.pop();
        }

        let abscissaProb = [];
        for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
          abscissaProb[i] = i;
        }    

        let data,options
        
        data  = {
          labels: abscissaProb,
          datasets: [{
            label: 'Условная вероятность поражения человека на открытом пространстве',
            data: probabilityOfDamageGraph,
            borderColor: '#3278e1',
            pointRadius: 0,
          }]
        }

        options = {
          animation: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Расстояние от оборудования, м'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Условная вероятность поражения, %'
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
                color: '#3278e1',
                font: {
                  size: 16,
                }
              }
            },
            datalabels: {
              display: false
            }
          }
        }

        if(chart) {
          chart.destroy()
        }

        chart = new Chart(ctx, { type: 'line', data, options })
        if(probabilityOfDamageGraph.length) {
          return chart.toBase64Image()  
        } else {
          return false
        }
      }

      function calculateVerticalJetFire(graphType, activeAccident, saveMode, currentObject) {
        const RADIATION_DENSITY_FIRE_JET = 200; // среднеповерхностная плотность теплового излучения вертикального факела, кВт/м2
        const HUMAN_TIME = 5; // характерное время, за которое человек обнаруживает пожар и принимает решение о своих дальнейших действиях, с
        const HUMAN_VELOCITY = 5; // средняя скорость человека к безопасной зоне, м/c

        let classOfSubstanceJetFire = currentObject.substance.classOfSubstanceJetFire; // класс вещества в оборудовании (для расчета факела): сжатый газ (compressedGas), паровая фаза сжиженного газа (vaporPhaseLiquefiedGas), жидкая фаза сжиженного газа или жидкость (liquidPhaseLiquefiedGasOrLiquid)
        let massSubstanceRate = currentObject.failureParameterObj[activeAccident].failureParameter.massSubstanceRate; // расход вещества через отверстие, кг/c

        let probitFunctionTable = [-5.0,-4.5,-4.0,-3.5,-3.0,-2.5,-2.0,-1.5,-1.0,-0.5,0.0,0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,11.0,11.5,12.0,12.5,13.0,13.5,14.0,14.5,15.0];
        let probabilityOfDamageTable = [0,0,0,0,0,3.191e-14,1.280e-12,4.016e-11,9.866e-10,1.899e-8,2.867e-7,3.398e-6,3.167e-5,2.326e-4,1.350e-3,6.210e-3,0.023,0.067,0.159,0.309,0.500,0.691,0.841,0.933,0.977,0.994,0.999,1,1,1,1,1,1,1,1,1,1,1,1,1,1,]

        /*  */

        let distanceFromEquipment = []; // расстояние от оборудования, м
        for (let i = 0; i < 10000; i++) {
          distanceFromEquipment[i] = i;
        }

        let empiricalCoefficient; // значение эмпирического коэффициента
        let jetFireLength; // длина факела при струйном горении
        let jetFireWidth; // ширина факела при струйном горении
        let characteristicSpillDiameter; // характерный диаметр вертикального факела, м2
        let radiationDensityPoolFire; // среднеповерхностная плотность теплового излучения пламени, кВт/м2
        let flameLength; // длина пламени, м
        let flameAngle; // угол отклонения пламени под действием ветра, рад.
        let pa; // параметр a
        let pb; // параметр b
        let pA; // параметр A
        let pB; // параметр B
        let pC; // параметр C
        let pD; // параметр D
        let pE; // параметр E
        let pF; // параметр F
        let pFv; // фактор облученности для вертикальной площадки
        let pFh; // фактор облученности для горизонтальной площадки
        let radiatinCoefficient; // угловой коэффициент облученности
        let atmosphericTransmittance; // коэффициент пропускания атмосферы
        let radiationIntencity; // интенсивность теплового излучения пожара пролива, кВт/м2
        let safetyDistance; // безопасное расстояние, на котором интенсивность теплового излучения равна 4 кВт/м2
        let effectiveExposureTime = []; // эффективное время экспозиции, с
        let radiationDose = []; // доза облучения
        let probitFunction = []; // пробит функция
        let indexProbability = []; //индекс массива, рядом с которым находится значение условной вероятности поражения
        let probabilityOfDamage = []; //условная вероятность поражения человека тепловым излучением пожара пролива

        if (classOfSubstanceJetFire === "compressedGas") {empiricalCoefficient = 12.5}
        if (classOfSubstanceJetFire === "vaporPhaseLiquefiedGas") {empiricalCoefficient = 13.5}
        if (classOfSubstanceJetFire === "liquidPhaseLiquefiedGasOrLiquid") {empiricalCoefficient = 15}
        jetFireLength = empiricalCoefficient * Math.pow(massSubstanceRate, 0.4);
        jetFireWidth = 0.15 * jetFireLength;
        characteristicSpillDiameter = jetFireWidth;
        flameLength = jetFireLength;
        flameAngle = 0;
        radiationDensityPoolFire = RADIATION_DENSITY_FIRE_JET;

        pa = 2 * flameLength / characteristicSpillDiameter;
        safetyDistance = Math.trunc(characteristicSpillDiameter / 2) + 1;
        do {
          pb = 2 * safetyDistance / characteristicSpillDiameter;
          pA = Math.sqrt(Math.pow(pa, 2) + Math.pow(pb + 1, 2) - 2 * pa * (pb + 1) * Math.sin(flameAngle));
          pB = Math.sqrt(Math.pow(pa, 2) + Math.pow(pb - 1, 2) - 2 * pa * (pb - 1) * Math.sin(flameAngle));
          pC = Math.sqrt(1 + (Math.pow(pb, 2) - 1) * Math.pow(Math.cos(flameAngle), 2));
          pD = Math.sqrt((pb - 1) / (pb + 1));
          pE = pa * Math.cos(flameAngle) / (pb - pa * Math.sin(flameAngle));
          pF = Math.sqrt(Math.pow(pb, 2) - 1);
          pFv = (1 / Math.PI) * (-pE * Math.atan(pD) + pE * ((Math.pow(pa, 2) + Math.pow(pb + 1, 2) - 2 * pb * (1 + pa * Math.sin(flameAngle))) / (pA * pB)) * Math.atan(pA * pD / pB) + (Math.cos(flameAngle) / pC) * (Math.atan((pa * pb - Math.pow(pF, 2) * Math.sin(flameAngle)) / (pF * pC)) + Math.atan(Math.pow(pF, 2) * Math.sin(flameAngle) / (pF * pC))));
          pFh = (1 / Math.PI) * (Math.atan(1 / pD) + (Math.sin(flameAngle) / pC) * (Math.atan((pa * pb - Math.pow(pF, 2) * Math.sin(flameAngle)) / (pF * pC)) + Math.atan((Math.pow(pF, 2) * Math.sin(flameAngle)) / (pF * pC))) - ((Math.pow(pa, 2) + Math.pow(pb + 1, 2) - 2 * (pb + 1 + pa * pb * Math.sin(flameAngle))) / (pA * pB)) * Math.atan(pA * pD / pB));
          radiatinCoefficient = Math.sqrt(Math.pow(pFv, 2) + Math.pow(pFh, 2));
          atmosphericTransmittance = Math.exp(-7 * (safetyDistance - 0.5 * characteristicSpillDiameter) / 10000);
          radiationIntencity = radiationDensityPoolFire * radiatinCoefficient * atmosphericTransmittance;
          safetyDistance++;
        } while (radiationIntencity >= 4);

        safetyDistance = safetyDistance - 1;

          pb = [];
          pA = [];
          pB = [];
          pC = [];
          pD = [];
          pE = [];
          pF = [];
          pFv = [];
          pFh = [];
          radiatinCoefficient = [];
          atmosphericTransmittance = [];
          radiationIntencity = [];
          effectiveExposureTime = [];
          radiationDose = [];
          probitFunction = [];
          indexProbability = [];
          probabilityOfDamage = [];

        for (let i = 0; i < distanceFromEquipment.length; i++) {

          if (distanceFromEquipment[i] <= Math.trunc(characteristicSpillDiameter / 2 + 1)) {
            radiationIntencity[i] = radiationDensityPoolFire;
          } else {

            pb[i] = 2 * distanceFromEquipment[i] / characteristicSpillDiameter;
            pA[i] = Math.sqrt(Math.pow(pa, 2) + Math.pow(pb[i] + 1, 2) - 2 * pa * (pb[i] + 1) * Math.sin(flameAngle));
            pB[i] = Math.sqrt(Math.pow(pa, 2) + Math.pow(pb[i] - 1, 2) - 2 * pa * (pb[i] - 1) * Math.sin(flameAngle));
            pC[i] = Math.sqrt(1 + (Math.pow(pb[i], 2) - 1) * Math.pow(Math.cos(flameAngle), 2));
            pD[i] = Math.sqrt((pb[i] - 1) / (pb[i] + 1));
            pE[i] = pa * Math.cos(flameAngle) / (pb[i] - pa * Math.sin(flameAngle));
            pF[i] = Math.sqrt(Math.pow(pb[i], 2) - 1);

            pFv[i] = (1 / Math.PI) * (-pE[i] * Math.atan(pD[i]) + pE[i] * ((Math.pow(pa, 2) + Math.pow(pb[i] + 1, 2) - 2 * pb[i] * (1 + pa * Math.sin(flameAngle))) / (pA[i] * pB[i])) * Math.atan(pA[i] * pD[i] / pB[i]) + (Math.cos(flameAngle) / pC[i]) * (Math.atan((pa * pb[i] - Math.pow(pF[i], 2) * Math.sin(flameAngle)) / (pF[i] * pC[i])) + Math.atan(Math.pow(pF[i], 2) * Math.sin(flameAngle) / (pF[i] * pC[i]))));

            pFh[i] = (1 / Math.PI) * (Math.atan(1 / pD[i]) + (Math.sin(flameAngle) / pC[i]) * (Math.atan((pa * pb[i] - Math.pow(pF[i], 2) * Math.sin(flameAngle)) / (pF[i] * pC[i])) + Math.atan((Math.pow(pF[i], 2) * Math.sin(flameAngle)) / (pF[i] * pC[i]))) - ((Math.pow(pa, 2) + Math.pow(pb[i] + 1, 2) - 2 * (pb[i] + 1 + pa * pb[i] * Math.sin(flameAngle))) / (pA[i] * pB[i])) * Math.atan(pA[i] * pD[i] / pB[i]));

            radiatinCoefficient[i] = Math.sqrt(Math.pow(pFv[i], 2) + Math.pow(pFh[i], 2));

            atmosphericTransmittance[i] = Math.exp(-7 * (distanceFromEquipment[i] - 0.5 * characteristicSpillDiameter) / 10000);

            radiationIntencity[i] = radiationDensityPoolFire * radiatinCoefficient[i] * atmosphericTransmittance[i];
          }

          if (distanceFromEquipment[i] > safetyDistance) {
            effectiveExposureTime[i] = 0;
            radiationDose[i] = 0;
            probitFunction[i] = 0;
            probabilityOfDamage[i] = 0;
          } else if (distanceFromEquipment[i] <= characteristicSpillDiameter / 2) {
            effectiveExposureTime[i] = 0;
            radiationDose[i] = 0;
            probitFunction[i] = 0;
            probabilityOfDamage[i] = 1;
          } else {
            effectiveExposureTime[i] = HUMAN_TIME + (safetyDistance - distanceFromEquipment[i]) / HUMAN_VELOCITY;
            radiationDose[i] = effectiveExposureTime[i] * Math.pow(radiationIntencity[i], 4 / 3);
            probitFunction[i] = -12.8 + 2.56 * Math.log(radiationDose[i]);
            indexProbability[i] = Math.trunc((probitFunction[i] + 5) / 0.5);
            if (probitFunction[i] < -5) {
              probabilityOfDamage[i] = 0;
            } else if (indexProbability[i] <= 39) {
              probabilityOfDamage[i] = probabilityOfDamageTable[indexProbability[i]] + (probitFunction[i] - probitFunctionTable[indexProbability[i]]) * (probabilityOfDamageTable[indexProbability[i] + 1] - probabilityOfDamageTable[indexProbability[i]]) / 0.5;
            } else if (indexProbability[i] > 39) {
            probabilityOfDamage[i] = 1;
            }
          }
        }
  
        if(saveMode){
          return {
            radiationIntencity: radiationIntencity,
            probabilityOfDamage: probabilityOfDamage,
          }
        }

        let radiationIntencityGraph = [];
        let probabilityOfDamageGraph = [];
        
        for (let i = 0; i < 10000; i++) {
          radiationIntencityGraph[i] = radiationIntencity[i];
          probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
        }
      
        while (radiationIntencityGraph[radiationIntencityGraph.length - 1] < 1) {
          radiationIntencityGraph.pop();
        }
      
        while (probabilityOfDamageGraph[probabilityOfDamageGraph.length - 1] === 0) {
          probabilityOfDamageGraph.pop();
        }
        
        let distance1; //расстояние от оборудования, где интенсивность излучения равна 10.5 кВт/м2
        let distance2; //расстояние от оборудования, где интенсивность излучения равна 7.0 кВт/м2
        let distance3; //расстояние от оборудования, где интенсивность излучения равна 4.2 кВт/м2
        let distance4; //расстояние от оборудования, где интенсивность излучения равна 1.4 кВт/м2

        for (let i = 0; i < radiationIntencityGraph.length; i++) {
          if (radiationIntencityGraph[i] >= 10.5) {distance1 = i;}
          if (radiationIntencityGraph[i] >= 7.0) {distance2 = i;}
          if (radiationIntencityGraph[i] >= 4.2) {distance3 = i;}
          if (radiationIntencityGraph[i] >= 1.4) {distance4 = i;}
        }

        let abscissaRad = [];
        for (let i = 0; i < radiationIntencityGraph.length; i++) {
          if(radiationIntencityGraph[i] > 0) {
            abscissaRad[i] = i;
          }
          
        }
      
        let abscissaProb = [];
        for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
          if(probabilityOfDamageGraph[i] > 0.01) {
            abscissaProb[i] = i;
          }
        }

        let data,options,probabilityData,probabilityOptions
        if(graphType === 'radiationIntencity') {
          data  = {
            labels: abscissaRad,
            datasets: [
              {
                label: 'Интенсивность теплового излучения вертикального факела',
                data: radiationIntencityGraph,
                backgroundColor: [],
                borderColor: [],
                pointRadius: [],
              }
            ],
          }
    
          options =  {
            animation: false,
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Расстояние от оборудования, м'
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: 'Интенсивность теплового излучения, кВт/м2'
                }
              },
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 0,
                  color: '#3278e1',
                  font: {
                    size: 16,
                  }
                }
              },
              datalabels: {
                display: function(context) {
                  return (context.dataIndex === distance1 || context.dataIndex === distance2 || context.dataIndex === distance3 || context.dataIndex === distance4)
                },
                formatter: function(value, context) {
                  if (context.dataIndex === distance1) {
                    return 1;
                  } else if (context.dataIndex === distance2) {
                    return 2;
                  } else if (context.dataIndex === distance3) {
                    return 3;
                  } else if (context.dataIndex === distance4) {
                    return 4;
                  }
                },
                color: 'white',
                borderRadius: 10,
                backgroundColor: '#ff4d00',
                align: '-45',
                padding: {
                  top: 5,
                  right: 5,
                  bottom: 5,
                  left: 5
                },
                offset: 10,
              }
            }
          }
    
          for (let i = 0; i < data.labels.length; i++) {
            if (data.labels[i] === distance1 || data.labels[i] === distance2 || data.labels[i] === distance3 || data.labels[i] === distance4) {
              data.datasets[0].pointRadius[i] = 5;
              data.datasets[0].backgroundColor[i] = '#ff4d00';
              data.datasets[0].borderColor[i] = '#ff4d00';
            } else {
              data.datasets[0].borderColor[i] = '#3278e1';
              data.datasets[0].pointRadius[i] = 0;
            }
          }
        } else {
          probabilityData  = {
            labels: abscissaProb,
            datasets: [{
              label: 'Условная вероятность поражения человека на открытом пространстве',
              data: probabilityOfDamageGraph,
              borderColor: '#3278e1',
              pointRadius: 0,
            }]
          }

          probabilityOptions = {
            animation: false,
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Расстояние от оборудования, м'
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: 'Условная вероятность поражения, %'
                }
              },
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 0,
                  color: '#3278e1',
                  font: {
                    size: 16,
                  }
                }
              },
              datalabels: {
                display: false
              }
            }
          }
        }

        if(chart) {
          chart.destroy()
        }

        if(graphType === 'radiationIntencity') {
          chart = new Chart(ctx, { type: 'line', data, options })
          if(radiationIntencityGraph.length) {
            return chart.toBase64Image()  
          } else {
            return false
          }
        } else {
          chart = new Chart(ctx, { type: 'line', data: probabilityData, options: probabilityOptions})
          if(probabilityOfDamageGraph.length) {
            return chart.toBase64Image()  
          } else {
            return false
          }
        }
      }

      function calculateCloudExplosionFire(graphType, activeAccident, saveMode, currentObject) {
        const ATMOSPHERE_PRESSURE = 101325; // атмоссферное давление, Па
        const EXPLOSION_PARTICIPATION_RATE = 0.1; // коэффициент участия горючего во взрыве
        const CONST_43 = 43; // константа, равная 43
        const CONST_26 = 26; // константа, равная 26
        const SOUND_SPEED = 340; // скорость звука в воздухе, м/c
        const DEGREE_OF_EXPANSION = 7; // степень расширения продуктов сгорания газа или пара

        let explosionInOpenSpace = currentObject.explosionInOpenSpace; // характеристика взрыва топливовоздушной смеси в открытом пространстве

        let carbonNumber = currentObject.substance.carbonNumber; // число атомов углерода в молекуле горючего
        let hydrogenNumber  = currentObject.substance.hydrogenNumber; // число атомов водорода в молекуле горючего
        let oxygenNumber  = currentObject.substance.oxygenNumber; // число атомов кислорода в молекуле горючего
        let halogenNumber = currentObject.substance.halogenNumber; // число атомов галоидов в молекуле горючего

        let specificHeatOfCombustion  = currentObject.substance.specificHeatOfCombustion; // удельная теплота сгорания горючего газа или пара, Дж/кг
        let bottomConcentrationLimit  = currentObject.substance.bottomConcentrationLimit; // нижний концентрационный предел распространения пламени, %(об.)
        let fuelClass  = currentObject.substance.fuelClass; // класс горючего вещества по степени чувствительности к возбуждению взрывных процессов

        let spaceClass  = project.currentProjectGeneralData.class; // класс окружающего пространства по степени загроможденности
        let massSubstanceInCloud  = currentObject.failureParameterObj[activeAccident].failureParameter.massSubstanceInCloud; // масса газа или пара, поступившего в открытое пространство, кг

        let probitFunctionTable = [-5.0,-4.5,-4.0,-3.5,-3.0,-2.5,-2.0,-1.5,-1.0,-0.5,0.0,0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,11.0,11.5,12.0,12.5,13.0,13.5,14.0,14.5,15.0];
        let probabilityOfDamageTable = [0,0,0,0,0,3.191e-14,1.280e-12,4.016e-11,9.866e-10,1.899e-8,2.867e-7,3.398e-6,3.167e-5,2.326e-4,1.350e-3,6.210e-3,0.023,0.067,0.159,0.309,0.500,0.691,0.841,0.933,0.977,0.994,0.999,1,1,1,1,1,1,1,1,1,1,1,1,1,1,]

        let distanceFromEquipment = []; // расстояние от оборудования, м
        for (let i = 0; i < 10000; i++) {
          distanceFromEquipment[i] = i;
        }

        let stoichiometricCoefficient; // стехиометрический коэффициент кислорода в реакции сгорания
        let stoichiometricConcentration; // стехиометрическая концентрация горючих газов или паров, %(об.)
        let cloudMassInIgnitionZone; // масса горючего газа или пара, содержащегося в облаке, с концентрацией между нижним и верхним концентрационными пределами распространения пламени, кг
        let fuelConcentration; // концентрация горючего вещества в смеси, %(об.)
        let energyEfficiency; // эфективный энергозапас горючей смеси, Дж
        let combustionClass; // класс режима сгорания облака
        let flameFrontVelocity; // скорость фронта пламени, необходимая для расчета 2-6 классов режима сгорания облака
        let dimensionlessDistance = []; // безразмерное расстояние
        let dimensionlessPressure = []; // безразмерное давление
        let overpressure = []; // размерная величина избыточного давления, Па
        let dimensionlessPulse = []; // безразмерный импульс фазы сжатия
        let pulse = []; // размерный импульс фазы сжатия, Па*с;
        let funcV = []; // функция V;
        let probitFunction = []; // пробит функция
        let indexProbability = []; //индекс массива, рядом с которым находится значение условной вероятности поражения
        let probabilityOfDamage = []; //условная вероятность поражения человека волной давления

        stoichiometricCoefficient = carbonNumber + (hydrogenNumber - halogenNumber) / 4 - oxygenNumber / 2;
        stoichiometricConcentration = 100 / (1 + 4.84 * stoichiometricCoefficient);
        cloudMassInIgnitionZone = EXPLOSION_PARTICIPATION_RATE * massSubstanceInCloud;
        fuelConcentration = bottomConcentrationLimit;
        if (explosionInOpenSpace === "surfaceExplosionYes") {
          if (fuelConcentration <= stoichiometricConcentration) {
            energyEfficiency = 2 * cloudMassInIgnitionZone * specificHeatOfCombustion;
          } else {
            energyEfficiency = 2 * cloudMassInIgnitionZone * specificHeatOfCombustion * stoichiometricConcentration / fuelConcentration;
          }
        } else if (explosionInOpenSpace === "surfaceExplosionNo") {
          if (fuelConcentration <= stoichiometricConcentration) {
            energyEfficiency = cloudMassInIgnitionZone * specificHeatOfCombustion;
          } else {
            energyEfficiency = cloudMassInIgnitionZone * specificHeatOfCombustion * stoichiometricConcentration / fuelConcentration;
          }
        }
        if (fuelClass === "class_1" && spaceClass === "class_I") {combustionClass = 1}
        if (fuelClass === "class_1" && spaceClass === "class_II") {combustionClass = 1}
        if (fuelClass === "class_1" && spaceClass === "class_III") {combustionClass = 2}
        if (fuelClass === "class_1" && spaceClass === "class_IV") {combustionClass = 3}
        if (fuelClass === "class_2" && spaceClass === "class_I") {combustionClass = 1}
        if (fuelClass === "class_2" && spaceClass === "class_II") {combustionClass = 2}
        if (fuelClass === "class_2" && spaceClass === "class_III") {combustionClass = 3}
        if (fuelClass === "class_2" && spaceClass === "class_IV") {combustionClass = 4}
        if (fuelClass === "class_3" && spaceClass === "class_I") {combustionClass = 2}
        if (fuelClass === "class_3" && spaceClass === "class_II") {combustionClass = 3}
        if (fuelClass === "class_3" && spaceClass === "class_III") {combustionClass = 4}
        if (fuelClass === "class_3" && spaceClass === "class_IV") {combustionClass = 5}
        if (fuelClass === "class_4" && spaceClass === "class_I") {combustionClass = 3}
        if (fuelClass === "class_4" && spaceClass === "class_II") {combustionClass = 4}
        if (fuelClass === "class_4" && spaceClass === "class_III") {combustionClass = 5}
        if (fuelClass === "class_4" && spaceClass === "class_IV") {combustionClass = 6}

        if (combustionClass === 6) {
          flameFrontVelocity = CONST_26 * Math.pow(massSubstanceInCloud, 1 / 6);
        }
        if (combustionClass === 5) {
          flameFrontVelocity = CONST_43 * Math.pow(massSubstanceInCloud, 1 / 6);
        }
        if (combustionClass === 4) {
          flameFrontVelocity = CONST_43 * Math.pow(massSubstanceInCloud, 1 / 6);
          if (flameFrontVelocity < 200) {
            flameFrontVelocity = 200;
          }
        }
        if (combustionClass === 3) {
          flameFrontVelocity = CONST_43 * Math.pow(massSubstanceInCloud, 1 / 6);
          if (flameFrontVelocity < 300) {
            flameFrontVelocity = 300;
          }
        }
        if (combustionClass === 2) {
          flameFrontVelocity = CONST_43 * Math.pow(massSubstanceInCloud, 1 / 6);
          if (flameFrontVelocity < 500) {
            flameFrontVelocity = 500;
          }
        }
        if (combustionClass === 1) {
          flameFrontVelocity = 0;
        }
        for (let i = 0; i < distanceFromEquipment.length; i++) {
          dimensionlessDistance[i] = distanceFromEquipment[i] / Math.pow((energyEfficiency / ATMOSPHERE_PRESSURE), 1 / 3);
          if (combustionClass === 1) {
            if (dimensionlessDistance[i] >= 0.2) {
              dimensionlessPressure[i] = Math.exp(-1.124 - 1.66 * Math.log(dimensionlessDistance[i]) + 0.260 * Math.pow(Math.log(dimensionlessDistance[i]), 2));
            } else {
              dimensionlessPressure[i] = 18;
            }
          } else if (combustionClass === 2 || combustionClass === 3 || combustionClass === 4 || combustionClass === 5 || combustionClass === 6) {
            if (dimensionlessDistance[i] > 0.34) {
              dimensionlessPressure[i] = (Math.pow(flameFrontVelocity, 2) / Math.pow(SOUND_SPEED, 2)) * ((DEGREE_OF_EXPANSION - 1) / DEGREE_OF_EXPANSION) * (0.83 / dimensionlessDistance[i] - 0.14 / Math.pow(dimensionlessDistance[i], 2));
            } else {
              dimensionlessPressure[i] = (Math.pow(flameFrontVelocity, 2) / Math.pow(SOUND_SPEED, 2)) * ((DEGREE_OF_EXPANSION - 1) / DEGREE_OF_EXPANSION) * (0.83 / 0.34 - 0.14 / Math.pow(0.34, 2));
            }
          }
          overpressure[i] = dimensionlessPressure[i] * ATMOSPHERE_PRESSURE;
          if (combustionClass === 1) {
            if (dimensionlessDistance[i] >= 0.2) {
              dimensionlessPulse[i] = Math.exp(-3.4217 - 0.898 * Math.log(dimensionlessDistance[i]) - 0.0096 * Math.pow(Math.log(dimensionlessDistance[i]), 2));
            } else {
              dimensionlessPulse[i] = Math.exp(-3.4217 - 0.898 * Math.log(0.14) - 0.0096 * Math.pow(Math.log(0.14), 2));
            }
          } else if (combustionClass === 2 || combustionClass === 3 || combustionClass === 4 || combustionClass === 5 || combustionClass === 6) {
            if (dimensionlessDistance[i] > 0.34) {
              dimensionlessPulse[i] = (flameFrontVelocity / SOUND_SPEED) * ((DEGREE_OF_EXPANSION - 1) / DEGREE_OF_EXPANSION) * (1 - 0.4 * (flameFrontVelocity / SOUND_SPEED) * ((DEGREE_OF_EXPANSION - 1) / DEGREE_OF_EXPANSION)) * (0.06 / dimensionlessDistance[i] + 0.01 / Math.pow(dimensionlessDistance[i], 2) - 0.0025 / Math.pow(dimensionlessDistance[i], 3));
            } else {
              dimensionlessPulse[i] = (flameFrontVelocity / SOUND_SPEED) * ((DEGREE_OF_EXPANSION - 1) / DEGREE_OF_EXPANSION) * (1 - 0.4 * (flameFrontVelocity / SOUND_SPEED) * ((DEGREE_OF_EXPANSION - 1) / DEGREE_OF_EXPANSION)) * (0.06 / 0.34 + 0.01 / Math.pow(0.34, 2) - 0.0025 / Math.pow(0.34, 3));
            }
          }
          pulse[i] = dimensionlessPulse[i] * Math.pow(ATMOSPHERE_PRESSURE, 2 / 3) * Math.pow(energyEfficiency, 1 / 3) / SOUND_SPEED;
          funcV[i] = Math.pow((17500 / overpressure[i]), 8.4) + Math.pow((290 / pulse[i]), 9.3);
          probitFunction[i] = 5 - 0.26 * Math.log(funcV[i]);
          indexProbability[i] = Math.trunc((probitFunction[i] + 5) / 0.5);
          if (probitFunction[i] < -5) {
            probabilityOfDamage[i] = 0;
          } else if (indexProbability[i] <= 39) {
            probabilityOfDamage[i] = probabilityOfDamageTable[indexProbability[i]] + (probitFunction[i] - probitFunctionTable[indexProbability[i]]) * (probabilityOfDamageTable[indexProbability[i] + 1] - probabilityOfDamageTable[indexProbability[i]]) / 0.5;
          } else if(indexProbability[i] > 39) {
            probabilityOfDamage[i] = 1;
          }
        }

        if(saveMode){
          return {
            overpressure: overpressure,
            probabilityOfDamage: probabilityOfDamage,
            combustionClass: combustionClass,
          }
        }

        let overpressureGraph = [];
        let probabilityOfDamageGraph = [];
        
        if (combustionClass === 1) {
          let distanceFromEquipmentMax // максимальное расстояние от оборудования для построения графиков, м
          distanceFromEquipmentMax = 6.5 * Math.pow((energyEfficiency / ATMOSPHERE_PRESSURE), 1 / 3);
          for (let i = 0; i < 10000; i++) {
            if (i < distanceFromEquipmentMax) {
              overpressureGraph[i] = overpressure[i] / 1000;
              probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
            } else {
              overpressure[i] = 0;
              probabilityOfDamage[i] = 0;
              overpressureGraph[i] = overpressure[i] / 1000;
              probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
            }
          }
        } else if (combustionClass === 2 || combustionClass === 3 || combustionClass === 4 || combustionClass === 5 || combustionClass === 6) {
          for (let i = 0; i < 10000; i++) {
            overpressureGraph[i] = overpressure[i] / 1000;
            probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
          }
        }
      
        while (overpressureGraph[overpressureGraph.length - 1] < 10) {
          overpressureGraph.pop();
        }
      
        while (probabilityOfDamageGraph[probabilityOfDamageGraph.length - 1] < 1e-3) {
          probabilityOfDamageGraph.pop();
        }
        
        let distance1; //расстояние от оборудования, где избыточное давление равно 100 кПа
        let distance2; //расстояние от оборудования, где избыточное давление равно 53 кПа
        let distance3; //расстояние от оборудования, где избыточное давление равно 28 кПа
        let distance4; //расстояние от оборудования, где избыточное давление равно 12 кПа

        if(!saveMode) {
          for (let i = 0; i < overpressureGraph.length; i++) {
            if (overpressureGraph[i] >= 100) {distance1 = i;}
            if (overpressureGraph[i] >= 53) {distance2 = i;}
            if (overpressureGraph[i] >= 28) {distance3 = i;}
            if (overpressureGraph[i] >= 12) {distance4 = i;}
          }
        }

        let abscissaRad = [];
        for (let i = 0; i < overpressureGraph.length; i++) {
          if(overpressureGraph[i] > 0) {
            abscissaRad[i] = i;
          }
        }
      
        let abscissaProb = [];
        for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
          if(probabilityOfDamageGraph[i] > 0.01) {
            abscissaProb[i] = i;
          }
        }

        let data,options,probabilityData,probabilityOptions

        if(graphType === 'radiationIntencity') {
          data  = {
            labels: abscissaRad,
            datasets: [
              {
                label: 'Избыточное давление взрыва облака',
                data: overpressureGraph,
                backgroundColor: [],
                borderColor: [],
                pointRadius: [],
              }
            ],
          }
    
          options =  {
            animation: false,
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Расстояние от оборудования, м'
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: 'Избыточное давление взрыва, кПа'
                }
              },
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 0,
                  color: '#3278e1',
                  font: {
                    size: 16,
                  }
                }
              },
              datalabels: {
                display: function(context) {
                  return (context.dataIndex === distance1 || context.dataIndex === distance2 || context.dataIndex === distance3 || context.dataIndex === distance4)
                },
                formatter: function(value, context) {
                  if (context.dataIndex === distance1) {
                    return 1;
                  } else if (context.dataIndex === distance2) {
                    return 2;
                  } else if (context.dataIndex === distance3) {
                    return 3;
                  } else if (context.dataIndex === distance4) {
                    return 4;
                  }
                },
                color: 'white',
                borderRadius: 10,
                backgroundColor: '#ff4d00',
                align: '-45',
                padding: {
                  top: 5,
                  right: 5,
                  bottom: 5,
                  left: 5
                },
                offset: 10,
              }
            }
          }
    
          for (let i = 0; i < data.labels.length; i++) {
            if (data.labels[i] === distance1 || data.labels[i] === distance2 || data.labels[i] === distance3 || data.labels[i] === distance4) {
              data.datasets[0].pointRadius[i] = 5;
              data.datasets[0].backgroundColor[i] = '#ff4d00';
              data.datasets[0].borderColor[i] = '#ff4d00';
            } else {
              data.datasets[0].borderColor[i] = '#3278e1';
              data.datasets[0].pointRadius[i] = 0;
            }
          }
        } else {
          probabilityData  = {
            labels: abscissaProb,
            datasets: [{
              label: 'Условная вероятность поражения человека в здании',
              data: probabilityOfDamageGraph,
              borderColor: '#3278e1',
              pointRadius: 0,
            }]
          }

          probabilityOptions = {
            animation: false,
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Расстояние от оборудования, м'
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: 'Условная вероятность поражения, %'
                }
              },
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 0,
                  color: '#3278e1',
                  font: {
                    size: 16,
                  }
                }
              },
              datalabels: {
                display: false
              }
            }
          }
        }

        if(chart) {
          chart.destroy()
        }

        if(graphType === 'radiationIntencity') {
          chart = new Chart(ctx, { type: 'line', data, options })
          if(overpressureGraph.length) {
            return chart.toBase64Image()  
          } else {
            return false
          }
        } else {
          chart = new Chart(ctx, { type: 'line', data: probabilityData, options: probabilityOptions})
          if(probabilityOfDamageGraph.length) {
            return chart.toBase64Image()  
          } else {
            return false
          }
        }
      }

      function calculateTankExplosionFire(graphType, activeAccident, saveMode, currentObject) {
        const ATMOSPHERE_PRESSURE = 101325; // атмоссферное давление, Па
        const PRESSURE_WAVE_ENERGY_FRACTION = 0.5; // доля энергии волны давления
        const SPECIFIC_HEAT_OF_LIQUID = 2000; // удельная теплоемкость жидкости, Дж/(кг*К) (для расчета взрыва резервуара в очаге пожара)

        let constA = currentObject.substance.constA; // константа A уравнения Антуана;
        let constB = currentObject.substance.constB; // константа B уравнения Антуана;
        let constCa = currentObject.substance.constCa; // константа Ca уравнения Антуана;

        let boilingTemperature = currentObject.substance.boilingTemperature; // температура кипения жидкости (сжиженного газа) при атмосферном давлении, K
        let massSubstanceInEquipment = currentObject.failureParameterObj[activeAccident].failureParameter.massSubstanceInEquipment; // масса вещества, содержащаяся в емкости, кг
        let valvePressure = currentObject.objDetailData.baseData.valvePressure; // избыточное давление срабатывания предохранительного устройства, кПа

        let probitFunctionTable = [-5.0,-4.5,-4.0,-3.5,-3.0,-2.5,-2.0,-1.5,-1.0,-0.5,0.0,0.5,1.0,1.5,2.0,2.5,3.0,3.5,4.0,4.5,5.0,5.5,6.0,6.5,7.0,7.5,8.0,8.5,9.0,9.5,10.0,10.5,11.0,11.5,12.0,12.5,13.0,13.5,14.0,14.5,15.0];
        let probabilityOfDamageTable = [0,0,0,0,0,3.191e-14,1.280e-12,4.016e-11,9.866e-10,1.899e-8,2.867e-7,3.398e-6,3.167e-5,2.326e-4,1.350e-3,6.210e-3,0.023,0.067,0.159,0.309,0.500,0.691,0.841,0.933,0.977,0.994,0.999,1,1,1,1,1,1,1,1,1,1,1,1,1,1,]

        let distanceFromEquipment = []; // расстояние от оборудования, м
        for (let i = 0; i < 10000; i++) {
          distanceFromEquipment[i] = i;
        }

        let tankTemperature; // температура в емкости при давлении срабатывания предохранительного устройства, К
        let effectiveExplosionEnergy; // эффективная энергия взрыва, Дж
        let givenMass; // приведенная масса, кг
        let overpressure = []; // избыточное давление взрыва, Па
        let pulse = []; // импульс фазы сжатия, Па*с;
        let funcV = []; // функция V;
        let probitFunction = []; // пробит функция
        let indexProbability = []; //индекс массива, рядом с которым находится значение условной вероятности поражения
        let probabilityOfDamage = []; //условная вероятность поражения человека волной давления

        tankTemperature = constB / (constA - Math.log10(valvePressure + ATMOSPHERE_PRESSURE / 1000)) - constCa + 273.15;

        effectiveExplosionEnergy = PRESSURE_WAVE_ENERGY_FRACTION * SPECIFIC_HEAT_OF_LIQUID * massSubstanceInEquipment * (tankTemperature - boilingTemperature);

        givenMass = (effectiveExplosionEnergy / 4.52) * Math.pow(10, -6);
        for (let i = 0; i < distanceFromEquipment.length; i++) {

          if (distanceFromEquipment[i] <= 1) {
            overpressure[i] = ATMOSPHERE_PRESSURE * (0.8 * Math.pow(givenMass, 0.33) + 3 * Math.pow(givenMass, 0.66) + 5 * givenMass);
            pulse[i] = 123 * Math.pow(givenMass, 0.66);
          } else {
            overpressure[i] = ATMOSPHERE_PRESSURE * (0.8 * Math.pow(givenMass, 0.33) / distanceFromEquipment[i] + 3 * Math.pow(givenMass, 0.66) / Math.pow(distanceFromEquipment[i], 2) + 5 * givenMass / Math.pow(distanceFromEquipment[i], 3));
            pulse[i] = 123 * Math.pow(givenMass, 0.66) / distanceFromEquipment[i];
          }
          funcV[i] = Math.pow((17500 / overpressure[i]), 8.4) + Math.pow((290 / pulse[i]), 9.3);
          probitFunction[i] = 5 - 0.26 * Math.log(funcV[i]);
          indexProbability[i] = Math.trunc((probitFunction[i] + 5) / 0.5);
          if (probitFunction[i] < -5) {
            probabilityOfDamage[i] = 0;
          } else if (indexProbability[i] <= 39) {
            probabilityOfDamage[i] = probabilityOfDamageTable[indexProbability[i]] + (probitFunction[i] - probitFunctionTable[indexProbability[i]]) * (probabilityOfDamageTable[indexProbability[i] + 1] - probabilityOfDamageTable[indexProbability[i]]) / 0.5;
          } else if(indexProbability[i] > 39) {
            probabilityOfDamage[i] = 1;
          }
        }

        if(saveMode){
          return {
            overpressure: overpressure,
            probabilityOfDamage: probabilityOfDamage,
            tankTemperature: tankTemperature,
            boilingTemperature: boilingTemperature
          }
        }

        let overpressureGraph = [];
        let probabilityOfDamageGraph = [];
      
        for (let i = 0; i < 10000; i++) {
          overpressureGraph[i] = overpressure[i] / 1000;
          probabilityOfDamageGraph[i] = probabilityOfDamage[i] * 100;
        }
      
        while (overpressureGraph[overpressureGraph.length - 1] < 10) {
          overpressureGraph.pop();
        }
      
        while (probabilityOfDamageGraph[probabilityOfDamageGraph.length - 1] < 1e-3) {
          probabilityOfDamageGraph.pop();
        }
        
        let distance1; //расстояние от оборудования, где избыточное давление равно 100 кПа
        let distance2; //расстояние от оборудования, где избыточное давление равно 53 кПа
        let distance3; //расстояние от оборудования, где избыточное давление равно 28 кПа
        let distance4; //расстояние от оборудования, где избыточное давление равно 12 кПа

        if(!saveMode){
          for (let i = 0; i < overpressureGraph.length; i++) {
            if (overpressureGraph[i] >= 100) {distance1 = i;}
            if (overpressureGraph[i] >= 53) {distance2 = i;}
            if (overpressureGraph[i] >= 28) {distance3 = i;}
            if (overpressureGraph[i] >= 12) {distance4 = i;}
          }
        }
        

        let abscissaPress = [];
        for (let i = 0; i < overpressureGraph.length; i++) {
          abscissaPress[i] = i;
        }
      
        let abscissaProb = [];
        for (let i = 0; i < probabilityOfDamageGraph.length; i++) {
          abscissaProb[i] = i;
        }

        let data,options,probabilityData,probabilityOptions
        if(graphType === 'radiationIntencity') {
          data  = {
            labels: abscissaPress,
            datasets: [
              {
                label: 'Избыточное давление взрыва резервуара',
                data: overpressureGraph,
                backgroundColor: [],
                borderColor: [],
                pointRadius: [],
              }
            ],
          }
    
          options =  {
            animation: false,
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Расстояние от оборудования, м'
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: 'Избыточное давление взрыва, кПа'
                },
                max: 1000,
              },
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 0,
                  color: '#3278e1',
                  font: {
                    size: 16,
                  }
                }
              },
              datalabels: {
                display: function(context) {
                  return (context.dataIndex === distance1 || context.dataIndex === distance2 || context.dataIndex === distance3 || context.dataIndex === distance4)
                },
                formatter: function(value, context) {
                  if (context.dataIndex === distance1) {
                    return 1;
                  } else if (context.dataIndex === distance2) {
                    return 2;
                  } else if (context.dataIndex === distance3) {
                    return 3;
                  } else if (context.dataIndex === distance4) {
                    return 4;
                  }
                },
                color: 'white',
                borderRadius: 10,
                backgroundColor: '#ff4d00',
                align: '-45',
                padding: {
                  top: 5,
                  right: 5,
                  bottom: 5,
                  left: 5
                },
                offset: 10,
              }
            }
          }
    
          for (let i = 0; i < data.labels.length; i++) {
            if (data.labels[i] === distance1 || data.labels[i] === distance2 || data.labels[i] === distance3 || data.labels[i] === distance4) {
              data.datasets[0].pointRadius[i] = 5;
              data.datasets[0].backgroundColor[i] = '#ff4d00';
              data.datasets[0].borderColor[i] = '#ff4d00';
            } else {
              data.datasets[0].borderColor[i] = '#3278e1';
              data.datasets[0].pointRadius[i] = 0;
            }
          }
        } else {
          probabilityData  = {
            labels: abscissaPress,
            datasets: [{
              label: 'Условная вероятность поражения человека в здании',
              data: probabilityOfDamageGraph,
              borderColor: '#3278e1',
              pointRadius: 0,
            }]
          }

          probabilityOptions = {
            animation: false,
            scales: {
              x: {
                display: true,
                title: {
                  display: true,
                  text: 'Расстояние от оборудования, м'
                }
              },
              y: {
                display: true,
                title: {
                  display: true,
                  text: 'Условная вероятность поражения, %'
                }
              },
            },
            plugins: {
              legend: {
                labels: {
                  boxWidth: 0,
                  color: '#3278e1',
                  font: {
                    size: 16,
                  }
                }
              },
              datalabels: {
                display: false
              }
            }
          }
        }

        if(chart) {
          chart.destroy()
        }

        if(graphType === 'radiationIntencity') {
          chart = new Chart(ctx, { type: 'line', data, options })
          if(overpressureGraph.length) {
            return chart.toBase64Image()  
          } else {
            return false
          }
        } else {
          chart = new Chart(ctx, { type: 'line', data: probabilityData, options: probabilityOptions})
          if(probabilityOfDamageGraph.length) {
            return chart.toBase64Image()  
          } else {
            return false
          }
        }
      }

      function probabilityOfDamageChart(currentObject) {
        let сhartData = null
        let potRiskGraph = []
        for (let i = 0; i < 10000; i++) {
          potRiskGraph[i] = 0;
        }

        let radiationdetailChart = null
        let probabilitydetailChart = null

        let detailGraphs = []

        let globalChartData = null
        let globalChartOptions = null

        let graphDataObj1 = {} 
        let graphDataObj2 = {} 
  
        currentObject.failureParameterObj.map((failure, indx) => {
          failure.trees.map((treeElement) => {
            treeElement.fires.map((element, fireindx) => {
              switch (element.value) {
                case 0:
                  break;
                case 1:
                  сhartData = calculatePoolFire('probabilityOfDamage', indx, true, currentObject)
                  radiationdetailChart = calculatePoolFire('radiationIntencity', indx, false, currentObject)
                  probabilitydetailChart = calculatePoolFire('probabilityOfDamage', indx, false, currentObject)

                  graphDataObj1 = {
                    failureName: failureName(currentObject.objDetailData.accidents[indx]),
                    accidentName: element.label,
                    image: radiationdetailChart
                  }
                  graphDataObj2 = {
                    failureName: failureName(currentObject.objDetailData.accidents[indx]),
                    accidentName: element.label,
                    image: probabilitydetailChart
                  }

                  detailGraphs.push(graphDataObj1,graphDataObj2)
                  break;
                case 2:
                  сhartData = calculateBallFire('probabilityOfDamage', indx, true, currentObject)
                  radiationdetailChart = calculateBallFire('radiationIntencity', indx, false, currentObject)
                  probabilitydetailChart = calculateBallFire('probabilityOfDamage', indx, false, currentObject)

                  graphDataObj1 = {
                    failureName: failureName(currentObject.objDetailData.accidents[indx]),
                    accidentName: element.label,
                    image: radiationdetailChart
                  }
                  graphDataObj2 = {
                    failureName: failureName(currentObject.objDetailData.accidents[indx]),
                    accidentName: element.label,
                    image: probabilitydetailChart
                  }

                  detailGraphs.push(graphDataObj1,graphDataObj2)
                  break;
                case 3:
                  сhartData = calculateFlashFire(indx , true, currentObject)
                  probabilitydetailChart = calculateFlashFire(indx, false, currentObject)

                  graphDataObj1 = {
                    failureName: failureName(currentObject.objDetailData.accidents[indx]),
                    accidentName: element.label,
                    image: probabilitydetailChart
                  }

                  detailGraphs.push(graphDataObj1)
                  break;
                case 4:
                  сhartData = calculateHorisontalJetFire(indx , true, currentObject)

                  probabilitydetailChart = calculateHorisontalJetFire(indx, false, currentObject)

                  graphDataObj1 = {
                    failureName: failureName(currentObject.objDetailData.accidents[indx]),
                    accidentName: element.label,
                    image: probabilitydetailChart
                  }

                  detailGraphs.push(graphDataObj1)
                  break;
                case 5:
                  сhartData = calculateVerticalJetFire('probabilityOfDamage', indx , true, currentObject)
                  radiationdetailChart = calculateVerticalJetFire('radiationIntencity', indx, false, currentObject)
                  probabilitydetailChart = calculateVerticalJetFire('probabilityOfDamage', indx, false, currentObject)

                  graphDataObj1 = {
                    failureName: failureName(currentObject.objDetailData.accidents[indx]),
                    accidentName: element.label,
                    image: radiationdetailChart
                  }
                  graphDataObj2 = {
                    failureName: failureName(currentObject.objDetailData.accidents[indx]),
                    accidentName: element.label,
                    image: probabilitydetailChart
                  }

                  detailGraphs.push(graphDataObj1,graphDataObj2)
                  break;
                case 6:
                  сhartData = calculateCloudExplosionFire('probabilityOfDamage', indx , true, currentObject)
                  radiationdetailChart = calculateCloudExplosionFire('radiationIntencity', indx, false, currentObject)
                  probabilitydetailChart = calculateCloudExplosionFire('probabilityOfDamage', indx, false, currentObject)

                  graphDataObj1 = {
                    failureName: failureName(currentObject.objDetailData.accidents[indx]),
                    accidentName: element.label,
                    image: radiationdetailChart
                  }
                  graphDataObj2 = {
                    failureName: failureName(currentObject.objDetailData.accidents[indx]),
                    accidentName: element.label,
                    image: probabilitydetailChart
                  }

                  detailGraphs.push(graphDataObj1,graphDataObj2)
                  break;
                case 7:
                  сhartData = calculateTankExplosionFire('probabilityOfDamage', indx , true, currentObject)
                  radiationdetailChart = calculateTankExplosionFire('radiationIntencity', indx, false, currentObject)
                  probabilitydetailChart = calculateTankExplosionFire('probabilityOfDamage', indx, false, currentObject)

                  graphDataObj1 = {
                    failureName: failureName(currentObject.objDetailData.accidents[indx]),
                    accidentName: element.label,
                    image: radiationdetailChart
                  }
                  graphDataObj2 = {
                    failureName: failureName(currentObject.objDetailData.accidents[indx]),
                    accidentName: element.label,
                    image: probabilitydetailChart
                  }

                  detailGraphs.push(graphDataObj1,graphDataObj2)
                  break;
              }

              if(сhartData) {
                for (let i = 0; i < 10000; i++) {
                  potRiskGraph[i] +=  treeElement.frequencyScenario[fireindx] * сhartData.probabilityOfDamage[i];
                }
              }
            })
          })
        })

        while (potRiskGraph[potRiskGraph.length - 1] < 1e-10) {
          potRiskGraph.pop();
        }

        let abscissaPotRisk = [];
        for (let i = 0; i < potRiskGraph.length; i++) {
            abscissaPotRisk[i] = i;
        }

        let distance1PotRisk; //расстояние от оборудования, где величина потенциального риска равна 1e-4
        let distance2PotRisk; //расстояние от оборудования, где величина потенциального риска равна 1e-6
        let distance3PotRisk; //расстояние от оборудования, где величина потенциального риска равна 1e-8

        for (let i = 0; i < potRiskGraph.length; i++) {
          if (potRiskGraph[i] >= 1e-4) {distance1PotRisk = i;}
          if (potRiskGraph[i] >= 1e-6) {distance2PotRisk = i;}
          if (potRiskGraph[i] >= 1e-8) {distance3PotRisk = i;}
        }

        globalChartData  = {
          labels: abscissaPotRisk,
          datasets: [{
            label: 'Интегральный потенциальный риск',
            data: potRiskGraph,
            backgroundColor: [],
            borderColor: [],
            pointRadius: [],
          }]
        }

        globalChartOptions = {
          animation: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Расстояние от оборудования, м'
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Потенциальный риск, год-1'
              },
              type: 'logarithmic',
              ticks: {
                callback: function(value) {
                  return value.toExponential(0);
                }
              }
            },
          },
          plugins: {
            legend: {
              labels: {
                boxWidth: 0,
                color: '#3278e1',
                font: {
                  size: 16,
                }
              }
            },
            datalabels: {
                display: function(context) {
                      return (context.dataIndex === distance1PotRisk || context.dataIndex === distance2PotRisk || context.dataIndex === distance3PotRisk)
                    },
                formatter: function(value, context) {
                  if (context.dataIndex === distance1PotRisk) {
                    return 1e-4.toExponential(0);
                  } else if (context.dataIndex === distance2PotRisk) {
                    return 1e-6.toExponential(0);
                  } else if (context.dataIndex === distance3PotRisk) {
                    return 1e-8.toExponential(0);
                  }
                },
                color: 'white',
                borderRadius: 10,
                backgroundColor: '#ff4d00',
                align: '-45',
                padding: {
                  top: 5,
                  right: 5,
                  bottom: 5,
                  left: 5
                },
                offset: 10,
            }
          },
        }
        for (let i = 0; i < globalChartData.labels.length; i++) {
          if (globalChartData.labels[i] === distance1PotRisk || globalChartData.labels[i] === distance2PotRisk || globalChartData.labels[i] === distance3PotRisk) {
            globalChartData.datasets[0].pointRadius[i] = 5;
            globalChartData.datasets[0].backgroundColor[i] = '#ff4d00';
            globalChartData.datasets[0].borderColor[i] = '#ff4d00';
          } else {
            globalChartData.datasets[0].borderColor[i] = '#3278e1';
            globalChartData.datasets[0].pointRadius[i] = 0;
          }
        }
        if(chart) {
          chart.destroy()
        }

        chart = new Chart(ctx, { type: 'line', data: globalChartData, options: globalChartOptions })

        if(potRiskGraph.length) {
          detailGraphs.push({
            failureName: null,
            accidentName: null,
            image: chart.toBase64Image()
          })
          return detailGraphs 
        } else {
          return false
        }
      }

      let indexNumber = 0
      allEquipments.map((element) => {
        if(element && element.substance.name && !usedSubstancesNames.find(el => el === element.substance.name)) {
          let arr = [
            new Paragraph({
              text: `${indexNumber += 1}. Наименование вещества - ${element.substance.name}`,
              heading: HeadingLevel.HEADING_3,
            }),
            new Paragraph({
              text: `Число атомов углерода в молекуле горючего: ${element.substance.carbonNumber}`,
              style: "main",
            }),
            new Paragraph({
              text: `Число атомов водорода в молекуле горючего: ${element.substance.hydrogenNumber}`,
              style: "main",
            }),
            new Paragraph({
              text: `Число атомов кислорода в молекуле горючего: ${element.substance.oxygenNumber}`,
              style: "main",
            }),
            new Paragraph({
              text: `Число атомов галоидов в молекуле горючего: ${element.substance.halogenNumber}`,
              style: "main",
            }),
            new Paragraph({
              text: `Молярная масса, кг/кмоль: ${element.substance.molarMass}`,
              style: "main",
            }),
            new Paragraph({
              text: `Удельная теплота сгорания горючего газа или пара, Дж/кг: ${element.substance.specificHeatOfCombustion}`,
              style: "main",
            }),
            new Paragraph({
              text: `Нижний концентрационный предел распространения пламени, % (об.): ${element.substance.bottomConcentrationLimit}`,
              style: "main",
            }),
            new Paragraph({
              text: `Класс горючего вещества по степени чувствительности к возбуждению взрывных процессов:  ${message[element.substance.fuelClass]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Удельная массовая скорость выгорания жидкости, кг/(м2*с): ${element.substance.massBurningRate}`,
              style: "main",
            }),
            new Paragraph({
              text: `Плотность вещества, кг/м3: ${element.substance.substanceDensity}`,
              style: "main",
            }),
            new Paragraph({
              text: `Константы уравнения Антуана: ${element.substance.constA}, ${element.substance.constB}, ${element.substance.constCa}`,
              style: "main",
            }),
            new Paragraph({
              text: `Температура вспышки, К: ${element.substance.flashPoint}`,
              style: "main",
            }),
            new Paragraph({
              text: `Температура кипения при нормальных условиях, К: ${element.substance.boilingTemperature}`,
              style: "main",
            }),
            new Paragraph({
              text: `Класс вещества в оборудовании (для расчета факела): ${message[element.substance.classOfSubstanceJetFire]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Тип жидкости в оборудовании (для расчета пожара пролива): ${message[element.substance.nameOfSubstancePoolFire]}`,
              style: "main",
            }),

          ]
          usedSubstances.push(arr)
          usedSubstancesNames.push(element.substance.name)
        }
      })
      for(let i = 0; i < usedSubstances.length; i++) {
        usedSubstancesData.push(
          {
            properties: {
              type: SectionType.CONTINUOUS,
            },
            children: usedSubstances[i]
          }
        )
      }

      indexNumber = 0
      arrEquipment.map((element) => {
        if(!groupId || (groupId && groupId !== element.groupId)) {
          groupId = element.groupId

          const graph = probabilityOfDamageChart(element)

          let arr = [
            new Paragraph({
              text: `${indexNumber += 1}. Наименование Аппарата - ${element.nameOfEquipment}`,
              heading: HeadingLevel.HEADING_3,
            }),
            new Paragraph({
              text: `Класс технологического оборудования: ${message[element.objDetailData.baseData.equipmentClass]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Агрегатное состояние вещества: ${message[element.objDetailData.baseData.stateOfAggregation]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Наименование вещества: ${element.substance.name}`,
              style: "main",
            }),
            new Paragraph({
              text: `Расположение оборудования: ${message[element.equipmentLocation]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Объем емкостного оборудования, м3: ${element.objDetailData.baseData.volumeTank}`,
              style: "main",
            }),

            new Paragraph({
              text: `Степень заполнения емкостного оборудования: ${element.objDetailData.baseData.fillRatioTank}`,
              style: "main",
            }),
            new Paragraph({
              text: `Высота уровня взлива жидкости в емкостном оборудовании, м: ${element.objDetailData.baseData.liquidHeightTank}`,
              style: "main",
            }),
            new Paragraph({
              text: `Избыточное давление в емкостном оборудовании, Па: ${element.objDetailData.baseData.overpressureTank}`,
              style: "main",
            }),
            new Paragraph({
              text: `Избыточное давление срабатывания предохранительного клапана или мембраны, кПа (критическое избыточное давление разгерметизации - для герметичных аппаратов при отсутствии предохранительных устройств): ${element.objDetailData.baseData.valvePressure}`,
              style: "main",
            }),
            new Paragraph({
              text: `Подстилающая поверхность: ${message[element.objDetailData.baseData.surfaceProperty]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Наличие поддона (обвалование, ограждающая стена, бортики и т.п.): ${message[element.objDetailData.baseData.palletAvailability]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Аварийно-спасательные формирования ликвидируют возгорание при мгновенном воспламенении вещества, вышедшего из оборудования в результате его локального повреждения (в том числе полного разрушения трубопровода), и герметизируют место утечки ${message[element.objDetailData.baseData.liquidationHoleEmergencyServices]}`,
              style: "main",
            }),

            new Paragraph({
              text: `Аварийно-спасательные формирования ликвидируют пролив вещества,
              покрывая его пеной или перекачивая в аварийный резервуар: ${message[element.objDetailData.baseData.liquidationSpillEmergencyServices]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Характеристика взрыва топливовоздушной смеси в открытом пространстве: ${message[element.objDetailData.baseData.explosionInOpenSpace]}`,
              style: "main",
            }),
          ]

          if(graph) {
            let graphGroup = null
            let accidenGroup = null
            let failureIndex = 0
            graph.forEach((el, index) => {
              if(el.failureName && el.failureName !== graphGroup) {
                graphGroup = el.failureName
                arr.push(
                  new Paragraph({
                    text: `Авария - ${el.failureName}`,
                    heading: HeadingLevel.HEADING_3,
                    pageBreakBefore: true,
                  }),
                )
                let failure = element.failureParameterObj[failureIndex]
                arr.push(
                  new Paragraph({
                    text: `Логическое дерево событий для аварии`,
                    heading: HeadingLevel.HEADING_3,
                  }),
                )
                arr.push(
                  new Paragraph({
                    children: [
                        new ImageRun({
                          data: failure.treesPhoto.image,
                          transformation: {
                            width: failure.treesPhoto.width > 1100 ? failure.treesPhoto.width / 2.5 : failure.treesPhoto.width / 1.2,
                            height: failure.treesPhoto.width > 1100 ? failure.treesPhoto.height / 2.5 : failure.treesPhoto.height / 1.2,
                          },
                          floating: {
                            horizontalPosition: {
                              relative: HorizontalPositionRelativeFrom.PAGE,
                              offset: 500000
                            },
                            verticalPosition: {
                                relative: VerticalPositionRelativeFrom.PARAGRAPH,
                                align: VerticalPositionAlign.TOP,
                            },
                          },
                        }),
                    ],
                  }),
                )
                failureIndex += 1
              }

              if(el.accidentName && el.accidentName !== accidenGroup) {
                accidenGroup = el.accidentName
                arr.push(
                  new Paragraph({
                    text: el.accidentName,
                    heading: HeadingLevel.HEADING_3,
                    pageBreakBefore: true,
                  }),
                )
              }

              if(index+1 === graph.length) {
                arr.push(
                  new Paragraph({
                    text: 'Общий график потенциального риска',
                    heading: HeadingLevel.HEADING_3,
                    pageBreakBefore: true,
                  }),
                )
              }

              arr.push(
                new Paragraph({
                  children: [
                      new ImageRun({
                          data: el.image,
                          transformation: {
                            width: 620,
                            height: 400,
                          },
                      }),
                  ],
                }),
              )
            })
          }
          arrEquipments.push(arr)
        }
      })
      for(let i = 0; i < arrEquipments.length; i++) {
        arrEquipmentsData.push(
          {
            properties: {
              type: SectionType.CONTINUOUS,
            },
            children: arrEquipments[i]
          }
        )
      }

      indexNumber = 0
      if(arrPipeEquipment) {
        arrPipeEquipment.map((element) => {

          const graph = probabilityOfDamageChart(element)

          let arr = [
            new Paragraph({
              text: `${indexNumber += 1}. Наименование трубопровода - ${element.nameOfEquipment}`,
              heading: HeadingLevel.HEADING_3,
            }),
            new Paragraph({
              text: `Класс технологического оборудования: ${message[element.objDetailData.baseData.equipmentClass]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Агрегатное состояние вещества: ${message[element.objDetailData.baseData.stateOfAggregation]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Наименование вещества: ${element.substance.name}`,
              style: "main",
            }),
            new Paragraph({
              text: `Расположение оборудования: ${message[element.equipmentLocation]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Объем емкостного оборудования, м3: ${element.objDetailData.baseData.volumeTank}`,
              style: "main",
            }),

            new Paragraph({
              text: `Степень заполнения емкостного оборудования: ${element.objDetailData.baseData.fillRatioTank}`,
              style: "main",
            }),
            new Paragraph({
              text: `Разность высот уровня взлива жидкости в емкостном оборудовании и трубопровода, м: ${element.objDetailData.baseData.deltaLiquidHeight}`,
              style: "main",
            }),
            new Paragraph({
              text: `Избыточное давление в емкостном оборудовании, Па: ${element.objDetailData.baseData.overpressureTank}`,
              style: "main",
            }),
            new Paragraph({
              text: `Подстилающая поверхность: ${message[element.objDetailData.baseData.surfaceProperty]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Наличие поддона (обвалование, ограждающая стена, бортики и т.п.): ${message[element.objDetailData.baseData.palletAvailability]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Аварийно-спасательные формирования ликвидируют возгорание при мгновенном воспламенении вещества, вышедшего из оборудования в результате его локального повреждения (в том числе полного разрушения трубопровода), и герметизируют место утечки ${message[element.objDetailData.baseData.liquidationHoleEmergencyServices]}`,
              style: "main",
            }),

            new Paragraph({
              text: `Аварийно-спасательные формирования ликвидируют пролив вещества,
              покрывая его пеной или перекачивая в аварийный резервуар: ${message[element.objDetailData.baseData.liquidationSpillEmergencyServices]}`,
              style: "main",
            }),
            new Paragraph({
              text: `Характеристика взрыва топливовоздушной смеси в открытом пространстве: ${message[element.objDetailData.baseData.explosionInOpenSpace]}`,
              style: "main",
            }),
          ]
          if(graph) {
            let graphGroup = null
            let accidenGroup = null
            let failureIndex = 0
            graph.forEach((el, index) => {
              if(el.failureName && el.failureName !== graphGroup) {
                graphGroup = el.failureName
                arr.push(
                  new Paragraph({
                    text: `Авария - ${el.failureName}`,
                    heading: HeadingLevel.HEADING_3,
                    pageBreakBefore: true,
                  }),
                )
                let failure = element.failureParameterObj[failureIndex]
                arr.push(
                  new Paragraph({
                    text: `Логическое дерево событий для аварии`,
                    heading: HeadingLevel.HEADING_3,
                  }),
                )
                arr.push(
                  new Paragraph({
                    children: [
                        new ImageRun({
                            data: failure.treesPhoto.image,
                            transformation: {
                              width: failure.treesPhoto.width > 1100 ? failure.treesPhoto.width / 2.5 : failure.treesPhoto.width / 1.2,
                              height: failure.treesPhoto.width > 1100 ? failure.treesPhoto.height / 2.5 : failure.treesPhoto.height / 1.2,
                            },
                            floating: {
                              horizontalPosition: {
                                relative: HorizontalPositionRelativeFrom.PAGE,
                                offset: 500000
                              },
                              verticalPosition: {
                                  relative: VerticalPositionRelativeFrom.PARAGRAPH,
                                  align: VerticalPositionAlign.TOP,
                              },
                            },
                        }),
                    ],
                  }),
                )
                failureIndex += 1
              }

              if(el.accidentName && el.accidentName !== accidenGroup) {
                accidenGroup = el.accidentName
                arr.push(
                  new Paragraph({
                    text: el.accidentName,
                    heading: HeadingLevel.HEADING_3,
                    pageBreakBefore: true,
                  }),
                )
              }

              if(index+1 === graph.length) {
                arr.push(
                  new Paragraph({
                    text: 'Общий график потенциального риска',
                    heading: HeadingLevel.HEADING_3,
                    pageBreakBefore: true,
                  }),
                )
              }

              arr.push(
                new Paragraph({
                  children: [
                      new ImageRun({
                          data: el.image,
                          transformation: {
                            width: 620,
                            height: 400,
                          },
                      }),
                  ],
                }),
              )
            })
          }
          arrPipeEquipments.push(arr)
        })
        for(let i = 0; i < arrPipeEquipments.length; i++) {
          arrPipeEquipmentsData.push(
            {
              properties: {
                type: SectionType.CONTINUOUS,
              },
              children: arrPipeEquipments[i]
            }
          )
        }
      }

      if(project.currentProject.arrBuilding) {
        project.currentProject.arrBuilding.map((element,index) => {
          let cell = [
            new TableCell({
              margins: {
                left: convertInchesToTwip(0.05),
                right: convertInchesToTwip(0.1),
              },
              children: [
                new Paragraph({
                  text: (index+1).toString(),
                  style: "tableText",
                })
              ],
            }),
            new TableCell({
              margins: {
                left: convertInchesToTwip(0.05),
                right: convertInchesToTwip(0.1),
              },
              children: [
                new Paragraph({
                  text: element.name,
                  style: "tableText",
                })
              ],
            }),
            new TableCell({
              margins: {
                left: convertInchesToTwip(0.05),
                right: convertInchesToTwip(0.1),
              },
              children: [
                new Paragraph({
                  text: element.potRiskInBuilding/* .toExponential(3).toString() */,
                  style: "tableText",
                })
              ],
            }),
          ]
          buildingTable.push(
            new TableRow({
              children: cell
            })
          )
        })
      }


      let workersIndex = 0

      if(project.currentProject.arrWorker) {
        project.currentProject.arrWorker.map((workElement) => {
          workElement.arrProbabilityOfPresenceWorker.map((posElement,index) => {
            let cell = [
              new TableCell({
                margins: {
                  left: convertInchesToTwip(0.05),
                  right: convertInchesToTwip(0.1),
                },
                children: [
                  new Paragraph({
                    text: (workersIndex += 1).toString(),
                    style: "tableText",
                  })
                ],
              }),
              new TableCell({
                margins: {
                  left: convertInchesToTwip(0.05),
                  right: convertInchesToTwip(0.1),
                },
                children: [
                  new Paragraph({
                    text: workElement.name.toString(),
                    style: "tableText",
                  })
                ],
              }),
              new TableCell({
                margins: {
                  left: convertInchesToTwip(0.05),
                  right: convertInchesToTwip(0.1),
                },
                children: [
                  new Paragraph({
                    text: workElement.arrWorkerPosNames && workElement.arrWorkerPosNames[index] ?workElement.arrWorkerPosNames[index].toString() : '',
                    style: "tableText",
                  })
                ],
              }),
              new TableCell({
                margins: {
                  left: convertInchesToTwip(0.05),
                  right: convertInchesToTwip(0.1),
                },
                children: [
                  new Paragraph({
                    text: posElement.toString(),
                    style: "tableText",
                  })
                ],
              }),
            ]
            workersTable.push(
              new TableRow({
                children: cell
              })
            )
          })
        })
      }

      if(project.currentProject.arrZone) {
        project.currentProject.arrZone.map((element, index) => {
          let cell = [
            new TableCell({
              margins: {
                left: convertInchesToTwip(0.05),
                right: convertInchesToTwip(0.1),
              },
              children: [
                new Paragraph({
                  text: (index + 1).toString(),
                  style: "tableText",
                })
              ],
            }),
            new TableCell({
              margins: {
                left: convertInchesToTwip(0.05),
                right: convertInchesToTwip(0.1),
              },
              children: [
                new Paragraph({
                  text: element.name.toString(),
                  style: "tableText",
                })
              ],
            }),
            new TableCell({
              margins: {
                left: convertInchesToTwip(0.05),
                right: convertInchesToTwip(0.1),
              },
              children: [
                new Paragraph({
                  text: element.numberOfPersons.toString(),
                  style: "tableText",
                })
              ],
            }),
            new TableCell({
              margins: {
                left: convertInchesToTwip(0.05),
                right: convertInchesToTwip(0.1),
              },
              children: [
                new Paragraph({
                  text: element.probabilityOfPresencePerson.toString(),
                  style: "tableText",
                })
              ],
            }),
          ]
          zonesTable.push(
            new TableRow({
              children: cell
            })
          )
        })
      }

      const doc = new Document({
        styles: {
          default: {
            heading1: {
              run: {
                size: 32,
                bold: true,
                color: "000000",
                font: "Calibri"
              },
              paragraph: {
                spacing: {
                  after: 500,
                },
              },
            },
            heading2: {
              run: {
                size: 28,
                bold: true,
                color: "3278e1",
                font: "Calibri"
              },
              paragraph: {
                spacing: {
                  before: 400,
                  after: 200,
                },
                indent: {
                  left: 360,
                },
              },
            },
            heading3: {
              run: {
                size: 28,
                bold: true,
                color: "000000",
                font: "Calibri"
              },
              paragraph: {
                spacing: {
                  line: 225,
                  before: 120,
                  after: 120,
                },
              },
            },
          },
          paragraphStyles: [
            {
                id: "main",
                name: "Main",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: {
                  color: "000000",
                  size: 28,
                  font: "Calibri"
                },
                paragraph: {
                  spacing: {
                    line: 225,
                    before: 120,
                    after: 120,
                  },
                },
            },
            {
                id: "small",
                name: "Small",
                basedOn: "Normal",
                next: "Normal",
                quickFormat: true,
                run: {
                  color: "000000",
                  size: 22,
                  font: "Calibri",
                  italics: true,
                },
                paragraph: {
                  spacing: {
                    line: 225,
                  },
                },
            },
            {
              id: "tableText",
              name: "TableText",
              basedOn: "Normal",
              next: "Normal",
              quickFormat: true,
              run: {
                color: "000000",
                font: "Calibri",
                size: 26
              },
              paragraph: {
                spacing: {
                  line: 276,
                  after: 50,
                },
              },
            },
          ]
        },

        sections: [{
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [
            new Paragraph({
                text: 'Отчет по расчетному определению величин пожарного риска на производственном объекте в информационно-аналитической системе «Probit»',
                heading: HeadingLevel.HEADING_1,
                alignment: AlignmentType.CENTER,
            }),

            new Paragraph({
              text: '1.Общие данные по проекту',
              heading: HeadingLevel.HEADING_2,
            }),

            new Paragraph({
                text: `Наименование проекта - ${project.currentProjectGeneralData.name}`,
                style: "main",
            }),

            new Paragraph({
                text: `Описание проекта - ${project.currentProjectGeneralData.description ? project.currentProjectGeneralData.description : ''}`,
                style: "main",
            }),

            new Paragraph({
              text: '2.Город и климатические особенности в городе расположения объекта защиты',
              heading: HeadingLevel.HEADING_2,
            }),

            new Paragraph({
              text: `Наименование города - ${project.region.name}`,
              style: "main",
            }),
            new Paragraph({
              text: `Абсолютная максимальная температура воздуха в городе расположения предприятия, К: ${project.region.absoluteMaxTemperature}`,
              style: "main",
            }),
            new Paragraph({
              text: `Средняя максимальная температура воздуха наиболее теплого месяца в городе расположения предприятия, К: ${project.region.averageMaxTemperature}`,
              style: "main",
            }),
            new Paragraph({
              text: `Средние скорости ветра по направлениям в июле в городе расположения предприятия, м/c:`,
              style: "main",
            }),

            new Paragraph({
              text: `северный: ${project.region.northWindVelocity}`,
              style: "main",
              bullet: {
                  level: 0
              }
            }),
            new Paragraph({
              text: `северo-восточный: ${project.region.northEastWindVelocity}`,
              style: "main",
              bullet: {
                  level: 0
              }
            }),
            new Paragraph({
              text: `восточный: ${project.region.eastWindVelocity}`,
              style: "main",
              bullet: {
                  level: 0
              }
            }),
            new Paragraph({
              text: `юго-восточный: ${project.region.southEastWindVelocity}`,
              style: "main",
              bullet: {
                  level: 0
              }
            }),
            new Paragraph({
              text: `южный: ${project.region.southWindVelocity}`,
              style: "main",
              bullet: {
                  level: 0
              }
            }),
            new Paragraph({
              text: `юго-западный: ${project.region.southWestWindVelocity}`,
              style: "main",
              bullet: {
                  level: 0
              }
            }),
            new Paragraph({
              text: `западный: ${project.region.westWindVelocity}`,
              style: "main",
              bullet: {
                  level: 0
              }
            }),
            new Paragraph({
              text: `северо-западный: ${project.region.northWestWindVelocity}`,
              style: "main",
              bullet: {
                  level: 0
              }
            }),
            new Paragraph({
              text: `Повторяемость штилей, доли: ${project.region.calm}`,
              style: "main",
            }),

            new Paragraph({
              text: '3.Класс окружающего пространства по степени загроможденности',
              heading: HeadingLevel.HEADING_2,
            }),

            new Paragraph({
              text: `Класс окружающего пространства по степени загроможденности - ${message[project.currentProjectGeneralData.class]}`,
              style: "main",
            }),

            new Paragraph({
              text: `Примечание:`,
              style: "small",
            }),
            new Paragraph({
              text: `Kласс I - наличие длинных труб, полостей, каверн, заполненных горючей смесью, при сгорании которой возможно ожидать формирование турбулентных струй продуктов сгорания, имеющих размеры не менее трех размеров детонационной ячейки данной смеси.`,
              style: "small",
            }),
            new Paragraph({
              text: `Kласс II - сильно загроможденное пространство: наличие полузамкнутых объемов высокая плотность размещения технологического оборудования, лес, большое количество повторяющихся препятствий.`,
              style: "small",
            }),
            new Paragraph({
              text: `Kласс III - средне загроможденное пространство: отдельно стоящие технологические установки, резервуарный парк.`,
              style: "small",
            }),
            new Paragraph({
              text: `Класс IV - слабо загроможденное и свободное пространство`,
              style: "small",
            }),

            new Paragraph({
              text: '4.Свойства веществ, обращающихся на объекте защиты',
              heading: HeadingLevel.HEADING_2,
            }),
          ]
        },
        ...usedSubstancesData,
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [
            new Paragraph({
              text: '5.Таблица с порядковыми номерами и наименованиями зданий с указанием значений потенциального пожарного риска в них.',
              heading: HeadingLevel.HEADING_2,
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "№ п/п",
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Наименование здания",
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Значение потенциального пожарного риска, год-1",
                          style: "tableText",
                        })
                      ],
                    }),
                  ],
                }),
                ...buildingTable
              ],
            }),
          ]
        },
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [
            new Paragraph({
              text: '6.Таблица с порядковыми номерами и наименованиями должностей работников с указанием вероятности присутствия на рабочем месте',
              heading: HeadingLevel.HEADING_2,
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "№ п/п",
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Должность работника",
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Наименование рабочего места",
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Вероятность присутствия на рабочем месте",
                          style: "tableText",
                        })
                      ],
                    }),
                  ],
                }),
                ...workersTable
              ],
            }),
          ]
        },
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [
            new Paragraph({
              text: '7.Таблица с порядковыми номерами и наименованиями селитебных зон с указанием значений числа людей в селитебной зоне и вероятности их присутствия в селитебной зоне.',
              heading: HeadingLevel.HEADING_2,
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "№ п/п",
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Наименование селитебной зоны",
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Число людей",
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Вероятность присутствия",
                          style: "tableText",
                        })
                      ],
                    }),
                  ],
                }),
                ...zonesTable
              ],
            }),
          ]
        },
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [
            new Paragraph({
              text: '8.Поле потенциального пожарного риска на генеральном (ситуационном) плане объекта защиты',
              heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph({
              children: [
                new ImageRun({
                    data: objectFile,
                    transformation: {
                      width: 620,
                      height: 310,
                    },
                }),
              ],
            }),
            new Paragraph({
              heading: HeadingLevel.HEADING_3,
              children: [
                new ImageRun({
                  data: diagramFile,
                  transformation: {
                    width: 620,
                    height: 74,
                  },
                }),
              ],
            }),

            new Paragraph({
              text: 'Номера точек на графиках интенсивности теплового излучения',
              heading: HeadingLevel.HEADING_3,
              alignment: AlignmentType.CENTER,
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Номер точки",
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Степень поражения",
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Интенсивность излучения, кВт/м2",
                          style: "tableText",
                        })
                      ],
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          verticalAlign: VerticalAlign.CENTER,
                          text: '1',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: `Непереносимая боль через 3-5 с`,
                          style: "tableText",
                        }),
                        new Paragraph({
                          text: `Ожог 1 степени через 6-8 с`,
                          style: "tableText",
                        }),
                        new Paragraph({
                          text: `Ожог 2 степени через 12-16 с`,
                          style: "tableText",
                        }),
                      ],
                    }),
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '10,5',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                  ]
                }),

                new TableRow({
                  children: [
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '2',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: `Непереносимая боль через 20-30 с`,
                          style: "tableText",
                        }),
                        new Paragraph({
                          text: `Ожог 1 степени через 15-20 с`,
                          style: "tableText",
                        }),
                        new Paragraph({
                          text: `Ожог 2 степени через 30-40 с`,
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '7,0',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                  ]
                }),

                new TableRow({
                  children: [
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '3',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: `Безопасно для человека в брезентовой одежде`,
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '4,2',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                  ]
                }),

                new TableRow({
                  children: [
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '4',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: `Без негативных последствий в течение длительного времени`,
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '1,4',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                  ]
                })
              ]
            }),

            new Paragraph({
              text: 'Номера точек на графиках избыточного давления взрыва',
              heading: HeadingLevel.HEADING_3,
              alignment: AlignmentType.CENTER,
            }),
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Номер точки",
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Степень поражения",
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: "Избыточное давление, кПа",
                          style: "tableText",
                        })
                      ],
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          verticalAlign: VerticalAlign.CENTER,
                          text: '1',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: `Полное разрушение зданий`,
                          style: "tableText",
                        }),
                      ],
                    }),
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '100',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                  ]
                }),

                new TableRow({
                  children: [
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '2',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: `50 %-ное разрушение зданий`,
                          style: "tableText",
                        }),
                      ],
                    }),
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '53',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                  ]
                }),

                new TableRow({
                  children: [
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '3',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: `Средние повреждения зданий`,
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '28',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                  ]
                }),

                new TableRow({
                  children: [
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '4',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                    new TableCell({
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: `Умеренные повреждения зданий (повреждение внутренних перегородок, рам, дверей и т.п.)`,
                          style: "tableText",
                        })
                      ],
                    }),
                    new TableCell({
                      verticalAlign: VerticalAlign.CENTER,
                      margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.1),
                      },
                      children: [
                        new Paragraph({
                          text: '12',
                          style: "tableText",
                          alignment: AlignmentType.CENTER,
                        })
                      ],
                    }),
                  ]
                })
              ]
            }),
          ]
        },
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [
            new Paragraph({
              text: '9.Характеристики оборудования типа «Аппарат»',
              heading: HeadingLevel.HEADING_2,
            }),
          ]
        },
        ...arrEquipmentsData,
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [
            new Paragraph({
              text: '10.Характеристики оборудования типа «Трубопровод»',
              heading: HeadingLevel.HEADING_2,
            }),
          ]
        },
        ...arrPipeEquipmentsData,
        {
          properties: {
            type: SectionType.CONTINUOUS,
          },
          children: [
            new Paragraph({
              text: '11.Расчетные величины пожарного риска',
              heading: HeadingLevel.HEADING_2,
              pageBreakBefore: true,
            }),
            new Paragraph({
              text: 'Индивидуальный пожарный риск для работников, год-1',
              heading: HeadingLevel.HEADING_3,
            }),
            new Paragraph({
              text: project.currentProject.risks.indRiskWorkers ? project.currentProject.risks.indRiskWorkers.toExponential(3).toString(): '0',
              style: 'main'
            }),
            new Paragraph({
              text: 'Индивидуальный пожарный риск для населения, год-1',
              heading: HeadingLevel.HEADING_3,
            }),
            new Paragraph({
              text: project.currentProject.risks.indRiskPersons ? project.currentProject.risks.indRiskPersons.toExponential(3).toString() : '0',
              style: 'main'
            }),
            new Paragraph({
              text: 'Социальный пожарный риск для населения, год-1',
              heading: HeadingLevel.HEADING_3,
            }),
            new Paragraph({
              text: project.currentProject.risks.socRisk ? project.currentProject.risks.socRisk.toExponential(3).toString() : '0',
              style: 'main'
            }),
          ]
        },
      ],
      });

      // Used to export the file into a .docx file
      Packer.toBlob(doc).then(blob => {
        saveAs(blob, "Probit.docx");
        console.log("Document created successfully");
      });
    }
  }
}
