<template>
  <div class="project-wrapper">
    <div v-if="isChanged" class="profile__subscribe">
      <div class="profile__subscribe-text">Произошли изменения в проекте, необходимо пересчитать риски.</div>
      <div class="close" @click="isChanged = false">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L21 21" stroke="#8C959C" />
          <path d="M21 1L1 21" stroke="#8C959C" />
        </svg>
      </div>
    </div>

    <div class="project">
      <div class="project__bar">

        <div class="project__object">

          <div class="project__object-header" @click="activeValue === 'Аппараты' ? (activeValue = '') : (activeValue = 'Аппараты')">
            <img v-if="arrEquipment && arrEquipment.length" class="project__object-header-arrow" :class="{ 'project__object-header-arrow--rotate': activeValue === 'Аппараты' }" src="../assets/icons/icon-arrow-filled.svg" alt="" />
            <div class="project__object-header-name">Аппараты</div>
            <div v-if="arrEquipment && arrEquipment.length" class="project__object-header-count">
              {{ arrEquipment.length }}
            </div>
          </div>


          <div v-if="arrEquipment && arrEquipment.length && activeValue === 'Аппараты'" class="project__object-values">
            <div v-for="(group, key, groupidx) in equipmentGroups" :key="groupidx + 'group'" >
              <div v-if="group.length" class="project__object-value" :class="{ chosen: chosenObject === `${group[0].nameOfEquipment}${groupidx}` }">
                <div class="project__object-value-name" @click="selectObject(group[0], groupidx)">
                  {{ group[0].nameOfEquipment }}
                </div>
                <div class="project__object-value-options">
                  <div class="project__object-value-option" @click="editObject(group[0], 'CreateEquipment', 'Редактировать аппарат')">
                    <img src="../assets/icons/icon-edit.svg" alt="" />
                  </div>
                  <div class="project__object-value-option" @click="openChart(group[0])">
                    <img src="../assets/icons/icon-line-chart.svg" alt="" />
                  </div>

                  <el-popconfirm title="Подтвердите удаление" cancelButtonText="Отмена" confirmButtonText="Да" @confirm="delPointGroup(group[0].groupId, arrEquipment, 'Equipment', 'arrEquipment')">
                    <template #reference>
                      <div class="project__object-value-option">
                        <img src="../assets/icons/icon-delete.svg" alt="" />
                      </div>
                    </template>
                  </el-popconfirm>
                </div>
              </div>
            </div>
          </div>



        </div>

        <div class="project__object">
          <div class="project__object-header" @click="activeValue === 'Трубопроводы' ? (activeValue = '') : (activeValue = 'Трубопроводы')">
            <img v-if="arrPipeEquipment && arrPipeEquipment.length" class="project__object-header-arrow" :class="{ 'project__object-header-arrow--rotate': activeValue === 'Трубопроводы' }" src="../assets/icons/icon-arrow-filled.svg" alt="" />
            <div class="project__object-header-name">Трубопроводы</div>
            <div v-if="arrPipeEquipment && arrPipeEquipment.length" class="project__object-header-count">
              {{ arrPipeEquipment.length }}
            </div>
          </div>
          <div v-if="arrPipeEquipment && arrPipeEquipment.length && activeValue === 'Трубопроводы'" class="project__object-values">
            <div v-for="(value, idx2) in arrPipeEquipment" :key="idx2" class="project__object-value" :class="{ chosen: chosenObject === `${value.nameOfEquipment}${idx2}` }">
              <div class="project__object-value-name" @click="selectObject(value, idx2)">
                {{ value.nameOfEquipment }}
              </div>
              <div class="project__object-value-options">
                <div class="project__object-value-option" @click="editObject(value, 'CreatePipe', 'Редактировать трубопровод')">
                  <img src="../assets/icons/icon-edit.svg" alt="" />
                </div>
                <div class="project__object-value-option" @click="openChart(value)">
                  <img src="../assets/icons/icon-line-chart.svg" alt="" />
                </div>
                <el-popconfirm title="Подтвердите удаление" cancelButtonText="Отмена" confirmButtonText="Да" @confirm="delPoint(value.pointId, idx2, arrPipeEquipment, 'PipeEquipment', 'arrPipeEquipment')">
                  <template #reference>
                    <div class="project__object-value-option">
                      <img src="../assets/icons/icon-delete.svg" alt="" />
                    </div>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </div>
        </div>

        <div class="project__object">
          <div class="project__object-header" @click="activeValue === 'Здания' ? (activeValue = '') : (activeValue = 'Здания')">
            <img v-if="arrBuilding && arrBuilding.length" class="project__object-header-arrow" :class="{ 'project__object-header-arrow--rotate': activeValue === 'Здания' }" src="../assets/icons/icon-arrow-filled.svg" alt="" />
            <div class="project__object-header-name">Здания</div>
            <div v-if="arrBuilding && arrBuilding.length" class="project__object-header-count">
              {{ arrBuilding.length }}
            </div>
          </div>
          <div v-if="arrBuilding && arrBuilding.length && activeValue === 'Здания'" class="project__object-values">
            <div v-for="(value, idx2) in arrBuilding" :key="idx2 + 'building'" class="project__object-value" :class="{ chosen: chosenObject === `${value.name}${idx2}` }">
              <div class="project__object-value-name" @click="selectObject(value, idx2)">
                {{ value.name }}
              </div>
              <div class="project__object-value-options">
                <div class="project__object-value-option" @click="editObject(value, 'addBuilding', 'Редактировать здание')">
                  <img src="../assets/icons/icon-edit.svg" alt="" />
                </div>
                <el-popconfirm title="Подтвердите удаление" cancelButtonText="Отмена" confirmButtonText="Да" @confirm="delPoint(value.pointId, idx2, arrBuilding, 'Building', 'arrBuilding')">
                  <template #reference>
                    <div class="project__object-value-option">
                      <img src="../assets/icons/icon-delete.svg" alt="" />
                    </div>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </div>
        </div>

        <div class="project__object">
          <div class="project__object-header" @click="activeValue === 'Противопожарная стена' ? (activeValue = '') : (activeValue = 'Противопожарная стена')">
            <img v-if="arrWall && arrWall.length" class="project__object-header-arrow" :class="{ 'project__object-header-arrow--rotate': activeValue === 'Противопожарная стена' }" src="../assets/icons/icon-arrow-filled.svg" alt="" />
            <div class="project__object-header-name">Противопожарная стена</div>
            <div v-if="arrWall && arrWall.length" class="project__object-header-count">
              {{ arrWall.length }}
            </div>
          </div>
          <div v-if="arrWall && arrWall.length && activeValue === 'Противопожарная стена'" class="project__object-values">
            <div v-for="(value, idx2) in arrWall" :key="idx2 + 'building'" class="project__object-value" :class="{ chosen: chosenObject === `${value.name}${idx2}` }">
              <div class="project__object-value-name" @click="selectObject(value, idx2)">
                {{ value.name }}
              </div>
              <div class="project__object-value-options">
                <div class="project__object-value-option" @click="editObject(value, 'addWall', 'Редактировать противопожарную стену')">
                  <img src="../assets/icons/icon-edit.svg" alt="" />
                </div>
                <el-popconfirm title="Подтвердите удаление" cancelButtonText="Отмена" confirmButtonText="Да" @confirm="delPoint(value.pointId, idx2, arrWall, 'Wall', 'arrWall')">
                  <template #reference>
                    <div class="project__object-value-option" >
                      <img src="../assets/icons/icon-delete.svg" alt="" />
                    </div>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </div>
        </div>

        <div class="project__object">
          <div class="project__object-header" @click="activeValue === 'Работники' ? (activeValue = '') : (activeValue = 'Работники')">
            <img v-if="arrWorker && arrWorker.length" class="project__object-header-arrow" :class="{ 'project__object-header-arrow--rotate': activeValue === 'Работники' }" src="../assets/icons/icon-arrow-filled.svg" alt="" />
            <div class="project__object-header-name">Работники</div>
            <div v-if="arrWorker && arrWorker.length" class="project__object-header-count">
              {{ arrWorker.length }}
            </div>
          </div>
          <div v-if="arrWorker && arrWorker.length && activeValue === 'Работники'" class="project__object-values">
            <div v-for="(value, idx2) in arrWorker" :key="idx2" class="project__object-value" :class="{ chosen: chosenObject === `${value.name}${idx2}` }">
              <div class="project__object-value-name" @click="selectObject(value, idx2)">
                {{ value.name }}
              </div>
              <div class="project__object-value-options">
                <div class="project__object-value-option" @click="editObject(value, 'AddWorker', 'Редактировать Работника')">
                  <img src="../assets/icons/icon-edit.svg" alt="" />
                </div>
                <el-popconfirm title="Подтвердите удаление" cancelButtonText="Отмена" confirmButtonText="Да" @confirm="delPoint(value.pointId, idx2, arrWorker, 'Worker', 'arrWorker')">
                  <template #reference>
                    <div class="project__object-value-option">
                      <img src="../assets/icons/icon-delete.svg" alt="" />
                    </div>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </div>
        </div>

        <div class="project__object">
          <div class="project__object-header" @click="activeValue === 'Население' ? (activeValue = '') : (activeValue = 'Население')">
            <img v-if="arrZone && arrZone.length" class="project__object-header-arrow" :class="{ 'project__object-header-arrow--rotate': activeValue === 'Население' }" src="../assets/icons/icon-arrow-filled.svg" alt="" />
            <div class="project__object-header-name">Население</div>
            <div v-if="arrZone && arrZone.length" class="project__object-header-count">
              {{ arrZone.length }}
            </div>
          </div>
          <div v-if="arrZone && arrZone.length && activeValue === 'Население'" class="project__object-values">
            <div v-for="(value, idx2) in arrZone" :key="idx2 + 'zone'" class="project__object-value" :class="{ chosen: chosenObject === `${value.name}${idx2}` }">
              <div class="project__object-value-name" @click="selectObject(value, idx2)">
                {{ value.name }}
              </div>
              <div class="project__object-value-options">
                <div class="project__object-value-option" @click="editObject(value, 'AddPeople', 'Редактировать жилую зону')">
                  <img src="../assets/icons/icon-edit.svg" alt="" />
                </div>
                <el-popconfirm title="Подтвердите удаление" cancelButtonText="Отмена" confirmButtonText="Да" @confirm="delPoint(value.pointId, idx2, arrZone, 'Zone', 'arrZone')">
                  <template #reference>
                    <div class="project__object-value-option">
                      <img src="../assets/icons/icon-delete.svg" alt="" />
                    </div>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="project__content">
        <div class="project__content-header">
          <div class="project__content-header-title">
            {{ currentProjectGeneralData ? currentProjectGeneralData.name : '' }}
            <img v-if="output" :src="output" alt="">
          </div>
          <div v-if="currentProjectGeneralData" class="project__content-header-switch">
            <el-tooltip content="Необходимо расcчитать риски" placement="left" :disabled="!!potRisk || !!currentProjectGeneralData.risk_result.length">
              <el-switch v-model="showedRisk" active-color="#EFF2F6" inactive-color="EFF2F6" inactive-text="Отобразить потенциальный риск" :width="64" :disabled="!potRisk && !currentProjectGeneralData.risk_result.length" />
            </el-tooltip>
          </div>
        </div>
        <div class="project__content-functions">
          <label v-for="item in functions" :key="item.value" class="project__content-functions-item" :class="[{ active: selectedFunction === item.value }, { disabled: checkAccess(item.value) || showedRisk || checkFunctionsStatus(item) }]">
            <input v-model="selectedFunction" type="radio" :disabled="checkAccess(item.value) || showedRisk || checkFunctionsStatus(item)" :value="item.value" />
            <img :src="item.path" class="project__content-functions-icon" alt="" />
            <span class="project__content-functions-name">
              {{ item.name }}
            </span>
          </label>
        </div>
        <div class="project__content-view-wrapper">
          <div v-if="currentProject && currentProject.sizeData" class="project__content-view-roulette-wrapper">
            <div class="project__content-view-roulette" @click="selectedFunction = 'roulette'" :class="{ active: selectedFunction === 'roulette' }">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="roulette-icon">
                <path d="M7.93921 15.3642L15.3638 7.93954C16.4245 6.87888 16.4245 5.81822 15.3638 4.75756L13.2425 2.63624C12.1819 1.57558 11.1212 1.57558 10.0605 2.63624L2.63591 10.0609C1.57525 11.1215 1.57525 12.1822 2.63591 13.2428L4.75723 15.3642C5.81789 16.4248 6.87855 16.4248 7.93921 15.3642Z" stroke="#8C959C" stroke-width="1.5" stroke-linecap="round" />
                <path d="M9.53027 3.1665L12.1819 5.81815" stroke="#8C959C" stroke-width="1.5" stroke-linecap="round" />
                <path d="M3.1665 9.53027L5.28782 11.6516" stroke="#8C959C" stroke-width="1.5" stroke-linecap="round" />
                <path d="M5.31411 7.38266L7.93925 10.0608" stroke="#8C959C" stroke-width="1.5" stroke-linecap="round" />
                <path d="M7.40918 5.2876L9.00017 6.87859" stroke="#8C959C" stroke-width="1.5" stroke-linecap="round" />
              </svg>
              Линейка
            </div>
            <div class="project__content-view-roulette-result" :class="{ visible: rouletteResult && selectedFunction === 'roulette' }">{{ rouletteResult }} м <img src="@/assets/icons/icon-cross.svg" @click="closeRoulette" /></div>
          </div>

          <div class="project__content-view-scale">
            <button @click="changeScale('plus')" :disabled="disabledPlusScale">+</button>
            <button @click="changeScale('minus')" :disabled="disabledMinusScale">-</button>
          </div>
          <div v-dragscroll class="project__content-view">
            <div class="project__content-view-container">
              <div class="project__content-view-content" :style="`transform: scale(${mapScale});`">
                <canvas id="canvas" class="project__content-view-canvas" :width="canvasWidth" :height="canvasHeight" @dblclick.prevent="checkMode" @contextmenu="checkModeForContextMenu" />
                <canvas v-show="potRisk && showedRisk" id="canvasRisk" class="project__content-view-canvas-risk" :width="canvasWidth" :height="canvasHeight" />
<!--                <img class="project__content-view-map" src="../assets/images/project-map.jpg" alt="" />-->
                <img v-if="currentProjectGeneralData && currentProjectGeneralData.image" class="project__content-view-map" :src="currentProjectGeneralData.image[0].src" alt="" />

                <img v-if="currentProjectGeneralData && currentProjectGeneralData.risk_result.length && showRiskImage" class="project__content-view-saved-risk" :src="currentProjectGeneralData.risk_result[0].src" />
              </div>
            </div>
          </div>
        </div>
        <div class="project__content-diagram">
          <div class="project__content-diagram-chart">
            <span v-for="item in diagramsColors" :key="item" :style="`background: ${item};`" />
          </div>
          <div class="project__content-diagram-values">
            <p>1E-8</p>
            <p>1E-7</p>
            <p>1E-6</p>
            <p>1E-5</p>
            <p>1E-4</p>
          </div>
          <div class="project__content-diagram-text">Потенциальный риск, 1/год</div>
        </div>
        <CalculateRisk />
      </div>
      <div v-if="showedDialog" class="project__dialog">
        <div class="project__dialog-mask"></div>
        <div class="project__dialog-content">
          <ViewMachine />
          <div class="project__dialog-close" @click="showedDialog = false">
            <img src="../assets/icons/icon-close.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ViewMachine from '../components/ViewMachine'
import CalculateRisk from '../components/CalculateRisk'
import { ElMessageBox, ElMessage } from 'element-plus'
import { polygon, centroid } from 'turf'
import { DocGenerator } from '@/mixin/doc_saver'
import { DateParser } from '@/mixin/date_parser'

export default {
  name: 'Project',
  mixins: [DocGenerator, DateParser],
  components: {
    ViewMachine,
    CalculateRisk,
  },
  data() {
    return {
      polygon,
      centroid,
      chosenObject: null,
      isChanged: false,
      functions: [
        {
          name: 'Масштаб',
          value: 'scale',
          path: require('../assets/icons/icon-maximize.svg'),
        },
        {
          name: 'Аппараты',
          value: 'machines',
          path: require('../assets/icons/icon-mashines.svg'),
        },
        {
          name: 'Трубопроводы',
          value: 'pipes',
          path: require('../assets/icons/icon-pipeline.svg'),
        },
        {
          name: 'Здания',
          value: 'buildings',
          path: require('../assets/icons/icon-buildings.svg'),
        },
        {
          name: 'Противопожарная стена',
          value: 'grid',
          path: require('../assets/icons/icon-grid.svg'),
        },
        {
          name: 'Работники',
          value: 'worker',
          path: require('../assets/icons/icon-people.svg'),
        },
        {
          name: 'Население',
          value: 'person',
          path: require('../assets/icons/icon-folk.svg'),
        },
      ],
      rouletteResult: null,
      isScale: false,
      selectedFunction: null,
      showRiskImage: false,
      showedRisk: false,
      showedDialog: false,
      diagramsColors: ['#606FC8', '#53DCDE', '#7BC657', '#EEDD45', '#E6A64B', '#E16359'],
      activeValue: '',
      mapScale: 1,
      arrPosition: [],
      arrPosNorthDirection: [],
      arrPosScaleLine: [],
      canvasWidth: '300',
      canvasHeight: '300',
      arrPointEquipment: [],
      arrPosEquipment: [],
      arrlengthBranchEquipment: [],

      arrDrawPoints: [],
      output: null
    }
  },
  computed: {
    ...mapState('Project', ['currentProjectGeneralData', 'currentProject', 'arrEquipment', 'arrPipeEquipment', 'arrBuilding', 'arrWall', 'arrWorker', 'arrZone', 'workerStop', 'currentGroupId']),
    ...mapState('Dialog', ['redrawCanvasAfterCloseDialog']),
    disabledMinusScale() {
      return this.mapScale === 1
    },
    disabledPlusScale() {
      return this.mapScale === 3
    },
    northDirection() {
      return this.currentProject ? this.currentProject.deflectionAngle : null
    },
    potRisk() {
      return this.$store.state.Project.potRisk
    },
    selectedSwitcher() {
      return this.$store.state.Project.selectedSwitcher
    },
    user(){
      return this.$store.state.Personal.user
    },
    equipmentGroups() {
      let groups = {}
      this.arrEquipment.map((element) => {
        if(element.groupId && !groups[element.groupId]) {
          groups[element.groupId] = []
          groups[element.groupId].push(element)
        } else if(element.groupId && groups[element.groupId]) {
          groups[element.groupId].push(element)
        }
      })
      return groups
    }
  },
  watch: {
    showedRisk(val) {
      if (val) {
        this.drawRisks()
      } else {
        this.showRiskImage = false
      }
    },
    potRisk(val) {
      if (val) {
        this.showedRisk = true
      }
    },
    currentProject: {
      deep: true,
      handler(newVal, oldVal) {
        this.arrPointEquipment = []
        this.arrPosition = []
        this.arrPosEquipment = []
        this.saveChangingInProject(newVal, oldVal)
      },
    },
    redrawCanvasAfterCloseDialog: {
      handler(val) {
        if (val) {
          this.redrawCanvas()
          this.$store.commit('Dialog/setData', { label: 'redrawCanvasAfterCloseDialog', data: false })
        }
      },
    },
    selectedFunction: {
      handler() {
        this.redrawCanvas()
        this.rouletteResult = null
        this.$store.commit('Project/setData', { label: 'currentGroupId', data: Date.now() })
      },
    },

    selectedSwitcher: {
      handler() {
        if (this.checkFunctionsStatus({ name: this.$store.state.Project.selectedSwitcherName })) {
          this.selectedFunction = null
        }
      },
    },
  },
  async created() {
    await this.$store.dispatch('Project/getProject', this.$route.params.id).then(async (resp) => {
      setTimeout(() => {
        const canvasWrapper = document.querySelector('.project__content-view-content')
        if (canvasWrapper) {
          this.canvasWidth = canvasWrapper.clientWidth + ''
          this.canvasHeight = canvasWrapper.clientHeight + ''
        }

        if (resp.data.data.settings && resp.data.data.settings.drawPoints) {
          for (let key in resp.data.data.settings.drawPoints) {
            this.drawPoints(resp.data.data.settings.drawPoints[key], key === 'Equipment' || key === 'Worker' ? true : false, key === 'Building' || key === 'Zone' ? true : false, key)
          }
        }

        if (resp.data.data.settings && resp.data.data.settings.risks) {
          if (resp.data.data.settings.risks.relevanceRisks === true) {
            this.isChanged = false
          } else if (resp.data.data.settings.risks.relevanceRisks === false) {
            this.isChanged = true
          }
        }
      }, 1000)
      await this.$store.dispatch('Cities/getCity', this.currentProjectGeneralData.city_id).then(({ data }) => {
        let myData = data.data.settings
        myData.name = data.data.name
        this.$store.commit('Project/setData', { label: 'region', data: myData })
      })
      if (!this.northDirection) {
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'SetNorth' })
        this.$store.commit('Dialog/setData', { label: 'dialogNotificationVisible', data: true })
      }
      if (resp.data.data.settings && resp.data.data.settings.delta) {
        this.isScale = true
      }
    })
  },
  beforeUnmount() {
    this.$store.commit('Project/setData', { label: 'currentProjectGeneralData', data: null })
    this.$store.commit('Project/setData', { label: 'currentProject', data: null })
    this.$store.commit('Project/setData', { label: 'arrEquipment', data: [] })
    this.$store.commit('Project/setData', { label: 'arrPipeEquipment', data: [] })
    this.$store.commit('Project/setData', { label: 'arrBuilding', data: [] })
    this.$store.commit('Project/setData', { label: 'arrWall', data: [] })
    this.$store.commit('Project/setData', { label: 'arrWorker', data: [] })
    this.$store.commit('Project/setData', { label: 'arrZone', data: [] })
    this.$store.commit('Project/setData', { label: 'potRisk', data: null })
    this.$store.dispatch('Dialog/closeDialog')
  },
  mounted() {
    const canvasWrapper = document.querySelector('.project__content-view')
    if (canvasWrapper) {
      this.canvasWidth = canvasWrapper.clientWidth + ''
    }
  },
  methods: {
    saveChangingInProject(settings, oldVal) {
      if (oldVal || (!oldVal && Object.keys(settings).length === 1)) {
        let data
        if (this.currentProject && this.currentProject.risks && this.currentProject.risks.relevanceRisks && !this.$store.state.Project.potRisk) {
          this.isChanged = true
          settings.risks.relevanceRisks = false
        } else if (this.currentProject && this.currentProject.risks && this.currentProject.risks.relevanceRisks) {
          this.isChanged = false
        }
        data = {
          settings,
        }
        this.$store.dispatch('Project/changeProjects', { data, id: this.currentProjectGeneralData.id })
        this.redrawCanvas()
      }
    },

    drawRisks() {
      if (this.potRisk) {
        const potRisk = JSON.parse(JSON.stringify(this.potRisk))
        let canvasRisk = document.getElementById('canvasRisk')
        let ctxRisk = canvasRisk.getContext('2d')
        ctxRisk.clearRect(0, 0, canvasRisk.width, canvasRisk.height)
        ctxRisk.globalAlpha = 0.4
        ctxRisk.globalAlpha = 0.4
        for (let i = 0; i <= canvasRisk.width; i++) {
          for (let j = 0; j <= canvasRisk.height; j++) {
            if (potRisk[i][j] > 1e-4) {
              ctxRisk.fillStyle = 'red'
            } else if (potRisk[i][j] > 1e-5 && potRisk[i][j] <= 1e-4) {
              ctxRisk.fillStyle = 'orange'
            } else if (potRisk[i][j] > 1e-6 && potRisk[i][j] <= 1e-5) {
              ctxRisk.fillStyle = 'yellow'
            } else if (potRisk[i][j] > 1e-7 && potRisk[i][j] <= 1e-6) {
              ctxRisk.fillStyle = 'lime'
            } else if (potRisk[i][j] > 1e-8 && potRisk[i][j] <= 1e-7) {
              ctxRisk.fillStyle = 'aqua'
            } else if (potRisk[i][j] <= 1e-8) {
              ctxRisk.fillStyle = 'blue'
            }
            ctxRisk.fillRect(i, j, 1, 1)
          }
        }
        this.saveRiskCanvas()
      } else {
        this.showRiskImage = true
      }
    },

    saveRiskCanvas() {
      let canvas = document.getElementById('canvasRisk')
      let img = canvas.toDataURL()
      let file = dataURItoBlob(img)

      function dataURItoBlob(dataURI) {
        var byteString
        if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
        else byteString = unescape(dataURI.split(',')[1])
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
        var ia = new Uint8Array(byteString.length)
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ia], { type: mimeString })
      }

      file = new File([file], 'risk-result.jpg')

      if (file) {
        let data = {
          risk_result: file,
          settings: JSON.stringify(this.currentProject),
        }
        const formData = new FormData()
        for (let key in data) {
          formData.append(key, data[key])
        }

        this.$store.dispatch('Project/changeProjects', { data: formData, id: this.currentProjectGeneralData.id }).catch(() => {
          ElMessage({
            type: 'error',
            message: 'Ошибка',
          })
        })
      }
    },

    checkAccess(value) {
      if (value === 'scale') {
        return !this.northDirection || this.isScale
      } else {
        return !(this.northDirection && this.isScale)
      }
    },
    checkMode(event) {
      if (!this.northDirection) {
        this.setNorth(event)
      } else if (!this.isScale) {
        this.setScale(event)
      } else if (this.selectedFunction === 'roulette') {
        this.roulette(event)
      } else if (this.selectedFunction === 'buildings') {
        this.addBuilding(event)
      } else if (this.selectedFunction === 'grid') {
        this.addWall(event)
      } else if (this.selectedFunction === 'person') {
        this.addLivingArea(event)
      } else if (this.selectedFunction === 'machines') {
        this.addMachines(event)
      } else if (this.selectedFunction === 'pipes') {
        this.addPipes(event)
      } else if (this.selectedFunction === 'worker') {
        this.addWorker(event)
      }
    },
    checkModeForContextMenu(event) {
      event.preventDefault()
      if (this.selectedFunction === 'buildings') {
        this.finishBuilding()
      } else if (this.selectedFunction === 'person') {
        this.finishLivingArea()
      } else if (this.selectedFunction === 'machines') {
        this.finishMachines()
      } else if (this.selectedFunction === 'pipes') {
        this.finishPipes()
      } else if (this.selectedFunction === 'worker') {
        this.finishWorker(event)
      }
    },
    changeScale(val) {
      if (val === 'plus' && this.mapScale < 3) {
        this.mapScale += 0.25
      } else if (this.mapScale > 1) {
        this.mapScale -= 0.25
      }
    },
    openChart(data) {
      let role = null
      let days = null

      if (this.user) {
        role = this.user.role
        days = this.getDemoDays(this.user.role_expiration_date)
      }

      if(role === 'demo' && days === '0') {
        return false
      }

      this.$store.commit('Project/setData', { label: 'currentObject', data })
      this.showedDialog = true
    },
    deleteObject(name) {
      ElMessageBox.confirm(`Уверены, что хотите удалить объект ${name}`, 'Warning', {
        confirmButtonText: 'Да',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(() => {
        ElMessage({
          type: 'success',
          message: 'Объект удален',
        })
      })
    },
    clear() {
      const canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      this.arrPosNorthDirection = []
    },
    setNorth(event) {
      const canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      ctx.fillStyle = '#0024ED'
      ctx.strokeStyle = '#0024ED'
      if (this.arrPosNorthDirection.length < 2) {
        const x = event.offsetX
        const y = event.offsetY
        this.arrPosNorthDirection.push({ x, y })
        if (this.arrPosNorthDirection.length === 1) {
          ctx.strokeStyle = '#fff'
          ctx.lineWidth = 2
          ctx.beginPath()
          ctx.arc(x, y, 4, 2 * Math.PI, false)
          ctx.fill()
          ctx.stroke()
        }

        if (this.arrPosNorthDirection.length > 1) {
          ctx.lineWidth = 4
          ctx.beginPath()

          ctx.moveTo(this.arrPosNorthDirection[this.arrPosNorthDirection.length - 2].x, this.arrPosNorthDirection[this.arrPosNorthDirection.length - 2].y)

          ctx.lineTo(this.arrPosNorthDirection[this.arrPosNorthDirection.length - 1].x, this.arrPosNorthDirection[this.arrPosNorthDirection.length - 1].y)

          ctx.stroke()
          ctx.closePath()
        }
      }

      if (this.arrPosNorthDirection.length > 1) {
        let headlen = 20
        const angle = Math.atan2(this.arrPosNorthDirection[1].y - this.arrPosNorthDirection[0].y, this.arrPosNorthDirection[1].x - this.arrPosNorthDirection[0].x)
        ctx.lineCap = 'round'
        ctx.beginPath()
        ctx.moveTo(this.arrPosNorthDirection[1].x, this.arrPosNorthDirection[1].y)
        ctx.lineTo(this.arrPosNorthDirection[1].x - headlen * Math.cos(angle - Math.PI / 7), this.arrPosNorthDirection[1].y - headlen * Math.sin(angle - Math.PI / 7))
        ctx.lineTo(this.arrPosNorthDirection[1].x - headlen * Math.cos(angle + Math.PI / 7), this.arrPosNorthDirection[1].y - headlen * Math.sin(angle + Math.PI / 7))
        ctx.lineTo(this.arrPosNorthDirection[1].x, this.arrPosNorthDirection[1].y)
        ctx.lineTo(this.arrPosNorthDirection[1].x - headlen * Math.cos(angle - Math.PI / 7), this.arrPosNorthDirection[1].y - headlen * Math.sin(angle - Math.PI / 7))

        ctx.lineWidth = 10
        ctx.stroke()
        ctx.fillStyle = 'white'
        ctx.fill()
        ctx.closePath()

        setTimeout(() => {
          ElMessageBox.confirm('Подтвердите правильность выбора северного направления', '', {
            customClass: 'custom-notification',
            showClose: false,
            confirmButtonText: 'Применить',
            cancelButtonText: 'Отмена',
          })
            .then(() => {
              ElMessage({
                type: 'success',
                message: 'Север задан',
              })
              this.calculateWind()
              this.selectedFunction = 'scale'
              this.arrPosNorthDirection.length = 0
              ctx.clearRect(0, 0, canvas.width, canvas.height)
            })
            .catch(() => {
              this.arrPosNorthDirection.length = 0
              ctx.clearRect(0, 0, canvas.width, canvas.height)
            })
        }, 100)
      }
    },

    calculateWind() {
      let deflectionAngle = null
      if (this.arrPosNorthDirection[1].x - this.arrPosNorthDirection[0].x >= 0) {
        deflectionAngle = -Math.PI + Math.acos((this.arrPosNorthDirection[1].y - this.arrPosNorthDirection[0].y) / Math.sqrt(Math.pow(this.arrPosNorthDirection[1].x - this.arrPosNorthDirection[0].x, 2) + Math.pow(this.arrPosNorthDirection[1].y - this.arrPosNorthDirection[0].y, 2)))
      } else {
        deflectionAngle = Math.acos((this.arrPosNorthDirection[0].y - this.arrPosNorthDirection[1].y) / Math.sqrt(Math.pow(this.arrPosNorthDirection[1].x - this.arrPosNorthDirection[0].x, 2) + Math.pow(this.arrPosNorthDirection[1].y - this.arrPosNorthDirection[0].y, 2)))
      }
      this.$store.commit('Project/setData', { label: 'currentProject', data: {} })
      this.$store.commit('Project/addDataToProject', { label: 'deflectionAngle', data: deflectionAngle })
    },
    setScale(event) {
      const canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      ctx.fillStyle = '#73FBFD'
      ctx.strokeStyle = '#D83EE1'

      if (this.arrPosScaleLine.length < 2) {
        const x = event.offsetX
        const y = event.offsetY
        this.arrPosScaleLine.push({ x, y })
        ctx.beginPath()
        ctx.arc(x, y, 4, 2 * Math.PI, false)

        ctx.fill()
        ctx.beginPath()
        if (this.arrPosScaleLine.length > 1) {
          ctx.lineWidth = 2
          ctx.moveTo(this.arrPosScaleLine[this.arrPosScaleLine.length - 2].x, this.arrPosScaleLine[this.arrPosScaleLine.length - 2].y)
          ctx.lineTo(this.arrPosScaleLine[this.arrPosScaleLine.length - 1].x, this.arrPosScaleLine[this.arrPosScaleLine.length - 1].y)
          ctx.stroke()
        }
        ctx.closePath()
      }
      if (this.arrPosScaleLine.length > 1) {
        setTimeout(() => {
          let lengthScaleLineMeter = 0
          ElMessageBox.prompt('Введите расстояние между точками, м:', '', {
            confirmButtonText: 'Применить',
            cancelButtonText: 'Отмена',
            inputPattern: /[0-9]/,
            inputErrorMessage: 'Только цифры',
            customClass: 'custom-notification',
            showClose: false,
          })
            .then(({ value }) => {
              lengthScaleLineMeter = parseFloat(value)
              let lengthScaleLinePixel = Math.sqrt(Math.pow(this.arrPosScaleLine[1].x - this.arrPosScaleLine[0].x, 2) + Math.pow(this.arrPosScaleLine[1].y - this.arrPosScaleLine[0].y, 2))
              const delta = lengthScaleLineMeter / lengthScaleLinePixel
              this.$store.commit('Project/addDataToProject', { label: 'delta', data: delta })
              this.$store.commit('Project/addDataToProject', { label: 'sizeData', data: { pixels: Math.round(lengthScaleLinePixel), meter: lengthScaleLineMeter } })
              ctx.clearRect(0, 0, canvas.width, canvas.height)
              ElMessage({
                type: 'success',
                message: 'Масштаб задан',
              })
              this.isScale = true
              this.selectedFunction = 'machines'
            })
            .catch(() => {
              this.arrPosScaleLine.length = 0
              ctx.clearRect(0, 0, canvas.width, canvas.height)
            })
        }, 100)
      }
    },

    roulette(event) {
      if (!this.arrPosition.length) {
        this.redrawCanvas()
        this.rouletteResult = null
      }
      const x = event.offsetX
      const y = event.offsetY
      this.arrPosition.push({ x, y })
      this.drawPoints(this.arrPosition, false, false)

      if (this.arrPosition.length === 2) {
        let baseSize = this.$store.state.Project.currentProject.sizeData
        let lengthScaleLinePixel = Math.sqrt(Math.pow(this.arrPosition[1].x - this.arrPosition[0].x, 2) + Math.pow(this.arrPosition[1].y - this.arrPosition[0].y, 2))
        this.rouletteResult = Math.round((lengthScaleLinePixel * baseSize.meter) / baseSize.pixels)
        this.arrPosition = []
      }
    },

    closeRoulette() {
      this.redrawCanvas()
      this.arrPosition = []
      this.rouletteResult = null
    },

    addMachines(event) {
      if(this.user.role !== 'demo' || (this.user.role === 'demo' && this.arrPointEquipment.length < 1) ) {
        const x = event.offsetX
        const y = event.offsetY
        const pointId = Date.now()
        const groupId = this.currentGroupId
        this.arrPointEquipment.push({ x, y, pointId, groupId })
        this.drawPoints(this.arrPointEquipment, true, false)
      }
    },

    finishMachines() {
      if (this.arrPointEquipment.length) {
        this.$store.commit('Dialog/setData', { label: 'showClose', data: false })
        this.$store.commit('Project/setData', { label: 'currentMachinesPosition', data: this.arrPointEquipment })
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'CreateEquipment' })
        this.$store.commit('Dialog/setData', { label: 'titleDialog', data: 'Оборудование' })
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      }
    },

    addPipes(event) {
      const x = event.offsetX
      const y = event.offsetY
      const pointId = Date.now()
      this.arrPosEquipment.push({ x, y, pointId })
      this.drawPoints(this.arrPosEquipment, false)
      if (this.arrPosEquipment.length > 1) {
        this.calculatePointPipe()
      }
    },

    calculatePointPipe() {
      let canvas = document.getElementById('canvas')
      let xField = []
      let yField = []

      for (let i = 0; i <= canvas.width; i++) {
        xField[i] = i
      }
      for (let j = 0; j <= canvas.height; j++) {
        yField[j] = j
      }

      let n1user = this.arrPosEquipment[this.arrPosEquipment.length - 2].x
      let n2user = this.arrPosEquipment[this.arrPosEquipment.length - 1].x
      let k1user = this.arrPosEquipment[this.arrPosEquipment.length - 2].y
      let k2user = this.arrPosEquipment[this.arrPosEquipment.length - 1].y

      let n1 = n1user < n2user ? n1user : n2user
      let n2 = n1user < n2user ? n2user : n1user
      let k1 = n1user === n1 ? k1user : k2user
      let k2 = n2user === n2 ? k2user : k1user

      function findFieldPointEquipment1() {
        let tangens
        let igrek = []
        let ind = []
        let index = []
        let indexNew = []
        let r = []
        tangens = (yField[k2] - yField[k1]) / (xField[n2] - xField[n1])
        for (let i = 0; i <= canvas.width; i++) {
          igrek[i] = i >= n1 && i <= n2 ? yField[k1] + (xField[i] - xField[n1]) * tangens : 0
          ind[i] = igrek[i]
          index[i] = Math.trunc(ind[i])
          indexNew[i] = igrek[i] - index[i] < 0.5 ? index[i] : index[i] + 1
        }
        for (let i = 0; i <= canvas.width; i++) {
          r[i] = []
          for (let j = 0; j <= canvas.height; j++) {
            r[i][j] = j === indexNew[i] && j >= k1 && j <= k2 ? 1 : 0
          }
        }
        return r
      }

      function findFieldPointEquipment2() {
        let tangens
        let iks = []
        let ind = []
        let index = []
        let indexNew = []
        let r = []
        tangens = (xField[n2] - xField[n1]) / (yField[k2] - yField[k1])
        for (let j = 0; j <= canvas.height; j++) {
          iks[j] = j >= k1 && j <= k2 ? xField[n1] + (yField[j] - yField[k1]) * tangens : 0
          ind[j] = iks[j]
          index[j] = Math.trunc(ind[j])
          indexNew[j] = iks[j] - index[j] < 0.5 ? index[j] : index[j] + 1
        }
        for (let i = 0; i <= canvas.width; i++) {
          r[i] = []
          for (let j = 0; j <= canvas.height; j++) {
            r[i][j] = i === indexNew[j] && i >= n1 && i <= n2 ? 1 : 0
          }
        }
        return r
      }

      function findFieldPointEquipment3() {
        let tangens
        let igrek = []
        let ind = []
        let index = []
        let indexNew = []
        let r = []
        tangens = (yField[k2] - yField[k1]) / (xField[n2] - xField[n1])
        for (let i = 0; i <= canvas.width; i++) {
          igrek[i] = i >= n1 && i <= n2 ? yField[k1] + (xField[i] - xField[n1]) * tangens : 0
          ind[i] = igrek[i]
          index[i] = Math.trunc(ind[i])
          indexNew[i] = igrek[i] - index[i] < 0.5 ? index[i] : index[i] + 1
        }
        for (let i = 0; i <= canvas.width; i++) {
          r[i] = []
          for (let j = 0; j <= canvas.height; j++) {
            r[i][j] = j === indexNew[i] && j >= k2 && j <= k1 ? 1 : 0
          }
        }
        return r
      }

      function findFieldPointEquipment4() {
        let tangens
        let iks = []
        let ind = []
        let index = []
        let indexNew = []
        let r = []
        tangens = (xField[n2] - xField[n1]) / (yField[k2] - yField[k1])
        for (let j = 0; j <= canvas.height; j++) {
          iks[j] = j <= k1 && j >= k2 ? xField[n1] + (yField[j] - yField[k1]) * tangens : 0
          ind[j] = iks[j]
          index[j] = Math.trunc(ind[j])
          indexNew[j] = iks[j] - index[j] < 0.5 ? index[j] : index[j] + 1
        }
        for (let i = 0; i <= canvas.width; i++) {
          r[i] = []
          for (let j = 0; j <= canvas.height; j++) {
            r[i][j] = i === indexNew[j] && i >= n1 && i <= n2 ? 1 : 0
          }
        }
        return r
      }

      let fieldPointEquipment1 = findFieldPointEquipment1()
      let fieldPointEquipment2 = findFieldPointEquipment2()
      let fieldPointEquipment3 = findFieldPointEquipment3()
      let fieldPointEquipment4 = findFieldPointEquipment4()

      let condition = Math.atan((yField[k2] - yField[k1]) / (xField[n2] - xField[n1]))

      let fieldPointEquipment = []

      for (let i = 0; i <= canvas.width; i++) {
        fieldPointEquipment[i] = []
        for (let j = 0; j <= canvas.height; j++) {
          if (condition >= 0 && condition < Math.PI / 4) {
            fieldPointEquipment[i][j] = fieldPointEquipment1[i][j]
          } else if (condition >= Math.PI / 4 && condition <= Math.PI / 2) {
            fieldPointEquipment[i][j] = fieldPointEquipment2[i][j]
          } else if (condition >= (-1 * Math.PI) / 4 && condition < 0) {
            fieldPointEquipment[i][j] = fieldPointEquipment3[i][j]
          } else if (condition >= (-1 * Math.PI) / 2 && condition < (-1 * Math.PI) / 4) {
            fieldPointEquipment[i][j] = fieldPointEquipment4[i][j]
          }
        }
      }

      for (let x = 0; x <= canvas.width; x++) {
        for (let y = 0; y <= canvas.height; y++) {
          if (fieldPointEquipment[x][y] === 1) {
            this.arrPointEquipment.push({ x, y })
          }
        }
      }

      let pointEquipmentNumber = 0
      for (let x = 0; x <= canvas.width; x++) {
        for (let y = 0; y <= canvas.height; y++) {
          pointEquipmentNumber += fieldPointEquipment[x][y]
        }
      }

      let lengthLineEquipment = Math.sqrt(Math.pow(xField[n2] - xField[n1], 2) + Math.pow(yField[k2] - yField[k1], 2))

      for (let i = 0; i < pointEquipmentNumber; i++) {
        this.arrlengthBranchEquipment.push(lengthLineEquipment / pointEquipmentNumber)
      }
    },

    cutPoint() {
      for (let i = this.arrPointEquipment.length - 1; i >= 0; i--) {
        if (i % 2 === 1 && i !== (this.arrPointEquipment.length - 1)) {
          this.arrPointEquipment.splice(i, 1)
          this.arrlengthBranchEquipment.splice(i, 1)
        }
      }
    },

    finishPipes() {
      if (this.arrPosEquipment.length > 1) {
        const numberPointEquipmentFirst = this.arrPointEquipment.length;
        for (let i = 0; i < 4; i++) {
          if (this.arrPointEquipment.length > 4) {
            this.cutPoint()
          }
        }
        const numberPointEquipmentThen = this.arrPointEquipment.length;
        for (let i = this.arrPointEquipment.length - 1; i >= 0; i--) {
          this.arrlengthBranchEquipment[i] = this.arrlengthBranchEquipment[i] * numberPointEquipmentFirst / numberPointEquipmentThen;
        }

        this.$store.commit('Dialog/setData', { label: 'showClose', data: false })
        this.$store.commit('Project/setData', { label: 'currentMachinesPosition', data: { calculate: this.arrPointEquipment, draw: this.arrPosEquipment } })
        this.$store.commit('Project/setData', { label: 'arrLengthBranchEquipment', data: this.arrlengthBranchEquipment })
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'CreatePipe' })
        this.$store.commit('Dialog/setData', { label: 'titleDialog', data: 'Оборудование' })
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      }
    },

    addBuilding(event) {
      const x = event.offsetX
      const y = event.offsetY
      const pointId = Date.now()
      this.arrPosition.push({ x, y, pointId })
      this.drawPoints(this.arrPosition, false, true)
    },

    finishBuilding() {
      if (this.arrPosition.length > 2) {
        const canvas = document.getElementById('canvas')
        const ctx = canvas.getContext('2d')
        ctx.beginPath()
        ctx.lineWidth = 2
        ctx.moveTo(this.arrPosition[this.arrPosition.length - 1].x, this.arrPosition[this.arrPosition.length - 1].y)
        ctx.lineTo(this.arrPosition[0].x, this.arrPosition[0].y)
        ctx.stroke()
        ctx.closePath()
        this.$store.commit('Dialog/setData', { label: 'showClose', data: false })
        this.$store.commit('Project/setData', { label: 'currentBuildingPosition', data: this.arrPosition })
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddBuilding' })
        this.$store.commit('Dialog/setData', { label: 'titleDialog', data: 'Новое здание' })
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      }
    },

    addWall(event) {
      const x = event.offsetX
      const y = event.offsetY
      const pointId = Date.now()
      this.arrPosition.push({ x, y, pointId })
      this.drawPoints(this.arrPosition, false, false)
      if (this.arrPosition.length === 2) {
        this.finishWall()
      }
    },

    finishWall() {
      this.$store.commit('Dialog/setData', { label: 'showClose', data: false })
      this.$store.commit('Project/setData', { label: 'currentWallPosition', data: this.arrPosition })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddWall' })
      this.$store.commit('Dialog/setData', { label: 'titleDialog', data: 'Противопожарная стена' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },

    addWorker(event) {
      if(!this.workerStop) {
        const x = event.offsetX
        const y = event.offsetY
        const pointId = Date.now()
        this.arrPointEquipment.push({ x, y, pointId })
        this.drawPoints(this.arrPointEquipment, true)
        this.$store.commit('Project/setData', { label: 'workerStop', data: true })
      }
    },

    finishWorker() {
      if (this.arrPointEquipment.length) {
        this.$store.commit('Dialog/setData', { label: 'showClose', data: false })
        this.$store.commit('Project/setData', { label: 'currentWorkerPosition', data: this.arrPointEquipment })
        this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddWorker' })
        this.$store.commit('Dialog/setData', { label: 'titleDialog', data: 'Работник' })
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
      }
    },
    addLivingArea(event) {
      const x = event.offsetX
      const y = event.offsetY
      const pointId = Date.now()
      this.arrPosition.push({ x, y, pointId })
      this.drawPoints(this.arrPosition, false, true)
    },

    finishLivingArea() {
      const canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      ctx.beginPath()
      ctx.lineWidth = 2
      ctx.moveTo(this.arrPosition[this.arrPosition.length - 1].x, this.arrPosition[this.arrPosition.length - 1].y)
      ctx.lineTo(this.arrPosition[0].x, this.arrPosition[0].y)
      ctx.stroke()
      ctx.closePath()
      this.$store.commit('Dialog/setData', { label: 'showClose', data: false })
      this.$store.commit('Project/setData', { label: 'currentPeoplePosition', data: this.arrPosition })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'AddPeople' })
      this.$store.commit('Dialog/setData', { label: 'titleDialog', data: 'Население' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },

    drawPoints(arr, dashed, completed, key) {
      const canvas = document.getElementById('canvas')
      if (arr && arr.length && canvas) {
        setTimeout(() => {
          const ctx = canvas.getContext('2d')

          if (((this.selectedFunction === 'machines' || this.selectedFunction === 'pipes') && !key) || key === 'Equipment' || key === 'PipeEquipment') {
            ctx.fillStyle = '#EB3223'
            ctx.strokeStyle = '#EB3223'
          } else if ((this.selectedFunction === 'buildings' && !key) || key === 'Building') {
            ctx.fillStyle = '#F5C2CB'
            ctx.strokeStyle = '#F9F553'
          } else if ((this.selectedFunction === 'grid' && !key) || key === 'Wall') {
            ctx.fillStyle = '#75F94C'
            ctx.strokeStyle = '#75F94C'
          } else if ((this.selectedFunction === 'person' && !key) || key === 'Zone') {
            ctx.fillStyle = '#F5C2CB'
            ctx.strokeStyle = '#0024ED'
          } else if ((this.selectedFunction === 'worker' && !key) || key === 'Worker') {
            ctx.fillStyle = '#0024ED'
            ctx.strokeStyle = '#F5C2CB'
          } else if (this.selectedFunction === 'roulette' && !key) {
            ctx.fillStyle = '#FFB800'
            ctx.strokeStyle = '#FFB800'
          }

          arr.map((element, index) => {
            if (Array.isArray(element)) {
              let center = { x: 0, y: 0 }

              if ((key === 'Zone' || key === 'Building') && element.length >= 3) {
                let arr = [[]]
                element.map((element) => {
                  arr[0].push([element.x, element.y])
                })
                arr[0].push(arr[0][0])
                let polygon = this.polygon(arr)
                let centroid = this.centroid(polygon)
                center.x = centroid.geometry.coordinates[0]
                center.y = centroid.geometry.coordinates[1]
              }

              element.map((points, index2) => {
                let img = new Image()

                if (key === 'Equipment') {
                  img.src = '/img/icon-map-equipment.svg'
                }

                if (key === 'Worker') {
                  img.src = '/img/icon-map-worker.svg'
                }

                if (key === 'Wall') {
                  img.src = '/img/icon-map-wall.svg'
                }

                if (key === 'PipeEquipment') {
                  img.src = '/img/icon-map-pipe.svg'
                }

                if (key === 'Zone' || key === 'Building') {
                  ctx.beginPath()
                  ctx.arc(points.x, points.y, 4, 2 * Math.PI, false)
                  ctx.fill()
                  ctx.closePath()

                  if (index2 === element.length - 1) {
                    if (key === 'Zone') img.src = '/img/icon-map-zone.svg'
                    if (key === 'Building') img.src = '/img/icon-map-building.svg'
                    img.onload = () => ctx.drawImage(img, center.x - 12, center.y - 12, 24, 24)
                  }
                } else {
                  img.onload = () => ctx.drawImage(img, points.x - 12, points.y - 12, 24, 24)
                }

                if (dashed) {
                  ctx.setLineDash([3, 3])
                }

                if (index2 > 0) {
                  ctx.beginPath()
                  ctx.lineWidth = 2
                  ctx.moveTo(element[index2 - 1].x, element[index2 - 1].y)
                  ctx.lineTo(points.x, points.y)
                  if (completed && index2 === element.length - 1) {
                    ctx.moveTo(element[index2].x, element[index2].y)
                    ctx.lineTo(element[0].x, element[0].y)
                  }
                  ctx.stroke()
                  ctx.closePath()
                }
                ctx.setLineDash([])
              })
            } else if (element) {
              ctx.beginPath()
              ctx.arc(element.x, element.y, 4, 2 * Math.PI, false)
              ctx.fill()
              ctx.closePath()

              if (dashed) {
                ctx.setLineDash([3, 3])
              }

              if (index > 0) {
                ctx.beginPath()
                ctx.lineWidth = 2
                ctx.moveTo(arr[index - 1].x, arr[index - 1].y)
                ctx.lineTo(arr[index].x, arr[index].y)
                ctx.stroke()
                ctx.closePath()
              }
              ctx.setLineDash([])
            }
          })
        }, 0)
      }
    },

    delPoint(pointId, pointIndex, arr, objName, arrName) {
      var canvas = document.getElementById('canvas')
      var ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
      arr.splice(pointIndex, 1)

      this.currentProject[arrName].splice(pointIndex, 1)
      if (pointId) {
        this.currentProject.drawPoints[objName].map((element, index) => {
          element.map((points, index2) => {
            if (points.pointId === pointId) {
              element.splice(index2, 1)
            }
            if (!element.length) {
              this.currentProject.drawPoints[objName].splice(index, 1)
            }
          })
        })
      } else {
        this.currentProject.drawPoints[objName].splice(pointIndex, 1)
      }

      setTimeout(() => {
        for (let key in this.currentProject.drawPoints) {
          this.drawPoints(this.currentProject.drawPoints[key], key === 'Equipment' || key === 'Worker' ? true : false, key === 'Building' || key === 'Zone' ? true : false, key)
        }
        this.drawPoints(this.currentProject.drawPoints)
      }, 0)
    },

    delPointGroup(groupId, arr, objName, arrName) {
      var canvas = document.getElementById('canvas')
      var ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)

      const filtredArr = arr.filter(item => item.groupId !== groupId)

      
      this.$store.commit('Project/setData', { label: arrName, data: JSON.parse(JSON.stringify(filtredArr)) })

      this.$store.commit('Project/addDataToProject', { label: arrName, data: JSON.parse(JSON.stringify(filtredArr)) })
      
      if (groupId) {
        this.currentProject.drawPoints[objName].map((element, index) => {
          if(element[0] && element[0].groupId === groupId) {
            this.currentProject.drawPoints[objName].splice(index, 1)
          }
        })
      }

      setTimeout(() => {
        for (let key in this.currentProject.drawPoints) {
          this.drawPoints(this.currentProject.drawPoints[key], key === 'Equipment' || key === 'Worker' ? true : false, key === 'Building' || key === 'Zone' ? true : false, key)
        }
        this.drawPoints(this.currentProject.drawPoints)
      }, 0)
    },

    redrawCanvas() {
      const canvas = document.getElementById('canvas')
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      this.arrPointEquipment = []
      this.arrPosition = []
      this.arrPosEquipment = []

      setTimeout(() => {
        for (let key in this.currentProject.drawPoints) {
          this.drawPoints(this.currentProject.drawPoints[key], key === 'Equipment' || key === 'Worker' ? true : false, key === 'Building' || key === 'Zone' ? true : false, key)
        }
      }, 0)
      this.drawPoints(this.currentProject.drawPoints)
    },

    selectObject(data, index) {
      this.redrawCanvas()

      if (this.chosenObject === `${data.nameOfEquipment || data.name}${index}`) {
        this.chosenObject = null
        return
      } else {
        this.chosenObject = `${data.nameOfEquipment || data.name}${index}`
      }

      const canvas = document.querySelector('#canvas')
      const ctx = canvas.getContext('2d')

      if (data.arrPosWall || data.arrPosWorker) {
        ctx.fillStyle = 'rgba(255, 184, 0, .6)'
        const dataArr = data.arrPosWall || data.arrPosWorker
        dataArr.map((element) => {
          ctx.beginPath()
          ctx.arc(element.x, element.y, 24, 2 * Math.PI, false)
          ctx.fill()
        })
      } else if (data.points) {
        ctx.fillStyle = 'rgba(255, 184, 0, .6)'
        const dataArr = [data.points[0], this.currentProject.drawPoints.PipeEquipment[index][1]]
        dataArr.map((element) => {
          ctx.beginPath()
          ctx.arc(element.x, element.y, 24, 2 * Math.PI, false)
          ctx.fill()
        })
      } else if (data.arrPosBuilding || data.arrPosWorker || data.arrPosZone) {
        ctx.fillStyle = 'rgba(255, 184, 0, .6)'
        const dataArr = data.arrPosBuilding || data.arrPosWorker || data.arrPosZone
        ctx.beginPath()
        ctx.moveTo(dataArr[0].x, dataArr[0].y)
        dataArr.map((element) => {
          ctx.lineTo(element.x, element.y)
        })
        ctx.fill()
      } else {
        ctx.fillStyle = 'rgba(255, 184, 0, .6)'
        const dataArr = this.currentProject.drawPoints.Equipment[index]
        dataArr.map((element) => {
          ctx.beginPath()
          ctx.arc(element.x, element.y, 24, 2 * Math.PI, false)
          ctx.fill()
        })
      }
    },

    editObject(data, dialog, dialogTitle) {
      let role = null
      let days = null

      if (this.user) {
        role = this.user.role
        days = this.getDemoDays(this.user.role_expiration_date)
      }

      if(role === 'demo' && days === '0') {
        return false
      }

      const editData = JSON.parse(JSON.stringify(data))
      this.$store.commit('Project/setData', { label: 'currentEditObject', data: editData })
      this.$store.commit('Dialog/setData', { label: 'showClose', data: false })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: dialog })
      this.$store.commit('Dialog/setData', { label: 'titleDialog', data: dialogTitle })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },

    checkFunctionsStatus(item) {
      let role = null
      let days = null
      if (this.user) {
        role = this.user.role
        days = this.getDemoDays(this.user.role_expiration_date)
      }

      if(role === 'demo' && days === '0') {
        return true
      }

      if (item.name === 'Аппараты' || item.name === 'Трубопроводы') {
        let arrEquipment = this.$store.state.Project.arrEquipment
        arrEquipment = arrEquipment.concat(this.$store.state.Project.arrPipeEquipment)
        if ((role === 'teaching' && arrEquipment.length >= 5) || (item.name === 'Аппараты' && role === 'demo' && this.$store.state.Project.arrEquipment.length >= 1) || (item.name === 'Трубопроводы' && role === 'demo' && this.$store.state.Project.arrPipeEquipment.length >= 1)) {
          return true
        }
      }

      if (item.name === 'Противопожарная стена' && this.currentProject.arrWall && this.currentProject.arrWall.length === 1) {
        return true
      }

      if (item.name === 'Здания' && this.currentProject.arrBuilding && (role === 'teaching' && this.currentProject.arrBuilding.length >= 3 || role === 'demo' && this.currentProject.arrBuilding.length >= 1)) {
        return true
      }

      if (item.name === 'Работники' && this.currentProject.arrWorker && (role === 'teaching'  && this.currentProject.arrWorker.length >= 5 || role === 'demo'  && this.currentProject.arrWorker.length >= 1) ) {
        return true
      }

      if (item.name === 'Население' && this.currentProject.arrZone && (role === 'teaching'  && this.currentProject.arrZone.length >= 3 || role === 'demo'  && this.currentProject.arrZone.length >= 1)) {
        return true
      }

      return false
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.project-wrapper {
  width: 100%;
}

.project {
  display: flex;
  width: 100%;
  position: relative;

  &__bar {
    border-right: 1px solid #c8d0d6;
    width: 27rem;
    padding: 0 3rem;

    &-preview {
      margin-top: 3rem;
    }
  }

  &__dialog {
    width: calc(100% - 27rem);
    height: 100%;
    position: absolute;
    left: 27rem;
    z-index: 50;

    &-mask {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.4);
    }

    &-content {
      position: relative;
      z-index: 1;
      background: $basicWhite;
      width: 70%;
      height: 100%;
    }

    &-close {
      position: absolute;
      right: 3.6rem;
      top: 3.6rem;
      cursor: pointer;
    }
  }

  &__content {
    width: calc(100% - 27rem);
    padding: 3rem;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3rem;

      &-title {
        font-weight: 500;
        font-size: 2.2rem;
        line-height: 3rem;

        & > img {
          margin-left: 1.2rem;
          cursor: pointer;
        }
      }
    }

    &-functions {
      display: flex;
      margin-right: -1.4rem;
      margin-bottom: 3rem;

      &-item {
        display: block;
        border: 1px solid $grey;
        border-radius: 1.2rem;
        padding: 2rem;
        flex: 1;
        margin-right: 1.4rem;
        cursor: pointer;

        &.active {
          border: 1px solid $blue;
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        input {
          display: none;
        }
      }

      &-name {
        font-weight: 600;
        font-size: 1.4rem;
        line-height: 1.9rem;
        color: $darkGrey;
        margin-top: 1.2rem;
        display: block;
      }
    }

    &-view {
      height: 650px;
      position: relative;
      overflow: scroll;
      border-radius: 1.2rem;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
      &-container {
        margin: 0 auto;
        display: flex;
        width: 100%;
        display: flex;
      }

      &-content {
        position: relative;
        margin: 0 auto;
        //width: 740px; //
        //height: 480px; //
        transform-origin: 0 0;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      &-canvas {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        height: 100%;
        width: 100%;

        &-risk {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 20;
          height: 100%;
          width: 100%;
        }
      }

      &-wrapper {
        position: relative;
      }

      &-roulette {
        background: $basicWhite;
        border-radius: 6px;
        padding: 0 20px;
        height: 4.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        transition: 0.3s;

        &.active {
          background: $blue;
          color: $basicWhite;

          .roulette-icon {
            path {
              stroke: #eff2f6;
            }
          }
        }

        .roulette-icon {
          margin-right: 0.8rem;

          path {
            transition: 0.3s;
          }
        }

        &-result {
          position: absolute;
          left: 100%;
          background: $basicWhite;
          border-radius: 0 1.2rem 1.2rem 0;
          height: 100%;
          width: 16rem;
          opacity: 0;
          visibility: hidden;
          transform: translateX(-2rem);
          transition: 0.3s;
          color: #000;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
          display: flex;
          align-items: center;
          padding-right: 3.5rem;

          img {
            position: absolute;
            right: 10px;
            margin: 0;
            cursor: pointer;
          }

          &.visible {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
          }
        }

        &-wrapper {
          position: absolute;
          z-index: 2;
          box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
          left: 1rem;
          top: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 12px;
        }
      }

      &-scale {
        position: absolute;
        z-index: 2;
        background: $basicWhite;
        box-shadow: 0 4px 12px -2px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        right: 2rem;
        top: calc(50% - 3.6rem);
        width: 3.6rem;
        height: 7.2rem;
        overflow: hidden;

        button {
          background: none;
          border: none;
          width: 100%;
          height: 50%;
          color: $grey;
          font-weight: 600;
          font-size: 2.4rem;
          transition: 0.3s ease-out;

          &:disabled {
            cursor: not-allowed;

            &:hover {
              color: $grey;
            }
          }

          &:hover {
            color: $basicBlack;
          }

          &:first-of-type {
            border-bottom: 1px solid $lightGrey;
          }
        }
      }

      &-map {
        display: block;
        position: relative;
        z-index: 0;
        transition: 0.3s ease-out;
        //width: 100%; //
        //height: 100%; //
      }

      &-saved-risk {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 20;
        height: 100%;
        width: 100%;
      }
    }

    &-diagram {
      width: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 3rem auto;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 1.6rem;

      &-chart {
        display: flex;

        span {
          display: block;
          width: 10rem;
          height: 1.2rem;
        }
      }

      &-values {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        p {
          margin-right: 7.5rem;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      &-text {
        margin-top: 2rem;
      }
    }
  }

  &__object {
    border-bottom: 1px solid $lightGrey;

    &:last-of-type {
      border-bottom: none;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem 0;
      position: relative;

      &-arrow {
        position: absolute;
        left: -2rem;
        transition: 0.3s ease-out;
        transform: rotate(-90deg);
        cursor: pointer;

        &--rotate {
          transform: rotate(0);
        }
      }

      &-name {
        font-weight: 600;
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $lightBlack;
      }

      &-count {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.4rem;
        color: $darkGrey;
        width: 2.2rem;
        height: 1.6rem;
        background: $lightGrey;
        border-radius: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-values {
      padding-left: 1rem;
    }

    &-value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.5s;

      &-name {
        font-size: 1.2rem;
        line-height: 1.6rem;
        color: $darkGrey;
        cursor: pointer;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &-options {
        display: flex;
      }

      &-option {
        cursor: pointer;
        margin-right: 1rem;

        &:last-of-type {
          margin-right: 0;
        }
      }

      &.chosen {
        border-radius: 0.8rem;
        padding: 0 1rem;
        border: 1px solid $blue;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
