<template>
  <div v-if="currentObject" class="view-machine">
    <div class="view-machine__title">
      {{currentObject.nameOfEquipment}}
    </div>
    
    <div v-if="chartData && chartOptions" class="view-machine__chart">
      <LineChart :chartData="chartData" :options="chartOptions"/>
    </div>

    <el-button type="primary" @click="probabilityOfDamageChart" class="general-graph-btn">Построить график потенциального риска</el-button>

    <div v-if="!showDetails" class="view-machine__title">
      Характеристики
    </div>
    
    <div v-if="!showDetails" class="view-machine__accidents">
      <el-collapse v-model="activeName">
        <el-collapse-item 
          v-for="(accident, indx) in currentObject.failureParameterObj" 
          :key="indx" 
          :title="failureName(currentObject.objDetailData.accidents[indx], indx)" 
          :name="indx"
        >
          <div 
            v-for="(tree) in accident.trees" 
            :key="tree.id" 
            class="view-machine__tree-branch" 
          >

            <div class="view-machine__details-item" v-for="(row, idx) in tree.frequencyScenario" :key="idx">
              <div class="view-machine__details-item--top" 
              @click="tree.fires[idx].value !== 0 ? coseChart(tree.fires[idx].value, indx) : ''">
                <div class="view-machine__details-item-name">
                  {{ tree.fires[idx].label }}
                </div>
                <div v-if="tree.fires[idx].label !== 'Нет пожара' " class="view-machine__details-item-value">
                  <img src="../assets/icons/icon-line-chart.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { LineChart } from 'vue-chart-3';
import { calculatePoolFire } from '@/mixin/graphs/calculate_pool_fire'
import { calculateBallFire }  from '@/mixin/graphs/calculate_ball_fire'
import { calculateFlashFire }  from '@/mixin/graphs/calculate_flash_fire'
import { calculateHorisontalJetFire }  from '@/mixin/graphs/calculate_horisontal_jet_fire'
import { calculateVerticalJetFire }  from '@/mixin/graphs/calculate_vertical_jet_fire'
import { calculateCloudExplosionFire }  from '@/mixin/graphs/calculate_cloud_explosion_fire'
import { calculateTankExplosionFire }  from '@/mixin/graphs/calculate_tank_explosion_fire'
import { probabilityOfDamageChart } from '@/mixin/graphs/probability_o_damage_chart'


export default {
  name: 'ViewMachine',
  mixins: [calculatePoolFire, calculateBallFire, calculateFlashFire, calculateHorisontalJetFire,calculateVerticalJetFire, calculateCloudExplosionFire, calculateTankExplosionFire,probabilityOfDamageChart],
  components: { LineChart },
  data() {
    return {
      showDetails: false,
      showDetailsTable: false,

      tableType: '',
      detailChartInfo: null,

      mainProbabilityOfDamageChart: false,
      
      activeAccident: null,
      activeTree: null,
      
      activeName: [],

      chartData: null,
      chartOptions: null,
    }
  },
  computed: {
    ...mapState('Project', [
      'currentObject',
      'currentProject',
      'region',
    ]),
  },

  watch: {
    currentObject: {
      deep: true,
      handler() {
        this.chartData = null
        this.chartOptions = null
      }
    }
  },

  created() {
    this.currentObject.failureParameterObj.map((el, indx) => {
      this.activeName.push(indx)
    })
  },

  methods: {
    showAccidentDetail(accidentId, treeId) {
      this.showDetails = true
      this.activeAccident = accidentId
      this.activeTree = treeId
    },

    failureName(failure) {
      if(failure.failure === 'hole') {
        return `Локальное повреждение ${failure.diameterHole} мм`
      } else if(failure.failure === 'tankFire') {
        return `Пожар резервуара ${failure.diameterTank} м`
      } else {
        return `Полное разрушение`
      }
    },

    coseChart(chartID, indx) {
      this.$store.commit('Project/setData', { label: 'chartData', data: null })
      this.$store.commit('Project/setData', { label: 'chartOptions', data: null })
      this.$store.commit('Project/setData', { label: 'probabilityChartData', data: null })
      this.$store.commit('Project/setData', { label: 'probabilityChartOptions', data: null })
      this.$store.commit('Project/setData', { label: 'graphTableData', data: null })
      this.$store.commit('Project/setData', { label: 'graphDestructionTableData', data: null })
      this.$store.commit('Project/setData', { label: 'detailChartInfo', data: null })

      if(chartID === 1) {
        this.calculatePoolFire('radiationIntencity', indx)
        this.calculatePoolFire('probabilityOfDamage', indx)
      } else if(chartID === 2) {
        this.calculateBallFire('radiationIntencity', indx)
        this.calculateBallFire('probabilityOfDamage', indx)
      }else if(chartID === 3) {
        this.calculateFlashFire(indx)
      }else if(chartID === 4) {
        this.calculateHorisontalJetFire(indx)
      }else if(chartID === 5) {
        this.calculateVerticalJetFire('radiationIntencity', indx)
        this.calculateVerticalJetFire('probabilityOfDamage', indx)
      }else if(chartID === 6) {
        this.calculateCloudExplosionFire('radiationIntencity', indx)
        this.calculateCloudExplosionFire('probabilityOfDamage', indx)
      }else if(chartID === 7) {
        this.calculateTankExplosionFire('radiationIntencity', indx)
        this.calculateTankExplosionFire('probabilityOfDamage', indx)
      }

      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'Charts' })
      this.$store.commit('Dialog/setData', { label: 'titleDialog', data: 'Оборудование' })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    },

    
  }
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.view-machine {
  padding-top: 3.6rem;
  height: calc(100vh - 7.2rem);
  overflow: auto;
  padding-bottom: 5rem;

  &__title {
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin: 0 7.6rem 3.6rem 2rem;
  }


  &__table {
    padding: 0 1rem;
    margin-bottom: 4rem;
  }

  &__chart-info {
    padding: 0 2rem;
    margin-bottom: 4rem;
    font-size: 1.6rem;
  }

  &__chart {
    padding: 0 1rem;
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    > div {
      width: 100%;
    }
  }

  &__accidents {
    border-top: 1px solid $lightGrey;
  }

  &__accident {
    border-bottom: 1px solid $lightGrey;
    padding: 2.4rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.9rem;
    cursor: pointer;
  }

  &__details {
    &-header {
      display: flex;
      align-items: center;
      padding: 0 2rem;
      margin-bottom: 3.4rem;
    }

    &-back {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: $darkGrey;
      margin-right: 4.4rem;
      cursor: pointer;

      &>img {
        margin-right: 1rem;
      }
    }

    &-title {
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.5rem;
    }

    &-list {
      border-top: 1px solid $lightGrey;
    }

    &-item {
      border-bottom: 1px solid $lightGrey;
      width: 100%;

      &:last-child {
        border: none;
      }

      &-name {
        font-size: 1.4rem;
        line-height: 1.9rem;
      }

      &-value {
        font-size: 1.4rem;
        line-height: 1.9rem;
        font-weight: 700;
        color: $lightBlack;
      }

      &-action {
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.4rem;
        color: $blue;
        margin-right: 4rem;
        cursor: pointer;
        transition: 0.3s ease-out;

        &:hover {
          opacity: 0.8;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      &--top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3rem 2rem 2rem;
      }

      &--bottom {
        display: flex;
      }
    }
  }
  &__tree-branch{
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 0 3.5rem;
    border-top: 1px solid $lightGrey;
    cursor: pointer;
  }
  .general-graph-btn {
    display: block;
    margin: 30px auto;
  }
}
</style>

